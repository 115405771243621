<template>
  <div id="checklist">
    <el-row :gutter="40">
      <el-col :lg="16" :md="24">
        <el-row class="header-row">
          <h3 class="el-page-header">Checklist Builder</h3>
        </el-row>

        <el-popover placement="right" ref="newProcessPopover" title="New Process" width="300" trigger="click">
          <el-form :model="addClientChecklistForm" ref="addClientChecklistForm" size="mini" :rules="addClientChecklistForm.rules" label-position="top" label-width="180px"
            @submit.native.prevent>
            <el-form-item prop="name" label="Checklist Name">
              <el-input v-model="addClientChecklistForm.name" size="mini"></el-input>
            </el-form-item>
            <el-form-item prop="description" label="Description">
              <el-input v-model="addClientChecklistForm.description" size="mini"></el-input>
            </el-form-item>
            <el-form-item style="text-align: center;">
              <el-button-group>
                <el-button class="hiaButton" plain round size="mini" type="text" @click="closeNewChecklistPopover()">Cancel</el-button>
                <el-button class="hiaButton" plain round size="mini" @click="addChecklist('addClientChecklistForm')">Add</el-button>
              </el-button-group>
            </el-form-item>
          </el-form>

          <el-button class="hiaButton" slot="reference" plain round size="mini" icon="el-icon-plus">Add Checklist</el-button>
        </el-popover>

        <v-client-table class="hiaTable topMargin" v-loading="loading" element-loading-spinner="atom-audit-loader" ref="projectProcessListsGrid" id="projectProcessListsGrid"
          :data="clientChecklists" :columns="checklistColumns" :options="checklistOptions">
          <div slot="filter__inactive">
            <input type="checkbox" @change="$refs.projectProcessListsGrid.setFilter({inactive: $event.target.checked})">
          </div>

          <div slot="filter__inpatient">
            <input type="checkbox" @change="$refs.projectProcessListsGrid.setFilter({inpatient: $event.target.checked})">
          </div>

          <div slot="filter__outpatient">
            <input type="checkbox" @change="$refs.projectProcessListsGrid.setFilter({outpatient: $event.target.checked})">
          </div>

          <div slot="filter__ambulatory">
            <input type="checkbox" @change="$refs.projectProcessListsGrid.setFilter({ambulatory: $event.target.checked})">
          </div>

          <div slot="filter__provider">
            <input type="checkbox" @change="$refs.projectProcessListsGrid.setFilter({provider: $event.target.checked})">
          </div>

          <template slot="drilldown" slot-scope="props">
            <span @click="drilldown(props)" style="color: #337ab7; cursor: pointer;"><i class="mdi"
                :class="props.row.selected ? 'mdi-minus-box-outline' : 'mdi-plus-box-outline'" style="font-size: 15px; position: relative; top: 1px;"></i> Manage Items
              ({{props.row.checklistItems.length}})</span>
          </template>
          <template slot="child_row" slot-scope="props">
            <checklistItems style="padding-right: 20px; padding-left: 20px; padding-bottom:20px; margin-top: -10px;" :selectedChecklist="props.row"></checklistItems>
          </template>
          <template slot="name" slot-scope="props">
            <div class="el-input--mini">
              <input class="el-input__inner" type="text" v-model="props.row.name" @change="updateChecklist(props.row)">
            </div>
          </template>
          <template slot="description" slot-scope="props">
            <div class="el-input--mini">
              <input class="el-input__inner" type="text" v-model="props.row.description" @change="updateChecklist(props.row)">
            </div>
          </template>

          <template slot="inpatient" slot-scope="props">
            <input :key="props.row.checklistId" type="checkbox" v-model="props.row.inpatient" @change="updateChecklist(props.row)" />
          </template>

          <template slot="outpatient" slot-scope="props">
            <input :key="props.row.checklistId" type="checkbox" v-model="props.row.outpatient" @change="updateChecklist(props.row)" />
          </template>

          <template slot="provider" slot-scope="props">
            <input :key="props.row.checklistId" type="checkbox" v-model="props.row.provider" @change="updateChecklist(props.row)" />
          </template>

          <template slot="ambulatory" slot-scope="props">
            <input :key="props.row.checklistId" type="checkbox" v-model="props.row.ambulatory" @change="updateChecklist(props.row)" />
          </template>

          <template slot="inactive" slot-scope="props">
            <input :key="props.row.checklistId" type="checkbox" v-model="props.row.inactive" @change="updateChecklist(props.row)">
          </template>

          <template slot="delete" slot-scope="props">
            <div style="text-align: center;">
              <i class="el-icon-close delete-button" @click="deleteChecklist(props.row)"></i>
            </div>
          </template>
        </v-client-table>

      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import HiaAuthorization from '@/mixins/hiaAuthorization'
  import hiaTable from '@/mixins/hiaTable'
  import checklistItems from './checklistItems'

  export default {
    name: 'ClientChecklist',
    mixins: [hiaTable, HiaAuthorization],
    props: [],
    data() {
      return {
        loading: false,
        checklistColumns: ['drilldown', 'name', 'description', 'inpatient', 'outpatient', 'provider', 'ambulatory', 'inactive', 'delete'],
        checklistOptions: {
          showChildRowToggler: false,
          uniqueKey: 'checklistId',
          filterByColumn: true,
          sortable: ['name'],
          filterable: ['drilldown', 'name', 'description'],
          headings: {
            delete: function (h) {
              return ''
            },
            drilldown: 'Items',
            name: 'Check List'
          },
          perPage: 10,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' },
          columnsClasses: {
            drilldown: 'drilldown'
          }
        },
        showAddProjectProcessForm: false,
        addClientChecklistForm: {
          name: '',
          order: '',
          rules: {
            name: [
              { required: true, message: 'Please Input Name', trigger: 'none' }
            ],
            order: [
              { required: true, message: 'Please Input Order', trigger: 'none' }
            ]
          }
        }
      }
    },
    components: {
      checklistItems
    },
    methods: {
      ...mapActions('managedLists/clientChecklists/', ['GET_CLIENT_CHECKLISTS', 'POST_CLIENT_CHECKLIST', 'DELETE_CLIENT_CHECKLIST', 'PUT_CLIENT_CHECKLIST']),
      ...mapActions('managedLists/clientChecklistItems/', ['GET_CLIENT_CHECKLIST_ITEMS']),
      ...mapMutations('dropdowns/', ['REMOVE_CACHED_DROPDOWN']),
      async initialize(route) {
        this.loading = true
        await this.GET_CLIENT_CHECKLISTS()

        await Promise.all([this.GET_CLIENT_CHECKLISTS(), this.GET_CLIENT_CHECKLIST_ITEMS()])
        this.loading = false
      },
      closeNewChecklistPopover() {
        const popper = this.$refs.newProcessPopover
        if (popper) {
          this.addClientChecklistForm.name = null
          this.addClientChecklistForm.description = null
          popper.doDestroy()
          popper.doClose()
        }
      },
      drilldown(props) {
        props.row.selected = !props.row.selected
        const index = this.clientChecklists.findIndex(x => x.checklistId === props.row.checklistId)
        this.$set(this.clientChecklists, index, props.row)

        this.$refs.projectProcessListsGrid.toggleChildRow(props.row.checklistId)
      },
      addChecklist(refName) {
        this.$refs[refName].validate(async (valid) => {
          if (valid) {
            try {
              this.loading = true

              const payload = {
                checklist: {
                  name: this.addClientChecklistForm.name,
                  description: this.addClientChecklistForm.description
                }
              }

              await this.POST_CLIENT_CHECKLIST(payload)

              this.REMOVE_CACHED_DROPDOWN('clientChecklistsPro')
              this.REMOVE_CACHED_DROPDOWN('clientChecklistsIP')
              this.REMOVE_CACHED_DROPDOWN('clientChecklistsOP')
              this.REMOVE_CACHED_DROPDOWN('clientChecklistsAMB')

              this.$refs[refName].resetFields()
              this.closeNewChecklistPopover()
              this.loading = false
              this.$message({
                message: 'Process Added',
                type: 'success'
              })
            } catch (err) {
              this.loading = false
              this.$message({
                message: `Error Adding Check List: ${err.message}`,
                type: 'error'
              })
            }
          }
        })
      },
      async updateChecklist(checklist) {
        const index = this.clientChecklists.findIndex(x => x.checklistId === checklist.checklistId)
        this.$set(this.clientChecklists, index, checklist)

        try {
          this.loading = true
          await this.PUT_CLIENT_CHECKLIST(checklist)

          this.REMOVE_CACHED_DROPDOWN('clientChecklistsPro')
          this.REMOVE_CACHED_DROPDOWN('clientChecklistsIP')
          this.REMOVE_CACHED_DROPDOWN('clientChecklistsOP')
          this.REMOVE_CACHED_DROPDOWN('clientChecklistsAMB')

          this.loading = false
          this.$message({
            message: 'Process Saved',
            type: 'success'
          })
        } catch (err) {
          this.loading = false
          this.$message({
            message: `Error Editing Process: ${err.message}`,
            type: 'error'
          })
        }
      },
      async deleteChecklist(checklist) {
        await this.$confirm(`Permanently delete ${checklist.name}?`, 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        })

        try {
          this.loading = true
          await this.DELETE_CLIENT_CHECKLIST(checklist)

          this.REMOVE_CACHED_DROPDOWN('clientChecklistsPro')
          this.REMOVE_CACHED_DROPDOWN('clientChecklistsIP')
          this.REMOVE_CACHED_DROPDOWN('clientChecklistsOP')
          this.REMOVE_CACHED_DROPDOWN('clientChecklistsAMB')

          this.loading = false
          this.$message({
            message: 'Process Deleted Successfully',
            type: 'success'
          })
        } catch (err) {
          this.loading = false
          this.$message({
            message: `Error Deleting Process: ${err.message}`,
            type: 'error'
          })
        }
      }
    },
    computed: {
      ...mapState('managedLists/clients/', ['clientList']),
      ...mapState('managedLists/clientChecklists/', ['clientChecklists', 'projectProcessListLoading']),
      ...mapState('user/', ['currentTenantGuid'])
    },
    watch: {
      currentTenantGuid() {
        this.initialize(this.$route)
      }
    },
    created: function () {
      this.initialize(this.$route)
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .checklistOption {
    cursor: pointer;
    padding: 0px 20px 0px 20px;
    color: #337ab7;
  }

  .checklistOption:hover {
    background: rgba(255, 196, 12, 0.2);
    border-radius: 2px;
  }

  .selected {
    background: rgba(255, 196, 12, 0.2);
  }

  .topMargin {
    margin: 10px 0px 0px 0px;
  }

  >>>.drilldown {
    min-width: 200px;
  }

  >>>.VueTables__child-row-toggler {
    width: 16px;
    height: 16px;
    line-height: 16px;
    display: block;
    margin: auto;
    text-align: center;
  }

  >>>.VueTables__child-row-toggler--closed::before {
    content: "+";
  }

  >>>.VueTables__child-row-toggler--open::before {
    content: "-";
  }
</style>