<template>
  <div>
    <el-row class="header-row">
      <h3 class="el-page-header">Blended Rates</h3>
      <small style="margin-top: -15px; font-style: italic; display: block; width: 50%" v-if="isTrucodeEnabled()">When TruCode grouping is activated, blended rates are used to calculate reimbursements for groupers other than MS-DRG, for instance, APR-DRG</small>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="24">
        <el-form :model="blendedRatesForm" size="mini" ref="blendedRatesForm" :rules="blendedRatesForm.rules" label-position="left" label-width="180px">
          <template v-if="!showblendedRatesForm">
            <el-button class="hiaButton" plain round size="mini" @click="toggleblendedRatesForm()" icon="el-icon-plus">Add Blended Rate </el-button>
          </template>
          <el-form-item v-if="showblendedRatesForm" prop="clientId" label="Facility Name">
            <!-- <el-input size="mini" type="text" v-model="blendedRatesForm.clientId" id="clientName"></el-input> -->
            <el-select size="mini" v-model="blendedRatesForm.clientId" filterable default-first-option placeholder="Facility">
              <el-option v-for="item in clientList" :key="item.clientId" :label="item.clientName" :value="item.clientId"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="showblendedRatesForm" prop="blendedRateType" label="Type">
            <!-- <el-input size="mini" type="text" v-model="blendedRatesForm.medicareProvider" id="medicareProvider"></el-input> -->
            <el-select size="mini" v-model="blendedRatesForm.blendedRateType" filterable default-first-option placeholder="Blended Rate Type">
              <el-option v-for="item in dropdowns.BlendedRateTypes.list" :key="item.key" :label="item.value" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="showblendedRatesForm" prop="effectiveDate" label="Effective Date">
            <el-date-picker size="mini" type="date" id="revDate" v-model="blendedRatesForm.effectiveDate" format="MM/dd/yyyy" placeholder="Effective Date"> </el-date-picker>
          </el-form-item>
          <el-form-item v-if="showblendedRatesForm" prop="blendedRate" label="Blended Rate">
            <el-input type="number" step="1" v-model="blendedRatesForm.blendedRate"></el-input>
          </el-form-item>
          <el-form-item v-if="showblendedRatesForm">
            <el-button-group>
              <el-button class="hiaButton" plain round size="mini" type="text" @click="toggleblendedRatesForm()">Cancel</el-button>
              <el-button class="hiaButton" plain round size="mini" @click="postBlendedRate()">Add</el-button>
            </el-button-group>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row :gutter="10" style="margin: 10px 0px 0px 0px;">
      <el-col :lg="24" :md="24">
        <v-client-table class="hiaTable" v-loading="blendedRatesLoading" element-loading-spinner="atom-audit-loader" ref="bendedRatesGrid" id="bendedRatesGrid" :data="blendedRatesList" :columns="blendedRatesColumns" :options="blendedRatesOptions">
          <div slot="filter__inactive">
            <input type="checkbox" @change="$refs.bendedRatesGrid.setFilter({ inactive: $event.target.checked })" />
          </div>
          <template slot="clientId" slot-scope="props">
            <el-select size="mini" v-model="props.row.clientId" filterable allow-create default-first-option placeholder="Edit Facility" @change="putBlendedRate(props.row)">
              <el-option v-for="item in clientList" :key="item.clientId" :label="item.clientName" :value="item.clientId"> </el-option>
            </el-select>
          </template>

          <template slot="blendedRateType" slot-scope="props">
            <el-select size="mini" v-model="props.row.blendedRateType" filterable allow-create default-first-option placeholder="Edit Blended Rate" @change="putBlendedRate(props.row)">
              <el-option v-for="item in dropdowns.BlendedRateTypes.list" :key="item.key" :label="item.value" :value="item.value"> </el-option>
            </el-select>
          </template>

          <template slot="effectiveDate" slot-scope="props">
            <el-date-picker size="mini" type="date" id="revDate" :clearable="false" v-model="props.row.effectiveDate" @input="putBlendedRate(props.row)" format="MM/dd/yyyy"> </el-date-picker>
          </template>

          <template slot="blendedRate" slot-scope="props">
            <!-- <el-input size="mini" :disabled="readOnly()" type="text" v-model.lazy="props.row.blendedRate" @change="putBlendedRate(props.row)">
              <template slot="prepend">$</template>
            </el-input> -->
            <div style="display: flex;">
              <div style="padding: 5px;">
                $
              </div>
              <div class="el-input--mini">
                <input class="el-input__inner" type="number" v-model="props.row.blendedRate" @change="putBlendedRate(props.row)" />
              </div>
            </div>
          </template>

          <template slot="inactive" slot-scope="props">
            <input :key="props.row.clientId" type="checkbox" v-model="props.row.inactive" @change="putBlendedRate(props.row)" />
          </template>
          <template slot="delete" slot-scope="props">
            <div style="text-align: center;">
              <i class="el-icon-close delete-button" title="Delete Facility" @click="deleteBlendedRate(props.row)"></i>
            </div>
          </template>
        </v-client-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import HiaAuthorization from '@/mixins/hiaAuthorization'
  // import { mapFields } from 'vuex-map-fields'

  export default {
    name: 'blendedRates',
    mixins: [HiaAuthorization],
    data() {
      return {
        showblendedRatesForm: false,
        blendedRatesForm: {
          blendedRateType: null,
          effectiveDate: null,
          clientId: null,
          blendedRate: null,
          rules: {
            blendedRateType: [{ required: true, trigger: 'change', message: 'Type is required' }],
            effectiveDate: [{ required: true, trigger: 'change', message: 'Effective Date is required' }],
            clientId: [{ required: true, trigger: 'change', message: 'Facility is required' }],
            blendedRate: [{ required: true, trigger: 'change', message: 'Blended rate is required' }]
          }
        },
        blendedRatesColumns: ['clientId', 'blendedRateType', 'effectiveDate', 'blendedRate', 'delete'],
        blendedRatesOptions: {
          filterByColumn: true,
          orderBy: {
            column: 'clientName',
            ascending: true
          },
          headings: {
            clientId: 'Facility Name',
            blendedRate: 'Blended Rate',
            effectiveDate: 'Effective Date',
            blendedRateType: 'Type',
            delete: ''
          },
          perPage: 10,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' }
        }
      }
    },
    methods: {
      ...mapActions('managedLists/blendedRates/', ['GET_BLENDED_RATES', 'PUT_BLENDED_RATES', 'POST_BLENDED_RATES', 'DELETE_BLENDED_RATES']),
      ...mapMutations('dropdowns/', ['REMOVE_CACHED_DROPDOWN']),
      initialize() {
        this.GET_BLENDED_RATES()
      },
      toggleblendedRatesForm() {
        this.showblendedRatesForm = !this.showblendedRatesForm
      },
      postBlendedRate() {
        this.$refs.blendedRatesForm.validate(async valid => {
          if (valid) {
            try {
              const payload = {
                clientId: this.blendedRatesForm.clientId,
                blendedRate: this.blendedRatesForm.blendedRate,
                effectiveDate: this.blendedRatesForm.effectiveDate,
                blendedRateType: this.blendedRatesForm.blendedRateType
              }
              await this.POST_BLENDED_RATES(payload)
              this.clearForm()
              this.toggleblendedRatesForm()
              this.$message({
                message: 'Blended Rate Added Successfully',
                type: 'success'
              })
            } catch (err) {
              this.$message({
                message: 'Blended Rate Add Error',
                type: 'error'
              })
            }
          }
        })
      },
      async putBlendedRate(row) {
        const index = this.blendedRatesList.findIndex(x => x.blendedRateId === row.blendedRateId)
        this.$set(this.blendedRatesList, index, row)

        try {
          await this.PUT_BLENDED_RATES(row)
          this.$message({
            message: 'Blended Rate Updated Successfully',
            type: 'success'
          })
        } catch (err) {
          this.$message({
            message: 'Blended Rate Update Error',
            type: 'error'
          })
        }
      },
      async deleteBlendedRate(row) {
        await this.$confirm('Permanently delete Blended Rate? This may affect financial reporting information.', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        })

        try {
          await this.DELETE_BLENDED_RATES(row.blendedRateId)
          this.$message({
            message: 'Blended Rate Delete Successful',
            type: 'success'
          })
        } catch (err) {
          this.$message({
            message: `Error Deleting Blended Rate: ${err.message}`,
            type: 'error'
          })
        }
      },
      clearForm() {
        this.blendedRatesForm = {
          clientName: null
        }
      }
    },
    mounted: function() {
      this.initialize()
    },
    activated: function() {
      this.initialize()
    },
    computed: {
      // ...mapFields(['payorList.payorName']),
      ...mapState('dropdowns/', ['dropdowns']),
      ...mapState('managedLists/blendedRates/', ['blendedRatesList', 'blendedRatesLoading']),
      ...mapState('managedLists/clients/', ['clientList'])
    }
  }
</script>

<style scoped>
  >>> .el-input-group__prepend {
    padding: 0px 8px;
  }
</style>
