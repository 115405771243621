<template>
  <div id="Practice Provider">
    <div style="height: 100px;" v-if="initLoading" v-loading="initLoading" element-loading-spinner="atom-audit-loader">
    </div>
    <template v-if="!initLoading">
      <!-- <div class="header-row">
        <h3>Practices</h3>
      </div>
      <el-row :gutter="10">
        <el-col :lg="8" :md="12">
          <el-button v-if="!showAddPracticeForm && !disabled" size="mini" @click="toggleAddPracticeForm()" icon="el-icon-plus">Add
            New Practice</el-button>
          <el-form v-if="showAddPracticeForm" :model="addPracticeForm" ref="addPracticeForm" :rules="addPracticeForm.rules" label-position="left" size="mini"
            label-width="180px">
            <el-form-item prop="practiceName" label="Practice Name">
              <el-input v-model="addPracticeForm.practiceName" size="mini"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button size="mini" type="primary" @click="addPractice">Add</el-button>
              <el-button size="mini" @click="toggleAddPracticeForm()">Cancel</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :lg="12" :md="18">
          <v-client-table class="hiaTable topMargin" v-loading="practicesLoading" element-loading-spinner="atom-audit-loader" ref="practiceListsGrid" id="practiceListsGrid"
            :data="practices" :columns="practiceColumns" :options="tableOptions">
            <div slot="filter__inactive">
              <input type="checkbox" @change="$refs.practiceListsGrid.setFilter({inactive: $event.target.checked})">
            </div>
            <template slot="practiceName" slot-scope="props">
              <div class="el-input--mini">
                <input class="el-input__inner" :disabled="disabled" type="text" v-model="props.row.practiceName" @change="updatePractice(props.row)">
              </div>
            </template>
            <template slot="inactive" slot-scope="props">
              <input :key="props.row.practiceId" :disabled="disabled" type="checkbox" v-model="props.row.inactive" @change="updatePractice(props.row)">
            </template>
            <template slot="delete" slot-scope="props">
              <div style="text-align: center;">
                <i class="el-icon-close delete-button" @click="deletePractice(props.row)"></i>
              </div>
            </template>
            <div slot="child_row" slot-scope="props">
              <el-form style="margin-left: 50px;" class="topMargin" size="mini" :model="associateProviderForm" ref="associateProviderForm" :rules="associateProviderForm.rules"
                label-position="left" label-width="120px">
                <el-button v-if="!showAssociateProviderForm && !disabled" type="primary" size="mini" @click="toggleAssociateProviderForm(props.row)" icon="el-icon-plus">
                  Associate Provider</el-button>
                <template v-if="showAssociateProviderForm">
                  <el-form-item prop="providerId" label="Provider">
                    <hia-el-select v-model="associateProviderForm.providerId" :dropdowns="providers" placeholder="Provider" :filterable="true" itemkey="providerId"
                      label="name"></hia-el-select>
                  </el-form-item>
                  <el-form-item>
                    <el-button size="mini" type="primary" @click="associateProvider(props.row)">Add</el-button>
                    <el-button size="mini" @click="toggleAssociateProviderForm(props.row)">Cancel</el-button>
                  </el-form-item>
                </template>
              </el-form>
              <v-client-table class="hiaTable subTable" v-loading="practicesProvidersLoading" element-loading-spinner="atom-audit-loader"
                :ref="`providerListsGrid-${props.row.practiceId}`" :id="`providerListsGrid-${props.row.practiceId}`"
                :data="providers.filter(x => props.row.clientPracticesProvidersNew.some(y => y.providerId === x.providerId)).map(m => {return {name: m.name, providerId: m.providerId, practiceId: props.row.practiceId}})"
                :columns="['name', 'delete']" :options="tableOptions">
                <template slot="delete" slot-scope="props">
                  <div style="text-align: center;">
                    <i :disabled="disabled" class="el-icon-close delete-button" @click="disassociatePracticeProvider(props.row)"></i>
                  </div>
                </template>
              </v-client-table>
            </div>
          </v-client-table>
        </el-col>
      </el-row> -->
      <div class="header-row">
        <h3>Providers</h3>
      </div>
      <el-row>
        <el-col :lg="8" :md="12">
          <el-button-group>
            <el-button v-if="!showAddProviderForm" class="hiaButton" plain round size="mini" @click="toggleAddProviderForm()" icon="el-icon-plus">Add
              New Provider</el-button>
            <el-button v-if="!showAddProviderForm" :disabled="disableMergeButton" class="hiaButton" plain round size="mini" @click="toggleMergeProviders()">Merge
              Multiple</el-button>
          </el-button-group>
          <el-form v-if="showAddProviderForm" :model="addProviderForm" ref="addProviderForm" :rules="addProviderForm.rules" label-position="left" size="mini"
            label-width="180px">
            <el-form-item prop="providerName" label="Provider Name">
              <el-input v-model="addProviderForm.providerName" size="mini"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button-group>
                <el-button class="hiaButton" plain round size="mini" type="text" @click="toggleAddProviderForm()">Cancel</el-button>
                <el-button class="hiaButton" plain round size="mini" @click="addProvider">Add</el-button>
              </el-button-group>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-row style="margin: 10px 0px 0px 0px;">
        <el-col :lg="12" :md="18">
          <v-client-table class="hiaTable topMargin" v-loading="providersLoading" element-loading-spinner="atom-audit-loader" ref="providerListGrid" id="providerListGrid"
            :data="providers" :columns="providerColumns" :options="tableOptions" uniqueKey="providerId">
            <div slot="filter__inactive">
              <input type="checkbox" @change="$refs.providerListGrid.setFilter({inactive: $event.target.checked})">
            </div>
            <template slot="selected" slot-scope="props">
              <input type="checkbox" v-model="props.row.selected" @click="selectRecord(props.row, $event)">
            </template>
            <template slot="name" slot-scope="props">
              <div class="el-input--mini">
                <input class="el-input__inner" :disabled="disabled" type="text" v-model="props.row.name" @change="updateProvider(props.row)">
              </div>
            </template>
            <template slot="inactive" slot-scope="props">
              <input :key="props.row.providerId" :disabled="disabled" type="checkbox" v-model="props.row.inactive" @change="updateProvider(props.row)">
            </template>
            <template slot="delete" slot-scope="props">
              <div style="text-align: center;">
                <i :disabled="disabled" class="el-icon-close delete-button" @click="deleteProvider(props.row)"></i>
              </div>
            </template>
          </v-client-table>
        </el-col>
      </el-row>
    </template>

    <el-dialog title="Merge Providers" :visible.sync="showMergeProviders" width="50%">
      <div v-loading="mergeProvidersLoading" element-loading-spinner="atom-audit-loader">
        Select the target Provider record to merge to:
        <table style="margin: 20px 0px 20px 20px;">
          <tr v-for="provider in providers.filter(x => x.selected).sort((a,b) => a.name < b.name ? -1 : 1)" :key="provider.providerId">
            <td class="providerOption" :class="{selected: provider.providerId === selectedMergeProvider.providerId}" @click="selectedMergeProvider = provider">{{ provider.name
              }} </td>
            <td><i v-if="provider.providerId === selectedMergeProvider.providerId" class="mdi mdi-check" style="color: #63b344; margin-left: 8px;"></i>
            </td>
          </tr>
        </table>
        <span v-if="selectedMergeProvider.name">Merge all listed Providers to {{ selectedMergeProvider.name }}</span>
        <div style="margin-bottom: 40px;">
          <div class="pull-right">
            <el-button-group>
              <el-button class="hiaButton" plain round size="mini" type="text" @click="toggleMergeProviders">Cancel</el-button>
              <el-button :disabled="!selectedMergeProvider.name" class="hiaButton" plain round size="mini" @click="doMergeProviders">
                Continue
              </el-button>
            </el-button-group>
          </div>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import HiaAuthorization from '@/mixins/hiaAuthorization'
  import hiaTable from '@/mixins/hiaTable'

  export default {
    name: 'practiceProviderTab',
    mixins: [hiaTable, HiaAuthorization],
    props: ['disabled'],
    data() {
      return {
        initLoading: true,
        showMergeProviders: false,
        mergeProvidersLoading: false,
        selectedMergeProvider: {},
        practicesLoading: false,
        practicesProvidersLoading: false,
        providersLoading: false,
        providerList: [],
        providerColumns: ['selected', 'name', 'inactive', 'delete'],
        practiceColumns: ['practiceName', 'inactive', 'delete'],
        tableOptions: {
          // see the options API
          filterByColumn: true,
          sortable: ['name', 'practiceName'],
          orderBy: {
            column: 'name',
            ascending: true
          },
          headings: {
            delete: function (h) {
              return ''
            },
            selected: (h) => {
              return h('input', {
                attrs: {
                  type: 'checkbox',
                  id: 'providerSelectAllCheckbox',
                  disabled: false // this.readOnly()
                },
                on: {
                  click: (e) => {
                    this.localselectAll(e.srcElement.checked)
                  }
                },
                ref: 'selectAllCheckbox'
              })
            },
            practiceName: 'Practice Name'
          },
          perPage: 10,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' },
          uniqueKey: 'practiceId'
        },
        showAddPracticeForm: false,
        addPracticeForm: {
          practiceName: '',
          acp: '',
          rules: {
            practiceName: [
              { required: true, message: 'Please Input Practice Name', trigger: 'none' }
            ]
          }
        },
        showAddProviderForm: false,
        addProviderForm: {
          providerName: '',
          rules: {
            providerName: [
              { required: true, message: 'Please Input Provider Name', trigger: 'none' }
            ]
          }
        },
        showAssociateProviderForm: false,
        associateProviderForm: {
          providerId: '',
          rules: {
            providerId: [
              { required: true, message: 'Please Select Provider Name', trigger: 'none' }
            ]
          }
        }
      }
    },
    methods: {
      ...mapActions('managedLists/practices', ['GET_PRACTICES', 'PUT_PRACTICE', 'POST_PRACTICE', 'DELETE_PRACTICE', 'GET_PROVIDERS_NEW', 'POST_PROVIDER_NEW', 'PUT_PROVIDER_NEW', 'DELETE_PROVIDER_NEW', 'POST_ASSOCIATE_PROVIDER_NEW', 'DELETE_DISASSOCIATE_PROVIDER_NEW']),
      ...mapActions('managedLists/providers', ['GET_PROVIDERS_NEW', 'POST_PROVIDER_NEW', 'PUT_PROVIDER_NEW', 'DELETE_PROVIDER_NEW', 'PUT_MERGE_PROVIDERS']),
      ...mapMutations('managedLists/providers/', ['SET_SELECTED']),
      initialize(route) {
        this.initLoading = true
        // const clientNumber = route.params.clientNumber
        Promise.all([this.GET_PRACTICES(), this.GET_PROVIDERS_NEW()]).then(() => {
          this.initLoading = false
        }, error => {
          console.log(error)
          this.initLoading = false
        })
      },
      localselectAll(checked) {
        let filteredRecords = []

        if (this.$refs.providerListGrid) {
          filteredRecords = this.$refs.providerListGrid.allFilteredData
        }

        const recs = this.providers.filter(x => filteredRecords.some(y => y.providerId === x.providerId))
        hiaTable.selectAll(recs, checked)
        this.SET_SELECTED(recs)
      },
      selectRecord(row, e) {
        const checked = e.srcElement.checked
        row.selected = checked
        this.SET_SELECTED([row])
      },
      addPractice() {
        this.$refs.addPracticeForm.validate(async (valid) => {
          if (valid) {
            try {
              this.practicesLoading = true
              const payload = {
                // clientNumber: this.client.clientNumber,
                practiceName: this.addPracticeForm.practiceName,
                acp: this.addPracticeForm.acp
              }

              await this.POST_PRACTICE(payload)
              this.$refs.addPracticeForm.resetFields()
              this.practicesLoading = false
              this.$message({
                message: 'Client Practice Added',
                type: 'success'
              })
            } catch (err) {
              this.practicesLoading = false
              this.$message({
                message: 'Error adding Client Practice',
                type: 'error'
              })
            }
          }
        })
      },
      addProvider() {
        this.$refs.addProviderForm.validate(async (valid) => {
          if (valid) {
            try {
              this.providersLoading = true
              const payload = {
                provider: {
                  // clientNumber: this.client.clientNumber,
                  name: this.addProviderForm.providerName
                }
              }

              await this.POST_PROVIDER_NEW(payload)
              this.$refs.addProviderForm.resetFields()
              this.providersLoading = false
              this.$message({
                message: 'Provider Created',
                type: 'success'
              })
            } catch (err) {
              this.providersLoading = false
              this.$message({
                message: 'Error Creating Client Provider: ' + err.message,
                type: 'error'
              })
            }
          }
        })
      },
      associateProvider(practice) {
        this.$refs.associateProviderForm.validate(async (valid) => {
          if (valid) {
            try {
              this.practicesProvidersLoading = true
              const payload = {
                // clientNumber: this.client.clientNumber,
                providerId: this.associateProviderForm.providerId,
                providerName: this.providers.find(x => x.providerId === this.associateProviderForm.providerId).name,
                practiceId: practice.practiceId
              }

              await this.POST_ASSOCIATE_PROVIDER_NEW(payload)
              this.$refs.associateProviderForm.resetFields()
              this.toggleAssociateProviderForm()
              this.practicesProvidersLoading = false
              this.$message({
                message: 'Provider Associated',
                type: 'success'
              })
            } catch (err) {
              this.practicesProvidersLoading = false
              this.$message({
                message: 'Error Associating Provider',
                type: 'error'
              })
            }
          }
        })
      },
      async disassociatePracticeProvider(practiceProvider) {
        try {
          this.practicesProvidersLoading = true
          const payload = {
            // clientNumber: this.client.clientNumber,
            practiceId: practiceProvider.practiceId,
            providerId: practiceProvider.providerId
          }

          await this.DELETE_DISASSOCIATE_PROVIDER_NEW(payload)
          this.practicesProvidersLoading = false
          this.$message({
            message: 'Provider Disassociated',
            type: 'success'
          })
        } catch (err) {
          this.practicesProvidersLoading = false
          this.$message({
            message: 'Error Disassociating Provider',
            type: 'error'
          })
        }
      },
      async updatePractice(practice) {
        const index = this.practices.findIndex(x => x.practiceId === practice.practiceId)
        this.$set(this.practices, index, practice)

        try {
          this.practicesLoading = true
          await this.PUT_PRACTICE(practice)
          this.practicesLoading = false
          this.$message({
            message: 'Practice Saved',
            type: 'success'
          })
        } catch (err) {
          this.practicesLoading = false
          this.$message({
            message: 'Error updating Practice',
            type: 'error'
          })
        }
      },
      async updateProvider(provider) {
        const index = this.providers.findIndex(x => x.providerId === provider.providerId)
        this.$set(this.providers, index, provider)

        try {
          this.providersLoading = true
          await this.PUT_PROVIDER_NEW(provider)
          this.providersLoading = false
          this.$message({
            message: 'Provider Updated',
            type: 'success'
          })
        } catch (err) {
          this.providersLoading = false
          this.$message({
            message: 'Error Updating Client Provider',
            type: 'error'
          })
        }
      },
      async deleteProvider(provider) {
        await this.$confirm(`Permanently delete Provider ${provider.name}?`, 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        })

        try {
          this.providersLoading = true
          const payload = {
            providerId: provider.providerId
            // clientNumber: this.client.clientNumber
          }
          await this.DELETE_PROVIDER_NEW(payload)
          this.providersLoading = false
          this.$message({
            message: 'Provider Deleted',
            type: 'success'
          })
        } catch (err) {
          this.providersLoading = false
          this.$message({
            message: `Error Deleting Provider: ${err.message}`,
            type: 'error'
          })
        }
      },
      async deletePractice(practice) {
        await this.$confirm(`Permanently delete Practice ${practice.practiceName}?`, 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        })

        try {
          this.practicesLoading = true
          await this.DELETE_PRACTICE(practice)
          this.practicesLoading = false
          this.$message({
            message: 'Practice Deleted',
            type: 'success'
          })
        } catch (err) {
          this.practicesLoading = false
          this.$message({
            message: `Error Deleting Practice: ${err.message}`,
            type: 'error'
          })
        }
      },
      toggleAddPracticeForm() {
        this.addPracticeForm.practiceName = ''
        this.showAddPracticeForm = !this.showAddPracticeForm
      },
      toggleAddProviderForm() {
        this.addProviderForm.providerName = ''
        this.showAddProviderForm = !this.showAddProviderForm
      },
      toggleAssociateProviderForm() {
        this.associateProviderForm.providerName = ''
        this.showAssociateProviderForm = !this.showAssociateProviderForm
      },
      toggleMergeProviders() {
        this.selectedMergeProvider = {}
        this.showMergeProviders = !this.showMergeProviders
      },
      doMergeProviders() {
        this.$confirm(`Update all existing Review Records for listed Providers to ${this.selectedMergeProvider.name}?`, 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          this.mergeProvidersLoading = true
          const payload = {
            // clientNumber: this.$route.params.clientNumber,
            targetProviderId: this.selectedMergeProvider.providerId,
            providers: this.providers.filter(x => x.selected && x.providerId !== this.selectedMergeProvider.providerId)
          }
          this.PUT_MERGE_PROVIDERS(payload).then(response => {
            this.toggleMergeProviders()
            this.localselectAll(false)

            const table = this.$refs.providerListGrid.$el
            const checkboxes = table.querySelectorAll('input[type ="checkbox"]')

            for (let i = 0; i < checkboxes.length; i++) {
              checkboxes[i].checked = false
            }

            this.mergeProvidersLoading = false
          }, error => {
            console.log(error)
            this.mergeProvidersLoading = false
          })
        })
      }
    },
    computed: {
      // ...mapState('clients/', ['client']),
      ...mapState('managedLists/practices/', ['practices']),
      ...mapState('managedLists/providers/', ['providers']),
      ...mapState('user/', ['currentTenantGuid']),
      disableMergeButton() {
        return this.providers.filter(x => x.selected).length < 2
      }
    },
    watch: {
      currentTenantGuid() {
        this.initialize(this.$route)
      }
    },
    created: function () {
      this.initialize(this.$route)
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
  .providerOption {
    cursor: pointer;
    padding: 0px 20px 0px 20px;
    color: #337ab7;
  }

  .providerOption:hover {
    background: rgba(255, 196, 12, 0.2);
    border-radius: 2px;
  }

  .selected {
    background: rgba(255, 196, 12, 0.2);
  }

  >>>.subTable table {
    border: none !important;
  }

  >>>.subTable tbody {
    border: none !important;
  }

  >>>.subTable .VueTables__child-row {
    border: none !important;
  }

  >>>.subTable .VueTables__child-row>td {
    border: none !important;
  }

  >>>#minutesToReviewGrid .VuePagination__count {
    display: none;
  }

  >>>.subTable {
    margin-top: 10px;
    margin-left: 50px;
    margin-bottom: -8px;
  }

  >>>.subTable .VuePagination__count {
    display: none;
  }

  >>>.VueTables__child-row-toggler {
    width: 16px;
    height: 16px;
    line-height: 16px;
    display: block;
    margin: auto;
    text-align: center;
  }

  >>>.VueTables__child-row-toggler--closed::before {
    content: "+";
  }

  >>>.VueTables__child-row-toggler--open::before {
    content: "-";
  }
</style>