<template>
  <div>
    <el-row class="header-row">
      <h3 class="el-page-header">Compliance Findings List</h3>
    </el-row>
    <el-row :gutter="10">
      <el-col :lg="8" :md="12">
        <el-form :model="proComplianceReasonForChangeForm" size="mini" ref="proComplianceReasonForChangeForm" :rules="proComplianceReasonForChangeForm.rules" label-position="left" label-width="180px">
          <template v-if="!showproComplianceReasonForChangeForm">
            <el-button class="hiaButton" size="mini" plain round style="margin-bottom: 10px;" @click="toggleproComplianceReasonForChangeForm()" icon="el-icon-plus">Add
              Compliance Finding
            </el-button>
          </template>
          <el-form-item v-if="showproComplianceReasonForChangeForm" prop="description" label="Description">
            <el-input size="mini" type="text" v-model="proComplianceReasonForChangeForm.description" id="description"></el-input>
          </el-form-item>
          <el-form-item v-if="showproComplianceReasonForChangeForm">
            <el-button-group>
              <el-button class="hiaButton" plain round size="mini" type="text" @click="toggleproComplianceReasonForChangeForm()">Cancel</el-button>
              <el-button class="hiaButton" plain round size="mini" @click="postProReasonForChange()">Add</el-button>
            </el-button-group>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :lg="12" :md="18">
        <v-client-table class="hiaTable" v-loading="proComplianceReasonForChangeLoading" element-loading-spinner="atom-audit-loader" ref="proComplianceReasonForChangeGrid" id="proComplianceReasonForChangeGrid" :data="proComplianceReasonForChangeList" :columns="proComplianceReasonForChangeColumns" :options="proComplianceReasonForChangeOptions">
          <div slot="filter__inactive">
            <input type="checkbox" @change="$refs.proComplianceReasonForChangeGrid.setFilter({inactive: $event.target.checked})">
          </div>
          <template slot="description" slot-scope="props">
            <div class="el-input--mini">
              <input class="el-input__inner" type="text" v-model="props.row.description" @change="putProReasonForChange(props.row)">
            </div>
          </template>
          <template slot="inactive" slot-scope="props">
            <input :key="props.row.reasonForChangeId" type="checkbox" v-model="props.row.inactive" @change="putProReasonForChange(props.row)">
          </template>
          <template slot="delete" slot-scope="props">
            <div style="text-align: center;">
              <i class="el-icon-close delete-button" @click="deleteProReasonForChange(props.row)"></i>
            </div>
          </template>
        </v-client-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import HiaAuthorization from '@/mixins/hiaAuthorization'

  export default {
    name: 'proComplianceReasonForChange',
    mixins: [HiaAuthorization],
    data() {
      return {
        showproComplianceReasonForChangeForm: false,
        proComplianceReasonForChangeForm: {
          reasonNumber: null,
          description: null,
          rules: {
            reasonNumber: [
              { required: true, trigger: 'change' }
            ],
            description: [
              { required: true, trigger: 'change' }
            ]
          }
        },
        proComplianceReasonForChangeColumns: ['description', 'inactive', 'delete'],
        proComplianceReasonForChangeOptions: {
          filterByColumn: true,
          headings: {
            reasonNumber: 'Reason Number',
            description: 'Description',
            delete: ''
          },
          perPage: 10,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' }
        }
      }
    },
    methods: {
      ...mapActions('managedLists/proComplianceReasonForChanges/', ['PUT_PRO_COMPLIANCE_REASONFORCHANGES', 'POST_PRO_COMPLIANCE_REASONFORCHANGES', 'DELETE_PRO_COMPLIANCE_REASONFORCHANGES']),
      toggleproComplianceReasonForChangeForm() {
        this.showproComplianceReasonForChangeForm = !this.showproComplianceReasonForChangeForm
      },
      postProReasonForChange() {
        this.$refs.proComplianceReasonForChangeForm.validate(async (valid) => {
          if (valid) {
            try {
              const payload = {
                proComplianceReasonForChange: {
                  description: this.proComplianceReasonForChangeForm.description
                }
              }
              await this.POST_PRO_COMPLIANCE_REASONFORCHANGES(payload)
              this.clearForm()
              this.$message({
                message: 'Compliance Finding Add Successful',
                type: 'success'
              })
            } catch (err) {
              this.$message({
                message: 'Compliance Finding Add Error',
                type: 'error'
              })
            }
          }
        })
      },
      async putProReasonForChange(row) {
        const index = this.proComplianceReasonForChangeList.findIndex(x => x.reasonForChangeId === row.reasonForChangeId)
        this.$set(this.proComplianceReasonForChangeList, index, row)

        try {
          await this.PUT_PRO_COMPLIANCE_REASONFORCHANGES(row)
          this.$message({
            message: 'Compliance Finding Updated Successfully',
            type: 'success'
          })
        } catch (err) {
          this.$message({
            message: 'Compliance Finding Updated Error',
            type: 'error'
          })
        }
      },
      async deleteProReasonForChange(row) {
        await this.$confirm(`Permanently delete Compliance Finding ${row.description}?`, 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        })

        try {
          await this.DELETE_PRO_COMPLIANCE_REASONFORCHANGES(row.reasonForChangeId)
          this.$message({
            message: 'Compliance Finding Deleted',
            type: 'success'
          })
        } catch (err) {
          this.providersLoading = false
          this.$message({
            message: `Error Deleting Compliance Finding: ${err.message}`,
            type: 'error'
          })
        }
      },
      clearForm() {
        this.proComplianceReasonForChangeForm = {
          description: null
        }
      }
    },
    computed: {
      // ...mapFields(['payorList.payorName']),
      ...mapState('managedLists/proComplianceReasonForChanges/', ['proComplianceReasonForChangeList', 'proComplianceReasonForChangeLoading'])
    }
  }
</script>
