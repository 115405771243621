<template>
  <div>
    <el-row class="header-row">
      <h3 class="el-page-header">Outpatient Patient Types</h3>
    </el-row>
    <el-row :gutter="10">
      <el-col :lg="8" :md="12">
        <el-form :model="ascPatientTypesForm" size="mini" ref="ascPatientTypesForm" :rules="ascPatientTypesForm.rules" label-position="left" label-width="180px">
          <template v-if="!showascPatientTypesForm">
            <el-button class="hiaButton" plain round size="mini" @click="toggleascPatientTypesForm()" icon="el-icon-plus">Add New Patient Type </el-button>
          </template>
          <el-form-item v-if="showascPatientTypesForm" prop="description" label="Patient Type">
            <el-input size="mini" type="text" v-model="ascPatientTypesForm.description" id="description"></el-input>
          </el-form-item>
          <el-form-item v-if="showascPatientTypesForm">
            <el-button-group>
              <el-button class="hiaButton" plain round size="mini" type="text" @click="toggleascPatientTypesForm()">Cancel</el-button>
              <el-button class="hiaButton" plain round size="mini" @click="postAscPatientType()">Add</el-button>
            </el-button-group>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row style="margin: 10px 0px 0px 0px;">
      <el-col :lg="12" :md="18">
        <v-client-table class="hiaTable" v-loading="ascPatientTypesLoading" element-loading-spinner="atom-audit-loader" ref="codeTypesGrid" id="codeTypesGrid" :data="ascPatientTypesList" :columns="codeTypeColumns" :options="codeTypeOptions">
          <div slot="filter__inactive">
            <input type="checkbox" @change="$refs.codeTypesGrid.setFilter({ inactive: $event.target.checked })" />
          </div>
          <div slot="filter__outpatientReview">
            <input type="checkbox" @change="$refs.codeTypesGrid.setFilter({ outpatientReview: $event.target.checked })" />
          </div>
          <div slot="filter__ambulatoryReview">
            <input type="checkbox" @change="$refs.codeTypesGrid.setFilter({ ambulatoryReview: $event.target.checked })" />
          </div>
          <template slot="outpatientReview" slot-scope="props">
            <input :key="props.row.patientTypeId" type="checkbox" v-model="props.row.outpatientReview" @change="putPatientType(props.row)" />
          </template>
          <template slot="ambulatoryReview" slot-scope="props">
            <input :key="props.row.patientTypeId" type="checkbox" v-model="props.row.ambulatoryReview" @change="putPatientType(props.row)" />
          </template>
          <template slot="inactive" slot-scope="props">
            <input :key="props.row.patientTypeId" type="checkbox" v-model="props.row.inactive" @change="putPatientType(props.row)" />
          </template>
          <template slot="description" slot-scope="props">
            <div class="el-input--mini">
              <input class="el-input__inner" type="text" v-model="props.row.description" @change="putPatientType(props.row)" />
            </div>
          </template>
          <!-- <template slot="inactive" slot-scope="props">
            <input :key="props.row.patientTypeId" type="checkbox" v-model="props.row.inactive" @change="putPatientType(props.row)">
          </template> -->
          <template slot="delete" slot-scope="props">
            <div style="text-align: center;">
              <i class="el-icon-close delete-button" title="Delete Patient Type" @click="deleteAscPatientType(props.row)"></i>
            </div>
          </template>
        </v-client-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import HiaAuthorization from '@/mixins/hiaAuthorization'
  // import { mapFields } from 'vuex-map-fields'

  export default {
    name: 'codeTypes',
    mixins: [HiaAuthorization],
    data() {
      return {
        showascPatientTypesForm: false,
        ascPatientTypesForm: {
          patientType: '',
          description: '',
          rules: {
            patientType: [{ required: true, trigger: 'change' }],
            description: [{ required: true, trigger: 'change' }]
          }
        },
        codeTypeColumns: ['description', 'outpatientReview', 'ambulatoryReview', 'inactive', 'delete'],
        codeTypeOptions: {
          filterByColumn: true,
          orderBy: {
            column: 'description',
            ascending: true
          },
          headings: {
            patientType: 'Patient Type',
            ambulatoryReview: 'Ambulatory Review',
            outpatientReview: 'Outpatient Review',
            description: function(h) {
              return 'Description'
            },
            delete: function(h) {
              return ''
            }
          },
          perPage: 10,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' }
        }
      }
    },
    methods: {
      ...mapActions('managedLists/ascPatientTypes/', ['GET_ASC_PATIENT_TYPES', 'PUT_ASC_PATIENT_TYPES', 'POST_ASC_PATIENT_TYPES', 'DELETE_ASC_PATIENT_TYPES']),
      ...mapMutations('dropdowns/', ['REMOVE_CACHED_DROPDOWN']),
      initialize() {
        this.GET_ASC_PATIENT_TYPES()
      },
      toggleascPatientTypesForm() {
        this.showascPatientTypesForm = !this.showascPatientTypesForm
      },
      postAscPatientType() {
        this.$refs.ascPatientTypesForm.validate(async valid => {
          if (valid) {
            try {
              const payload = {
                ascPatientType: {
                  patientType: this.ascPatientTypesForm.patientType,
                  description: this.ascPatientTypesForm.description
                }
              }
              await this.POST_ASC_PATIENT_TYPES(payload)
              this.REMOVE_CACHED_DROPDOWN('opPatientTypes')
              this.clearForm()
              this.$message({
                message: 'Patient Type Added Successful',
                type: 'success'
              })
            } catch (err) {
              this.$message({
                message: 'Patient Type Added Error',
                type: 'error'
              })
            }
          }
        })
      },
      async putPatientType(row) {
        const index = this.ascPatientTypesList.findIndex(x => x.patientTypeId === row.patientTypeId)
        this.$set(this.ascPatientTypesList, index, row)

        try {
          await this.PUT_ASC_PATIENT_TYPES(row)
          this.REMOVE_CACHED_DROPDOWN('opPatientTypes')
          this.$message({
            message: 'Patient Type Updated Successfully',
            type: 'success'
          })
        } catch (err) {
          this.$message({
            message: 'Patient Type Updated Error',
            type: 'error'
          })
        }
      },
      async deleteAscPatientType(row) {
        await this.$confirm(`Permanently delete Patient Type ${row.patientType}?`, 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        })

        try {
          await this.DELETE_ASC_PATIENT_TYPES(row.patientTypeId)
          this.REMOVE_CACHED_DROPDOWN('opPatientTypes')
          this.$message({
            message: 'Patient Type Delete Successful',
            type: 'success'
          })
        } catch (err) {
          this.$message({
            message: `Error Deleting Patient Type: ${err.message}`,
            type: 'error'
          })
        }
      },
      clearForm() {
        this.ascPatientTypesForm = {
          patientType: '',
          description: ''
        }
        this.showascPatientTypesForm = false
      }
    },
    mounted: function() {
      this.initialize()
    },
    activated: function() {
      this.initialize()
    },
    computed: {
      ...mapState('managedLists/ascPatientTypes/', ['ascPatientTypesList', 'ascPatientTypesLoading'])
    }
  }
</script>
