<template>
  <div v-loading="loading" class="worksheet">
    <div v-for="item in recommendationLibraryPrintable" :key="item.id" style="margin-bottom: 20px;">
      <table class="demographics">
        <tr>
          <td class="strong">Title:</td>
          <td colspan="5">{{ item.title }}</td>
        </tr>
      </table>

      <table class="demographics">
        <tr>
          <td class="strong">Recommendation Type:</td>
          <td>{{ item.recommendationType }}</td>
          <td class="strong">Record Type:</td>
          <td>{{ item.recordType }}</td>
          <td class="strong">Code Group:</td>
          <td>{{ item.codeGroup }}</td>
        </tr>
        <tr>
          <td class="strong">Created By:</td>
          <td>{{ item.createdByName }}</td>
          <td class="strong">Inactive:</td>
          <td colspan="3">{{ item.inactive }}</td>
        </tr>
      </table>

      <table class="demographics">
        <td>
          <div style="padding-bottom: 3px;" class="fontSize bold">Recomendation:</div>
          <span class="recommendation" v-html="item.recommendation"></span>
        </td>
      </table>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import hiaFilters from '@/mixins/hiaFilters'

  export default {
    name: 'RecommendationLibraryListPrintable',
    mixins: [hiaFilters],
    props: ['activatePrint'],
    components: {},
    data: function() {
      return {
        allData: [],
        loading: false
      }
    },
    methods: {
      ...mapActions('recommendationLibrary/', ['GET_RECOMMENDATION_LIBRARY_ALL_PRINTABLE']),
      async getData() {
        try {
          this.loading = true
          await this.GET_RECOMMENDATION_LIBRARY_ALL_PRINTABLE()
          this.$nextTick(() => {
            // get images then set onload with callback to launch print modal when all images finish loading
            const images = document.querySelectorAll('.recommendation img')
            const imagesLength = images.length
            if (imagesLength) {
              for (let i = 0; i < images.length; i++) {
                const img = new window.Image()
                img.src = images[i].src
                img.onload = () => {
                  this.imageOnload(i, imagesLength)
                }
              }
            } else {
              this.loading = false
              this.$emit('printRecommendations')
            }
          })
        } catch (err) {}
      },
      imageOnload(counter, imagesLength) {
        if (counter + 1 === imagesLength) {
          this.loading = false
          this.$emit('printRecommendations')
        }
      }
    },
    computed: {
      ...mapState('recommendationLibrary/', ['recommendationLibraryPrintable'])
    },
    mounted: async function() {
      // await this.getData()
    },
    watch: {
      activatePrint: async function() {
        if (this.activatePrint) {
          await this.getData()
        }
      }
    }
  }
</script>

<style scoped>
  .totalsTable {
    border-spacing: 0px;
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
  }

  .totalsTable td {
    padding: 13px;
  }

  .hiaTable thead tr th {
    font-size: 11px;
  }
  .fontSize {
    font-size: 13px;
  }
  .bold {
    font-weight: bold;
  }

  .strong {
    font-weight: bold;
  }
</style>
