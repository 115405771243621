<template>
  <div id="Coders">
    <el-row class="header-row">
      <h3 class="el-page-header">CDI Staff</h3>
    </el-row>
    <el-form label-position="left" label-width="150px" size="mini">
      <div v-if="!showAddCDIStaffForm">
        <!-- <el-button class="hiaButton" plain round size="mini" v-if="!showAddCDIStaffForm" @click="toggleAddCDIStaffForm()" icon="el-icon-plus">Add CDI Staff
        </el-button> -->
        <el-button-group>
          <el-button class="hiaButton" plain round size="mini" @click="toggleAddCDIStaffForm()" icon="el-icon-plus">Add CDI Staff</el-button>
          <el-button :disabled="disableMergeButton" class="hiaButton" plain round size="mini" @click="toggleMergeCdi()">Merge Multiple</el-button>
        </el-button-group>
      </div>
      <el-row v-if="showAddCDIStaffForm">
        <el-col :lg="8" :md="12">
          <el-form :model="addCDIStaffForm" ref="addCDIStaffForm" size="mini" :rules="addCDIStaffForm.rules" label-position="left" label-width="180px" @submit.native.prevent>
            <el-form-item prop="cdiStaffName" label="CDI Staff Name">
              <el-input v-model="addCDIStaffForm.cdiStaffName" size="mini"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button-group>
                <el-button class="hiaButton" plain round size="mini" type="text" @click="toggleAddCDIStaffForm()">Cancel</el-button>
                <el-button class="hiaButton" plain round size="mini" @click="addCDIStaff">Add</el-button>
              </el-button-group>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-row class="topMargin">
        <el-col :lg="12" :md="18">
          <v-client-table class="hiaTable" v-loading="cdiStaffLoading" element-loading-spinner="atom-audit-loader" ref="cdiStaffGrid" id="cdiStaffGrid" :data="cdiStaffList" :columns="cdiStaffColumns" :options="cdiStaffOptions">
            <div slot="filter__inactive">
              <input type="checkbox" @change="$refs.cdiStaffGrid.setFilter({ inactive: $event.target.checked })" />
            </div>
            <template slot="selected" slot-scope="props">
              <div class="el-input--mini">
                <input type="checkbox" v-model="props.row.selected" @click="selectRecord(props.row, $event)" />
              </div>
            </template>
            <template slot="name" slot-scope="props">
              <div class="el-input--mini">
                <input class="el-input__inner" type="text" v-model="props.row.name" @change="updateCDIStaff(props.row)" />
              </div>
            </template>
            <template slot="inactive" slot-scope="props">
              <input :key="props.row.cdiStaffId" type="checkbox" v-model="props.row.inactive" @change="updateCDIStaff(props.row)" />
            </template>
            <template slot="delete" slot-scope="props">
              <div style="text-align: center;">
                <i class="el-icon-close delete-button" @click="deleteCDIStaff(props.row)"></i>
              </div>
            </template>
          </v-client-table>
        </el-col>
      </el-row>
    </el-form>

    <el-dialog title="Merge CDI Staff" :visible.sync="showMergeCDIStaff" width="50%">
      <div v-loading="mergeCDIStaffLoading" element-loading-spinner="atom-audit-loader">
        Select the target CDI Staff record to merge to:
        <table style="margin: 20px 0px 20px 20px;">
          <tr v-for="cdiStaff in cdiStaffList.filter(x => x.selected).sort((a, b) => (a.name < b.name ? -1 : 1))" :key="cdiStaff.cdiStaffId">
            <td class="coderOption" :class="{ selected: cdiStaff.cdiStaffId === selectedMergeCDI.cdiStaffId }" @click="selectedMergeCDI = cdiStaff">{{ cdiStaff.name }}</td>
            <td><i v-if="cdiStaff.cdiStaffId === selectedMergeCDI.cdiStaffId" class="mdi mdi-check" style="color: #63b344; margin-left: 8px;"></i></td>
          </tr>
        </table>

        <span v-if="selectedMergeCDI.name">Merge all listed CDI Staff to {{ selectedMergeCDI.name }}</span>
        <div style="margin-bottom: 40px;">
          <div class="pull-right">
            <el-button-group>
              <el-button class="hiaButton" plain round size="mini" type="text" @click="toggleMergeCdi">Cancel</el-button>
              <el-button :disabled="!selectedMergeCDI.name" class="hiaButton" plain round size="mini" @click="doMergeCDI">Continue </el-button>
            </el-button-group>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import HiaAuthorization from '@/mixins/hiaAuthorization'
  import hiaTable from '@/mixins/hiaTable'

  export default {
    name: 'cdiStaff',
    mixins: [HiaAuthorization],
    props: [],
    data() {
      return {
        loading: false,
        showMergeCDIStaff: false,
        mergeCDIStaffLoading: false,
        selectedMergeCDI: {},
        cdiStaffColumns: ['selected', 'name', 'inactive', 'delete'],
        cdiStaffOptions: {
          filterByColumn: true,
          sortable: ['name'],
          orderBy: {
            column: 'name',
            ascending: true
          },
          headings: {
            name: function(h) {
              return 'Name'
            },
            delete: function(h) {
              return ''
            },
            selected: h => {
              return h('input', {
                attrs: {
                  type: 'checkbox',
                  id: 'cdiSelectAllCheckbox'
                },
                on: {
                  click: e => {
                    this.localselectAll(e.srcElement.checked)
                  }
                },
                ref: 'selectAllCheckbox'
              })
            }
          },
          perPage: 10,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' }
        },
        showAddCDIStaffForm: false,
        addCDIStaffForm: {
          cdiStaffName: null,
          rules: {
            cdiStaffName: [{ required: true, message: 'Please Input CDI Staff Name', trigger: 'none' }]
          }
        }
      }
    },
    methods: {
      ...mapActions('managedLists/cdiStaff/', ['GET_CDISTAFF_LIST', 'POST_CDISTAFF', 'DELETE_CDISTAFF', 'PUT_CDISTAFF', 'PUT_MERGE_CDISTAFF']),
      ...mapMutations('managedLists/cdiStaff/', ['SET_SELECTED']),
      initialize(route) {
        this.GET_CDISTAFF_LIST()
      },
      localselectAll(checked) {
        let filteredRecords = []

        if (this.$refs.cdiStaffGrid) {
          filteredRecords = this.$refs.cdiStaffGrid.allFilteredData
        }

        const recs = this.cdiStaffList.filter(x => filteredRecords.some(y => y.cdiStaffId === x.cdiStaffId))
        hiaTable.selectAll(recs, checked)
        this.SET_SELECTED(recs)
      },
      selectRecord(row, e) {
        const checked = e.srcElement.checked
        row.selected = checked
        this.SET_SELECTED([row])
      },
      addCDIStaff() {
        this.$refs.addCDIStaffForm.validate(async valid => {
          if (valid) {
            try {
              const payload = {
                cdiStaff: {
                  name: this.addCDIStaffForm.cdiStaffName
                }
              }
              await this.POST_CDISTAFF(payload)
              this.$refs.addCDIStaffForm.resetFields()
              this.$message({
                message: 'CDI Staff Added Successful',
                type: 'success'
              })
            } catch (err) {
              this.$message({
                message: 'CDI Staff Added Error',
                type: 'error'
              })
            }
          }
        })
      },
      async updateCDIStaff(cdiStaff) {
        const index = this.cdiStaffList.findIndex(x => x.cdiStaffId === cdiStaff.cdiStaffId)
        this.$set(this.cdiStaffList, index, cdiStaff)
        try {
          await this.PUT_CDISTAFF(cdiStaff)
          this.$message({
            message: 'CDI Staff Updated Successfully',
            type: 'success'
          })
        } catch (err) {
          this.$message({
            message: 'CDI Staff Updated Error',
            type: 'error'
          })
        }
      },
      async deleteCDIStaff(cdiStaff) {
        await this.$confirm(`Permanently delete CDI Staff ${cdiStaff.name}?`, 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        })

        try {
          await this.DELETE_CDISTAFF(cdiStaff)
          this.$message({
            message: 'CDI Staff Deleted Successfully',
            type: 'success'
          })
        } catch (err) {
          this.$message({
            message: `Error Deleting CDI Staff: ${err.message}`,
            type: 'error'
          })
        }
      },
      toggleAddCDIStaffForm() {
        this.showAddCDIStaffForm = !this.showAddCDIStaffForm
      },
      toggleMergeCdi() {
        this.selectedMergeCDI = {}
        this.showMergeCDIStaff = !this.showMergeCDIStaff
      },
      doMergeCDI() {
        this.$confirm(`Update all existing Facility Review Records for listed CDI Staff to ${this.selectedMergeCDI.name}?`, 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          this.mergeCDIStaffLoading = true
          const payload = {
            targetCdiStaffId: this.selectedMergeCDI.cdiStaffId,
            cdiStaff: this.cdiStaffList.filter(x => x.selected && x.cdiStaffId !== this.selectedMergeCDI.cdiStaffId)
          }
          this.PUT_MERGE_CDISTAFF(payload).then(
            response => {
              this.mergeCDIStaffLoading = false
              this.toggleMergeCdi()
              document.getElementById('cdiSelectAllCheckbox').checked = false
              this.localselectAll(false)
            },
            error => {
              console.log(error)
              this.mergeCDIStaffLoading = false
            }
          )
        })
      }
    },
    mounted: function() {
      this.initialize()
    },
    activated: function() {
      this.initialize()
    },
    computed: {
      ...mapState('managedLists/cdiStaff/', ['cdiStaffList', 'cdiStaffLoading']),
      disableMergeButton() {
        return this.cdiStaffList.filter(x => x.selected).length < 2
      }
    },
    watch: {},
    created: function() {}
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .coderOption {
    cursor: pointer;
    padding: 0px 20px 0px 20px;
    color: #337ab7;
  }

  .coderOption:hover {
    background: rgba(255, 196, 12, 0.2);
    border-radius: 2px;
  }

  .selected {
    background: rgba(255, 196, 12, 0.2);
  }

  .topMargin {
    margin: 10px 0px 0px 0px;
  }
</style>
