<template>
  <div>
    <el-row class="header-row">
      <h3 class="el-page-header">Payor Details</h3>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="20">
        <el-form :model="payorDetailForm" size="mini" ref="payorDetailForm" :rules="payorDetailForm.rules" label-position="left" label-width="180px">
          <template v-if="!showPayorDetailForm">
            <el-button class="hiaButton" plain round size="mini" @click="togglePayorDetailForm('edits')" icon="el-icon-plus">Add Payor Detail </el-button>
          </template>
          <el-form-item v-if="showPayorDetailForm" prop="payorNumberId" label="Payor">
            <el-select size="mini" v-model="payorDetailForm.payorNumberId" filterable allow-create default-first-option placeholder="Select/Add Payor">
              <el-option v-for="item in payorList" :key="item.payorNumber" :label="item.payorName" :value="item.payorNumber"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="showPayorDetailForm" prop="payorDetail" label="Payor Detail">
            <el-input size="mini" type="text" v-model="payorDetailForm.payorDetail" id="payorDetail"></el-input>
          </el-form-item>
          <el-form-item v-if="showPayorDetailForm" style="margin-top: 31px;">
            <el-button-group>
              <el-button class="hiaButton" plain round size="mini" type="text" @click="togglePayorDetailForm('edits')">Cancel</el-button>
              <el-button class="hiaButton" plain round size="mini" @click="postPayorDetail('edits')">Add</el-button>
            </el-button-group>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row :gutter="10" style="margin: 10px 0px 0px 0px;">
      <el-col :span="24">
        <v-client-table class="hiaTable" v-loading="payorDetailsLoading" element-loading-spinner="atom-audit-loader" ref="payorDetailGrid" id="payorDetailGrid" :data="payorDetails" :columns="payorDetailColumns" :options="payorDetailOptions">
          <div slot="filter__inactive">
            <input type="checkbox" @change="$refs.payorDetailGrid.setFilter({ inactive: $event.target.checked })" />
          </div>
          <template slot="payorNumberId" slot-scope="props">
            <el-select size="mini" v-model="props.row.payorNumberId" filterable allow-create default-first-option placeholder="Edit Payor" @change="putPayorDetail(props.row)">
              <el-option v-for="item in payorList" :key="item.payorNumber" :label="item.payorName" :value="item.payorNumber"> </el-option>
            </el-select>
          </template>
          <template slot="payorDetail" slot-scope="props">
            <div class="el-input--mini">
              <input class="el-input__inner" type="text" v-model="props.row.payorDetail" @change="putPayorDetail(props.row)" />
            </div>
          </template>
          <template slot="inactive" slot-scope="props">
            <input :key="props.row.payorDetailId" type="checkbox" v-model="props.row.inactive" @change="putPayorDetail(props.row)" />
          </template>
          <template slot="delete" slot-scope="props">
            <div style="text-align: center;">
              <i class="el-icon-close delete-button" title="Delete Payor Detail" @click="deletePayorDetail(props.row)"></i>
            </div>
          </template>
        </v-client-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import HiaAuthorization from '@/mixins/hiaAuthorization'

  export default {
    name: 'payorDetailList',
    mixins: [HiaAuthorization],
    data() {
      return {
        showPayorDetailForm: false,
        payorDetailForm: {
          payorDetail: '',
          payorNumberId: null,
          rules: {
            payorNumberId: [{ required: true, trigger: 'change' }],
            payorDetail: [{ required: true, trigger: 'change' }]
          }
        },
        payorDetailColumns: ['payorNumberId', 'payorDetail', 'inactive', 'delete'],
        payorDetailOptions: {
          filterByColumn: true,
          orderBy: {
            column: 'payorNumberId',
            ascending: true
          },
          headings: {
            payorNumberId: function(h) {
              return 'Payor'
            },
            payorDetail: function(h) {
              return 'Payor Detail'
            },
            delete: function(h) {
              return ''
            }
          },
          perPage: 10,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' }
        }
      }
    },
    methods: {
      ...mapActions('managedLists/payorDetails/', ['GET_PAYORDETAIL']),
      ...mapActions('managedLists/payorDetails/', ['PUT_PAYORDETAIL', 'POST_PAYORDETAIL', 'DELETE_PAYORDETAIL']),
      initialize() {
        this.GET_PAYORDETAIL()
      },
      togglePayorDetailForm(listName) {
        this.showPayorDetailForm = !this.showPayorDetailForm
      },
      postPayorDetail(listName) {
        this.$refs.payorDetailForm.validate(async valid => {
          if (valid) {
            try {
              const payload = {
                payorDetail: {
                  payorNumberId: this.payorDetailForm.payorNumberId,
                  payorDetail: this.payorDetailForm.payorDetail
                }
              }
              await this.POST_PAYORDETAIL(payload)
              this.clearForm()
              this.$message({
                message: 'Payor Detail Added',
                type: 'success'
              })
            } catch (err) {
              this.$message({
                message: `Error Adding Payor Detail: ${err.message}`,
                type: 'error'
              })
            }
          }
        })
      },
      async putPayorDetail(row) {
        const index = this.payorDetails.findIndex(x => x.payorDetailId === row.payorDetailId)
        this.$set(this.payorDetails, index, row)

        try {
          await this.PUT_PAYORDETAIL(row)
          this.$message({
            message: 'Payor Detail Saved',
            type: 'success'
          })
        } catch (err) {
          this.$message({
            message: 'Error Editing Payor Detail',
            type: 'error'
          })
        }
      },
      async deletePayorDetail(row) {
        await this.$confirm(`Permanently delete Payor Detail ${row.payorDetail}?`, 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        })

        try {
          await this.DELETE_PAYORDETAIL(row.payorDetailId)
          this.$message({
            message: 'Payor Detail Delete Success',
            type: 'success'
          })
        } catch (err) {
          this.$message({
            message: `Error Deleting Payor Detail: ${err.message}`,
            type: 'error'
          })
        }
      },
      clearForm() {
        this.payorDetailForm = {
          payorDetail: '',
          payorNumberId: null
        }
      }
    },
    mounted: function() {
      this.initialize()
    },
    activated: function() {
      this.initialize()
    },
    computed: {
      ...mapState('managedLists/payorDetails/', ['payorDetails', 'payorDetailsLoading']),
      ...mapState('managedLists/payors/', ['payorList'])
    }
  }
</script>

<style scoped>
  /* align with payor table next to this table */
  >>> .VueTables__no-results {
    height: 35px;
  }
</style>
