<template>
  <div id="Coders">
    <el-row class="header-row">
      <h3 class="el-page-header">Coders</h3>
    </el-row>
    <el-form label-position="left" label-width="150px" size="mini">
      <div v-if="!showAddCoderForm">
        <el-button-group>
          <el-button class="hiaButton" plain round size="mini" @click="toggleAddCoderForm()" icon="el-icon-plus">Add Coder</el-button>
          <el-button :disabled="disableMergeButton" class="hiaButton" plain round size="mini" @click="toggleMergeCoder()">Merge
            Multiple</el-button>
        </el-button-group>
      </div>
      <el-row v-if="showAddCoderForm">
        <el-col :lg="8" :md="12">
          <el-form :model="addCoderForm" ref="addCoderForm" size="mini" :rules="addCoderForm.rules" label-position="left" label-width="180px" @submit.native.prevent>
            <el-form-item prop="name" label="Name">
              <el-input v-model="addCoderForm.name" size="mini"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button-group>
                <el-button class="hiaButton" plain round size="mini" type="text" @click="toggleAddCoderForm()">Cancel</el-button>
                <el-button class="hiaButton" plain round size="mini" @click="addCoder">Add</el-button>
              </el-button-group>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-row class="topMargin">
        <el-col :lg="12" :md="18">
          <v-client-table class="hiaTable" v-loading="loading" element-loading-spinner="atom-audit-loader" ref="coderListsGrid" id="coderListsGrid" :data="coderList" :columns="coderColumns" :options="coderOptions" uniqueKey="coderId">
            <div slot="filter__inactive">
              <input type="checkbox" @change="$refs.coderListsGrid.setFilter({inactive: $event.target.checked})">
            </div>
            <template slot="h__email">
              Email <el-tooltip effect="dark" content="Linked user email address, users can be linked to coders via the User Admin menu." placement="top">
                <i class="el-icon-info"></i>
              </el-tooltip>
            </template>
            <template slot="selected" slot-scope="props">
              <div class="el-input--mini">
                <input type="checkbox" v-model="props.row.selected" @click="selectRecord(props.row, $event)">
              </div>
            </template>
            <template slot="name" slot-scope="props">
              <div class="el-input--mini">
                <input class="el-input__inner" type="text" v-model="props.row.name" @change="updateCoder(props.row)">
              </div>
            </template>
            <template slot="inactive" slot-scope="props">
              <input :key="props.row.coderId" type="checkbox" v-model="props.row.inactive" @change="updateCoder(props.row)">
            </template>
            <template slot="email" slot-scope="props">
              {{ props.row.users.length > 0 ? props.row.users.map(x => x.email).join(', ') : null }}
            </template>
            <template slot="delete" slot-scope="props">
              <div style="text-align: center;">
                <i class="el-icon-close delete-button" @click="deleteCoder(props.row)"></i>
              </div>
            </template>
          </v-client-table>
        </el-col>
      </el-row>
    </el-form>

    <el-dialog title="Merge Coders" :visible.sync="showMergeCoder" width="50%">
      <div v-loading="mergeCoderLoading" element-loading-spinner="atom-audit-loader">
        Select the target coder record to merge to:
        <table style="margin: 20px 0px 20px 20px;">
          <tr v-for="coder in coderList.filter(x => x.selected).sort((a,b) => a.name < b.name ? -1 : 1)" :key="coder.coderId">
            <td class="coderOption" :class="{selected: coder.coderId === selectedMergeCoder.coderId}" @click="selectedMergeCoder = coder">{{ coder.name }} </td>
            <td><i v-if="coder.coderId === selectedMergeCoder.coderId" class="mdi mdi-check" style="color: #63b344; margin-left: 8px;"></i>
            </td>
          </tr>
        </table>
        <span v-if="selectedMergeCoder.name">Merge all listed coders to {{ selectedMergeCoder.name }}</span>
        <div style="margin-bottom: 40px;">
          <div class="pull-right">
            <el-button-group>
              <el-button class="hiaButton" plain round size="mini" type="text" @click="toggleMergeCoder">Cancel</el-button>
              <el-button :disabled="!selectedMergeCoder.name" class="hiaButton" plain round size="mini" @click="doMergeCoder">Continue
              </el-button>
            </el-button-group>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import HiaAuthorization from '@/mixins/hiaAuthorization'
  import hiaTable from '@/mixins/hiaTable'

  export default {
    name: 'coderTab',
    mixins: [hiaTable, HiaAuthorization],
    props: [],
    data() {
      return {
        loading: false,
        showMergeCoder: false,
        mergeCoderLoading: false,
        selectedMergeCoder: {},
        localCoder: [],
        coderColumns: ['selected', 'name', 'email', 'inactive', 'delete'],
        coderOptions: {
          filterByColumn: true,
          sortable: ['name'],
          orderBy: {
            column: 'name',
            ascending: true
          },
          filterAlgorithm: {
            email(row, query) {
              return row.users.some(x => x.email.toUpperCase().includes(query.toUpperCase()))
            }
          },
          headings: {
            delete: function (h) {
              return ''
            },
            selected: (h) => {
              return h('input', {
                attrs: {
                  type: 'checkbox',
                  id: 'coderSelectAllCheckbox',
                  disabled: false // this.readOnly()
                },
                on: {
                  click: (e) => {
                    this.localselectAll(e.srcElement.checked)
                  }
                },
                ref: 'selectAllCheckbox'
              })
            }
          },
          perPage: 10,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' }
        },
        showAddCoderForm: false,
        addCoderForm: {
          name: '',
          rules: {
            name: [
              { required: true, message: 'Please Input Name', trigger: 'none' }
            ]
          }
        }
      }
    },
    methods: {
      ...mapActions('managedLists/coders/', ['GET_CODERS', 'POST_CODER', 'DELETE_CODER', 'PUT_CODER', 'PUT_MERGE_CODER']),
      ...mapMutations('managedLists/coders/', ['SET_SELECTED']),
      initialize(route) {
        this.loading = true
        this.GET_CODERS().then(response => {
          this.localCoder = JSON.parse(JSON.stringify(this.coderList))
          this.loading = false
        })
      },
      localselectAll(checked) {
        let filteredRecords = []

        if (this.$refs.coderListsGrid) {
          filteredRecords = this.$refs.coderListsGrid.allFilteredData
        }

        const recs = this.coderList.filter(x => filteredRecords.some(y => y.coderId === x.coderId))
        hiaTable.selectAll(recs, checked)
        this.SET_SELECTED(recs)
      },
      selectRecord(row, e) {
        const checked = e.srcElement.checked
        row.selected = checked
        this.SET_SELECTED([row])
      },
      addCoder() {
        this.$refs.addCoderForm.validate(async (valid) => {
          if (valid) {
            try {
              this.loading = true
              const payload = {
                coder: {
                  name: this.addCoderForm.name
                }
              }
              await this.POST_CODER(payload)
              this.$refs.addCoderForm.resetFields()
              this.toggleAddCoderForm()
              this.loading = false
              this.$message({
                message: 'Coder Added',
                type: 'success'
              })
            } catch (err) {
              this.loading = false
              this.$message({
                message: `Error Adding Coder: ${err.message}`,
                type: 'error'
              })
            }
          }
        })
      },
      async updateCoder(coder) {
        const index = this.coderList.findIndex(x => x.coderId === coder.coderId)
        this.$set(this.coderList, index, coder)

        try {
          this.loading = true
          await this.PUT_CODER(coder)
          this.loading = false
          this.$message({
            message: 'Coder Saved',
            type: 'success'
          })
        } catch (err) {
          this.loading = false
          this.$message({
            message: 'Error Editing Coder',
            type: 'error'
          })
        }
      },
      async deleteCoder(coder) {
        await this.$confirm(`Permanently delete ${coder.name}?`, 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        })

        try {
          this.loading = true
          await this.DELETE_CODER(coder)
          this.loading = false
          this.$message({
            message: 'Coder Deleted Successfully',
            type: 'success'
          })
        } catch (err) {
          this.loading = false
          this.$message({
            message: `Error Deleting Coder: ${err.message}`,
            type: 'error'
          })
        }
      },
      toggleAddCoderForm() {
        this.showAddCoderForm = !this.showAddCoderForm
      },
      toggleMergeCoder() {
        this.selectedMergeCoder = {}
        this.showMergeCoder = !this.showMergeCoder
      },
      doMergeCoder() {
        this.$confirm(`Update all existing Review Records for listed Coder to ${this.selectedMergeCoder.name}?`, 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          this.mergeCoderLoading = true
          const payload = {
            targetCoderId: this.selectedMergeCoder.coderId,
            coder: this.coderList.filter(x => x.selected && x.coderId !== this.selectedMergeCoder.coderId)
          }
          this.PUT_MERGE_CODER(payload).then(response => {
            this.toggleMergeCoder()
            this.localselectAll(false)

            const table = this.$refs.coderListsGrid.$el
            const checkboxes = table.querySelectorAll('input[type ="checkbox"]')

            for (let i = 0; i < checkboxes.length; i++) {
              checkboxes[i].checked = false
            }

            this.mergeCoderLoading = false
          }, error => {
            console.log(error)
            this.mergeCoderLoading = false
          })
        })
      }
    },
    computed: {
      ...mapState('managedLists/clients/', ['clientList']),
      ...mapState('managedLists/coders/', ['coderList', 'coderListLoading']),
      ...mapState('user/', ['currentTenantGuid']),
      disableMergeButton() {
        return this.coderList.filter(x => x.selected).length < 2
      }
    },
    watch: {
      currentTenantGuid() {
        this.initialize(this.$route)
      }
    },
    created: function () {
      this.initialize(this.$route)
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.coderOption {
  cursor: pointer;
  padding: 0px 20px 0px 20px;
  color: #337ab7;
}

.coderOption:hover {
  background: rgba(255, 196, 12, 0.2);
  border-radius: 2px;
}

.selected {
  background: rgba(255, 196, 12, 0.2);
}

.topMargin {
  margin: 10px 0px 0px 0px;
}
</style>
