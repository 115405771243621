<template>
  <div id="Coders">
    <el-row class="header-row">
      <h3 class="el-page-header">Service Lines</h3>
    </el-row>
    <el-form label-position="left" label-width="150px" size="mini">
      <div>
        <el-button class="hiaButton" plain round size="mini" v-if="!showAddServiceLineForm" @click="toggleAddServiceLineForm()" icon="el-icon-plus">Add Service Line </el-button>
      </div>
      <el-row v-if="showAddServiceLineForm">
        <el-col :lg="8" :md="12">
          <el-form :model="addServiceLineForm" ref="addServiceLineForm" size="mini" :rules="addServiceLineForm.rules" label-position="left" label-width="180px" @submit.native.prevent>
            <el-form-item prop="serviceLineName" label="Service Line Name">
              <el-input v-model="addServiceLineForm.serviceLineName" size="mini"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button-group>
                <el-button class="hiaButton" plain round size="mini" type="text" @click="toggleAddServiceLineForm()">Cancel</el-button>
                <el-button class="hiaButton" plain round size="mini" @click="addServiceLine">Add</el-button>
              </el-button-group>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-row class="topMargin">
        <el-col :lg="12" :md="18">
          <v-client-table class="hiaTable" v-loading="serviceLinesLoading" element-loading-spinner="atom-audit-loader" ref="serviceLinesGrid" id="serviceLinesGrid" :data="serviceLinesList" :columns="serviceLineColumns" :options="serviceLineOptions">
            <div slot="filter__inactive">
              <input type="checkbox" @change="$refs.serviceLinesGrid.setFilter({ inactive: $event.target.checked })" />
            </div>
            <template slot="selected" slot-scope="props">
              <div class="el-input--mini">
                <input type="checkbox" v-model="props.row.selected" @click="selectRecord(props.row, $event)" />
              </div>
            </template>
            <template slot="serviceLineName" slot-scope="props">
              <div class="el-input--mini">
                <input class="el-input__inner" type="text" v-model="props.row.serviceLineName" @change="updateServiceLine(props.row)" />
              </div>
            </template>
            <template slot="inactive" slot-scope="props">
              <input :key="props.row.serviceLineId" type="checkbox" v-model="props.row.inactive" @change="updateServiceLine(props.row)" />
            </template>
            <template slot="delete" slot-scope="props">
              <div style="text-align: center;">
                <i class="el-icon-close delete-button" @click="deleteServiceLine(props.row)"></i>
              </div>
            </template>
          </v-client-table>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import HiaAuthorization from '@/mixins/hiaAuthorization'
  import hiaTable from '@/mixins/hiaTable'

  export default {
    name: 'serviceLines',
    mixins: [HiaAuthorization],
    props: [],
    data() {
      return {
        loading: false,
        showMergeServiceLine: false,
        mergeServiceLineLoading: false,
        selectedMergeCoder: {},
        localCoder: [],
        serviceLineColumns: ['serviceLineName', 'inactive', 'delete'],
        serviceLineOptions: {
          filterByColumn: true,
          sortable: ['name'],
          orderBy: {
            column: 'serviceLineName',
            ascending: true
          },
          headings: {
            serviceLineName: function(h) {
              return 'Service Line Name'
            },
            delete: function(h) {
              return ''
            },
            selected: h => {
              return h('input', {
                attrs: {
                  type: 'checkbox',
                  id: 'coderSelectAllCheckbox'
                },
                on: {
                  click: e => {
                    this.localselectAll(e.srcElement.checked)
                  }
                },
                ref: 'selectAllCheckbox'
              })
            }
          },
          perPage: 10,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' }
        },
        showAddServiceLineForm: false,
        addServiceLineForm: {
          serviceLineName: null,
          rules: {
            serviceLineName: [{ required: true, message: 'Please Input Service Line Name', trigger: 'none' }]
          }
        }
      }
    },
    methods: {
      ...mapActions('managedLists/serviceLines/', ['GET_SERVICELINESLIST', 'POST_SERVICELINESLIST', 'DELETE_SERVICELINELIST', 'PUT_SERVICELINESLIST']),
      ...mapMutations('managedLists/serviceLines/', ['SET_SELECTED']),
      initialize(route) {
        this.GET_SERVICELINESLIST()
        // this.loading = true
        // this.GET_CODERS().then(response => {
        //   this.localCoder = JSON.parse(JSON.stringify(this.coderList))
        //   this.loading = false
        // })
      },
      localselectAll(checked) {
        let filteredRecords = []

        if (this.$refs.coderList) {
          filteredRecords = this.$refs.coderList.allFilteredData
        }

        const recs = this.coderList.filter(x => filteredRecords.some(y => y.coderId === x.coderId))
        hiaTable.selectAll(recs, checked)
        this.SET_SELECTED(recs)
      },
      selectRecord(row, e) {
        const checked = e.srcElement.checked
        row.selected = checked
        this.SET_SELECTED([row])
      },
      addServiceLine() {
        this.$refs.addServiceLineForm.validate(async valid => {
          if (valid) {
            try {
              const payload = {
                clientServiceLine: {
                  serviceLineName: this.addServiceLineForm.serviceLineName
                }
              }
              await this.POST_SERVICELINESLIST(payload)
              this.$refs.addServiceLineForm.resetFields()
              this.$message({
                message: 'Service Line Added Successful',
                type: 'success'
              })
            } catch (err) {
              this.$message({
                message: 'Service Line Added Error',
                type: 'error'
              })
            }
          }
        })
      },
      async updateServiceLine(serviceLine) {
        const index = this.serviceLinesList.findIndex(x => x.clientServiceLineId === serviceLine.clientServiceLineId)
        this.$set(this.serviceLinesList, index, serviceLine)
        try {
          await this.PUT_SERVICELINESLIST(serviceLine)
          this.$message({
            message: 'Service Line Updated Successfully',
            type: 'success'
          })
        } catch (err) {
          this.$message({
            message: 'Service Line Updated Error',
            type: 'error'
          })
        }
      },
      async deleteServiceLine(serviceLine) {
        await this.$confirm(`Permanently delete Service Line ${serviceLine.serviceLineName}?`, 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        })

        try {
          await this.DELETE_SERVICELINELIST(serviceLine)
          this.$message({
            message: 'Service Line Deleted Successfully',
            type: 'success'
          })
        } catch (err) {
          this.$message({
            message: `Error Deleting Service Lines: ${err.message}`,
            type: 'error'
          })
        }
      },
      toggleAddServiceLineForm() {
        this.showAddServiceLineForm = !this.showAddServiceLineForm
      },
      toggleMergeCoder() {
        this.selectedMergeCoder = {}
        this.showMergeServiceLine = !this.showMergeServiceLine
      },
      doMergeCoder() {
        this.$confirm(`Update all existing Facility Review Records for listed Coder Staff to ${this.selectedMergeCoder.name}?`, 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          this.mergeServiceLineLoading = true
          const payload = {
            targetCoderId: this.selectedMergeCoder.coderId,
            coder: this.coderList.filter(x => x.selected && x.coderId !== this.selectedMergeCoder.coderId)
          }
          this.PUT_MERGE_CODER(payload).then(
            response => {
              this.mergeServiceLineLoading = false
              this.toggleMergeCoder()
              document.getElementById('coderSelectAllCheckbox').checked = false
              this.localselectAll(false)
            },
            error => {
              console.log(error)
              this.mergeServiceLineLoading = false
            }
          )
        })
      }
    },
    mounted: function() {
      this.initialize()
    },
    activated: function() {
      this.initialize()
    },
    computed: {
      ...mapState('managedLists/serviceLines/', ['serviceLinesList', 'serviceLinesLoading']),
      disableMergeButton() {
        return this.serviceLinesList.filter(x => x.selected).length < 2
      }
    },
    watch: {},
    created: function() {}
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .coderOption {
    cursor: pointer;
    padding: 0px 20px 0px 20px;
    color: #337ab7;
  }

  .coderOption:hover {
    background: rgba(255, 196, 12, 0.2);
    border-radius: 2px;
  }

  .selected {
    background: rgba(255, 196, 12, 0.2);
  }

  .topMargin {
    margin: 10px 0px 0px 0px;
  }
</style>
