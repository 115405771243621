<template>
  <div>
    <el-tabs v-model="reasonForChangeTab">
      <el-tab-pane label="Inpatient Reason for Change List" name="ip">
        <el-row>
          <el-col :lg="24" :md="24">
            <el-col :span="18" style="padding: 0px;">
              <el-form
                :model="reasonForChangeForm"
                size="mini"
                ref="reasonForChangeForm"
                :rules="reasonForChangeForm.rules"
                label-position="left"
                label-width="180px"
              >
                <template v-if="!showReasonForChangeForm">
                  <el-button
                    class="hiaButton"
                    style="margin-bottom: 10px;"
                    plain
                    round
                    size="mini"
                    @click="showReasonForChangeForm = true"
                    icon="el-icon-plus"
                    >Add Inpatient Reason for Change
                  </el-button>
                </template>
                <el-form-item
                  v-if="showReasonForChangeForm"
                  prop="description"
                  label="Description"
                >
                  <el-input
                    size="mini"
                    type="text"
                    v-model="reasonForChangeForm.description"
                    id="description"
                  ></el-input>
                </el-form-item>
                <el-form-item v-if="showReasonForChangeForm">
                  <el-button-group>
                    <el-button
                      class="hiaButton"
                      plain
                      round
                      size="mini"
                      type="text"
                      @click="showReasonForChangeForm = false"
                      >Cancel</el-button
                    >
                    <el-button
                      class="hiaButton"
                      plain
                      round
                      size="mini"
                      @click="postReasonForChangeIp()"
                      >Add</el-button
                    >
                  </el-button-group>
                </el-form-item>
              </el-form>
            </el-col>

            <v-client-table
              class="hiaTable"
              v-if="toggleTable"
              v-loading="reasonForChangesLoading"
              element-loading-spinner="atom-audit-loader"
              ref="reasonForChangeGrid"
              id="reasonForChangeGrid"
              :data="reasonForChanges.filter(x => x.reviewType === 'IP')"
              :columns="ipReasonForChangeColumns"
              :options="reasonForChangeOptions"
            >
              <div slot="filter__inactive">
                <input
                  type="checkbox"
                  @change="
                    $refs.reasonForChangeGrid.setFilter({
                      inactive: $event.target.checked
                    })
                  "
                />
              </div>
              <template slot="reviewType">
                Inpatient
              </template>
              <template slot="description" slot-scope="props">
                <div class="el-input--mini">
                  <input
                    class="el-input__inner"
                    type="text"
                    v-model="props.row.description"
                    @change="putReasonForChange(props.row)"
                  />
                </div>
              </template>
              <template slot="header" slot-scope="props">
                <input
                  :key="props.row.reasonForChangeId"
                  type="checkbox"
                  v-model="props.row.header"
                  @change="putReasonForChange(props.row)"
                />
              </template>
              <template slot="dx" slot-scope="props">
                <input
                  :key="props.row.reasonForChangeId"
                  type="checkbox"
                  v-model="props.row.dx"
                  @change="putReasonForChange(props.row)"
                />
              </template>
              <template slot="px" slot-scope="props">
                <input
                  :key="props.row.reasonForChangeId"
                  type="checkbox"
                  v-model="props.row.px"
                  @change="putReasonForChange(props.row)"
                />
              </template>
              <template slot="findingOnly" slot-scope="props">
                <input
                  :key="props.row.reasonForChangeId"
                  type="checkbox"
                  v-model="props.row.findingOnly"
                  @change="putReasonForChange(props.row)"
                />
              </template>
              <template slot="inactive" slot-scope="props">
                <input
                  :key="props.row.reasonForChangeId"
                  type="checkbox"
                  v-model="props.row.inactive"
                  @change="putReasonForChange(props.row)"
                />
              </template>
              <template slot="delete" slot-scope="props">
                <div style="text-align: center;">
                  <i
                    class="el-icon-close delete-button"
                    @click="deleteReasonForChange(props.row)"
                  ></i>
                </div>
              </template>
            </v-client-table>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="Outpatient Reason for Change List" name="op">
        <el-row>
          <el-col :lg="24" :md="24">
            <el-col :span="18" style="padding: 0px;">
              <el-form
                :model="reasonForChangeFormOp"
                size="mini"
                ref="reasonForChangeFormOp"
                :rules="reasonForChangeFormOp.rules"
                label-position="left"
                label-width="180px"
              >
                <template v-if="!showOpReasonForChangeForm">
                  <el-button
                    class="hiaButton"
                    style="margin-bottom: 10px;"
                    plain
                    round
                    size="mini"
                    @click="showOpReasonForChangeForm = true"
                    icon="el-icon-plus"
                    >Add Outpatient Reason for Change
                  </el-button>
                </template>
                <el-form-item
                  v-if="showOpReasonForChangeForm"
                  prop="description"
                  label="Description"
                >
                  <el-input
                    size="mini"
                    type="text"
                    v-model="reasonForChangeFormOp.description"
                    id="description"
                  ></el-input>
                </el-form-item>
                <el-form-item v-if="showOpReasonForChangeForm">
                  <el-button-group>
                    <el-button
                      class="hiaButton"
                      plain
                      round
                      size="mini"
                      type="text"
                      @click="showOpReasonForChangeForm = false"
                      >Cancel</el-button
                    >
                    <el-button
                      class="hiaButton"
                      plain
                      round
                      size="mini"
                      @click="postReasonForChangeOp()"
                      >Add</el-button
                    >
                  </el-button-group>
                </el-form-item>
              </el-form>
            </el-col>

            <v-client-table
              class="hiaTable"
              v-if="toggleTable"
              v-loading="reasonForChangesLoading"
              element-loading-spinner="atom-audit-loader"
              ref="reasonForChangeGridOp"
              id="reasonForChangeGridOp"
              :data="reasonForChanges.filter(x => x.reviewType === 'OP')"
              :columns="opReasonForChangeColumns"
              :options="reasonForChangeOptions"
            >
              <div slot="filter__inactive">
                <input
                  type="checkbox"
                  @change="
                    $refs.reasonForChangeGridOp.setFilter({
                      inactive: $event.target.checked
                    })
                  "
                />
              </div>
              <template slot="reviewType">
                Outpatient
              </template>
              <template slot="description" slot-scope="props">
                <div class="el-input--mini">
                  <input
                    class="el-input__inner"
                    type="text"
                    v-model="props.row.description"
                    @change="putReasonForChange(props.row)"
                  />
                </div>
              </template>
              <template slot="header" slot-scope="props">
                <input
                  :key="props.row.reasonForChangeId"
                  type="checkbox"
                  v-model="props.row.header"
                  @change="putReasonForChange(props.row)"
                />
              </template>
              <template slot="dx" slot-scope="props">
                <input
                  :key="props.row.reasonForChangeId"
                  type="checkbox"
                  v-model="props.row.dx"
                  @change="putReasonForChange(props.row)"
                />
              </template>
              <template slot="px" slot-scope="props">
                <input
                  :key="props.row.reasonForChangeId"
                  type="checkbox"
                  v-model="props.row.px"
                  @change="putReasonForChange(props.row)"
                />
              </template>
              <template slot="cpt" slot-scope="props">
                <input
                  :key="props.row.reasonForChangeId"
                  type="checkbox"
                  v-model="props.row.cpt"
                  @change="putReasonForChange(props.row)"
                />
              </template>
              <template slot="apc" slot-scope="props">
                <input
                  :key="props.row.reasonForChangeId"
                  type="checkbox"
                  v-model="props.row.apc"
                  @change="putReasonForChange(props.row)"
                />
              </template>
              <template slot="mod" slot-scope="props">
                <input
                  :key="props.row.reasonForChangeId"
                  type="checkbox"
                  v-model="props.row.mod"
                  @change="putReasonForChange(props.row)"
                />
              </template>
              <template slot="findingOnly" slot-scope="props">
                <input
                  :key="props.row.reasonForChangeId"
                  type="checkbox"
                  v-model="props.row.findingOnly"
                  @change="putReasonForChange(props.row)"
                />
              </template>
              <template slot="inactive" slot-scope="props">
                <input
                  :key="props.row.reasonForChangeId"
                  type="checkbox"
                  v-model="props.row.inactive"
                  @change="putReasonForChange(props.row)"
                />
              </template>
              <template slot="delete" slot-scope="props">
                <div style="text-align: center;">
                  <i
                    class="el-icon-close delete-button"
                    @click="deleteReasonForChange(props.row)"
                  ></i>
                </div>
              </template>
            </v-client-table>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="Pro Reason for Change List" name="pro">
        <el-row>
          <el-col :lg="24" :md="24">
            <el-col :span="18" style="padding: 0px;">
              <el-form
                :model="reasonForChangeFormPro"
                size="mini"
                ref="reasonForChangeFormPro"
                :rules="reasonForChangeFormPro.rules"
                label-position="left"
                label-width="180px"
              >
                <template v-if="!showProReasonForChangeForm">
                  <el-button
                    class="hiaButton"
                    style="margin-bottom: 10px;"
                    plain
                    round
                    size="mini"
                    @click="showProReasonForChangeForm = true"
                    icon="el-icon-plus"
                    >Add Pro Reason for Change
                  </el-button>
                </template>
                <el-form-item
                  v-if="showProReasonForChangeForm"
                  prop="description"
                  label="Description"
                >
                  <el-input
                    size="mini"
                    type="text"
                    v-model="reasonForChangeFormPro.description"
                    id="description"
                  ></el-input>
                </el-form-item>
                <el-form-item v-if="showProReasonForChangeForm">
                  <el-button-group>
                    <el-button
                      class="hiaButton"
                      plain
                      round
                      size="mini"
                      type="text"
                      @click="showProReasonForChangeForm = false"
                      >Cancel</el-button
                    >
                    <el-button
                      class="hiaButton"
                      plain
                      round
                      size="mini"
                      @click="postReasonForChangePro()"
                      >Add</el-button
                    >
                  </el-button-group>
                </el-form-item>
              </el-form>
            </el-col>

            <v-client-table
              class="hiaTable"
              v-if="toggleTable"
              v-loading="reasonForChangesLoading"
              element-loading-spinner="atom-audit-loader"
              ref="reasonForChangeGridPro"
              id="reasonForChangeGridPro"
              :data="reasonForChanges.filter(x => x.reviewType === 'PRO')"
              :columns="proReasonForChangeColumns"
              :options="reasonForChangeOptions"
            >
              <div slot="filter__inactive">
                <input
                  type="checkbox"
                  @change="
                    $refs.reasonForChangeGridPro.setFilter({
                      inactive: $event.target.checked
                    })
                  "
                />
              </div>
              <template slot="reviewType">
                Professional Fee
              </template>
              <template slot="description" slot-scope="props">
                <div class="el-input--mini">
                  <input
                    class="el-input__inner"
                    type="text"
                    v-model="props.row.description"
                    @change="putReasonForChange(props.row)"
                  />
                </div>
              </template>
              <template slot="header" slot-scope="props">
                <input
                  :key="props.row.reasonForChangeId"
                  type="checkbox"
                  v-model="props.row.header"
                  @change="putReasonForChange(props.row)"
                />
              </template>
              <template slot="dx" slot-scope="props">
                <input
                  :key="props.row.reasonForChangeId"
                  type="checkbox"
                  v-model="props.row.dx"
                  @change="putReasonForChange(props.row)"
                />
              </template>
              <template slot="cpt" slot-scope="props">
                <input
                  :key="props.row.reasonForChangeId"
                  type="checkbox"
                  v-model="props.row.cpt"
                  @change="putReasonForChange(props.row)"
                />
              </template>
              <template slot="mod" slot-scope="props">
                <input
                  :key="props.row.reasonForChangeId"
                  type="checkbox"
                  v-model="props.row.mod"
                  @change="putReasonForChange(props.row)"
                />
              </template>
              <template slot="findingOnly" slot-scope="props">
                <input
                  :key="props.row.reasonForChangeId"
                  type="checkbox"
                  v-model="props.row.findingOnly"
                  @change="putReasonForChange(props.row)"
                />
              </template>
              <template slot="inactive" slot-scope="props">
                <input
                  :key="props.row.reasonForChangeId"
                  type="checkbox"
                  v-model="props.row.inactive"
                  @change="putReasonForChange(props.row)"
                />
              </template>
              <template slot="delete" slot-scope="props">
                <div style="text-align: center;">
                  <i
                    class="el-icon-close delete-button"
                    @click="deleteReasonForChange(props.row)"
                  ></i>
                </div>
              </template>
            </v-client-table>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import HiaAuthorization from '@/mixins/hiaAuthorization'

export default {
  name: 'reasonForChange',
  mixins: [HiaAuthorization],
  data() {
    return {
      reasonForChangeTab: 'ip',
      toggleTable: true,
      showReasonForChangeForm: false,
      showOpReasonForChangeForm: false,
      showProReasonForChangeForm: false,
      reasonForChangeForm: {
        reviewType: null,
        reasonNumber: null,
        description: '',
        rules: {
          reviewType: [{ required: true, trigger: 'none' }],
          description: [{ required: true, trigger: 'none' }]
        }
      },
      reasonForChangeFormOp: {
        reviewType: null,
        reasonNumber: null,
        description: '',
        rules: {
          reviewType: [{ required: true, trigger: 'none' }],
          description: [{ required: true, trigger: 'none' }]
        }
      },
      reasonForChangeFormPro: {
        reviewType: null,
        reasonNumber: null,
        description: '',
        rules: {
          reviewType: [{ required: true, trigger: 'none' }],
          description: [{ required: true, trigger: 'none' }]
        }
      },
      ipReasonForChangeColumns: [
        'reviewType',
        'description',
        'header',
        'dx',
        'px',
        'findingOnly',
        'inactive',
        'delete'
      ],
      opReasonForChangeColumns: [
        'reviewType',
        'description',
        'header',
        'dx',
        'px',
        'cpt',
        'apc',
        'mod',
        'findingOnly',
        'inactive',
        'delete'
      ],
      proReasonForChangeColumns: [
        'reviewType',
        'description',
        'header',
        'dx',
        'cpt',
        'mod',
        'findingOnly',
        'inactive',
        'delete'
      ],
      reasonForChangeOptions: {
        filterByColumn: true,
        // orderBy: {
        //   column: 'description',
        //   ascending: true
        // },
        rowClassCallback: row => {
          if (row.isNew) {
            return 'isNew'
          }
          return ''
        },
        headings: {
          header: 'Header',
          dx: 'Dx',
          px: 'Px',
          cpt: 'CPT',
          apc: 'APC',
          mod: 'MOD',
          findingOnly: 'Finding Only',
          reviewType: 'Review Type',
          description: 'Description',
          delete: ''
        },
        perPage: 10,
        texts: {
          filter: '',
          filterBy: '...'
        },
        highlightMatches: true,
        sortIcon: { is: 'none' }
      }
    }
  },
  methods: {
    ...mapActions('managedLists/reasonForChanges/', [
      'GET_REASONFORCHANGES',
      'PUT_REASONFORCHANGES',
      'POST_REASONFORCHANGES',
      'DELETE_REASONFORCHANGES'
    ]),
    ...mapMutations('dropdowns/', ['REMOVE_CACHED_DROPDOWN']),
    initialize() {
      this.GET_REASONFORCHANGES()
    },
    postReasonForChangeIp() {
      this.$refs.reasonForChangeForm.validate(async valid => {
        if (valid) {
          try {
            const payload = {
              reasonForChange: {
                reviewType: 'IP',
                description: this.reasonForChangeForm.description
              }
            }
            await this.POST_REASONFORCHANGES(payload)

            this.toggleTable = false
            this.$nextTick(() => {
              this.toggleTable = true
            })

            this.uncacheInpatientDropdowns()

            this.clearForm()
            this.$message({
              message: 'Reason for Change Added Successful',
              type: 'success'
            })
          } catch (err) {
            this.$message({
              message: 'Reason for Change Added Error',
              type: 'error'
            })
          }
        }
      })
    },
    postReasonForChangeOp() {
      this.$refs.reasonForChangeFormOp.validate(async valid => {
        if (valid) {
          try {
            const payload = {
              reasonForChange: {
                reviewType: 'OP',
                description: this.reasonForChangeFormOp.description
              }
            }
            await this.POST_REASONFORCHANGES(payload)

            this.toggleTable = false
            this.$nextTick(() => {
              this.toggleTable = true
            })

            this.uncacheOutpatientDropdowns()

            this.clearForm()
            this.$message({
              message: 'Reason for Change Added Successful',
              type: 'success'
            })
          } catch (err) {
            this.$message({
              message: 'Reason for Change Added Error',
              type: 'error'
            })
          }
        }
      })
    },
    postReasonForChangePro() {
      this.$refs.reasonForChangeFormPro.validate(async valid => {
        if (valid) {
          try {
            const payload = {
              reasonForChange: {
                reviewType: 'PRO',
                description: this.reasonForChangeFormPro.description
              }
            }
            await this.POST_REASONFORCHANGES(payload)

            this.toggleTable = false
            this.$nextTick(() => {
              this.toggleTable = true
            })

            this.uncacheProDropdowns()

            this.clearForm()
            this.$message({
              message: 'Reason for Change Added Successful',
              type: 'success'
            })
          } catch (err) {
            this.$message({
              message: 'Reason for Change Added Error',
              type: 'error'
            })
          }
        }
      })
    },
    async putReasonForChange(row) {
      const index = this.reasonForChanges.findIndex(
        x => x.reasonForChangeId === row.reasonForChangeId
      )
      this.$set(this.reasonForChanges, index, row)

      try {
        await this.PUT_REASONFORCHANGES(row)
        this.uncacheInpatientDropdowns()
        this.uncacheOutpatientDropdowns()
        this.uncacheProDropdowns()

        this.$message({
          message: 'Reason for Change Updated Successfully',
          type: 'success'
        })
      } catch (err) {
        this.$message({
          message: 'Reason for Change Updated Error',
          type: 'error'
        })
      }
    },
    async deleteReasonForChange(row) {
      await this.$confirm(
        `Permanently delete Reason for Change ${row.description}?`,
        'Warning',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }
      )

      try {
        await this.DELETE_REASONFORCHANGES(row.reasonForChangeId)
        this.uncacheInpatientDropdowns()
        this.uncacheOutpatientDropdowns()
        this.uncacheProDropdowns()

        this.$message({
          message: 'Reason for Change Deleted',
          type: 'success'
        })
      } catch (err) {
        this.providersLoading = false
        this.$message({
          message: `Error Deleting Reason for Change: ${err.message}`,
          type: 'error'
        })
      }
    },
    uncacheInpatientDropdowns() {
      this.REMOVE_CACHED_DROPDOWN('inpatientReasonsForChangeHeader')
      this.REMOVE_CACHED_DROPDOWN('inpatientReasonsForChangeDx')
      this.REMOVE_CACHED_DROPDOWN('inpatientReasonsForChangePx')
      this.REMOVE_CACHED_DROPDOWN('inpatientReasonsForChange')
      this.REMOVE_CACHED_DROPDOWN('allReasonsForChange')
    },
    uncacheOutpatientDropdowns() {
      this.REMOVE_CACHED_DROPDOWN('outpatientReasonsForChangeHeader')
      this.REMOVE_CACHED_DROPDOWN('outpatientReasonsForChangeDx')
      this.REMOVE_CACHED_DROPDOWN('outpatientReasonsForChangePx')
      this.REMOVE_CACHED_DROPDOWN('outpatientReasonsForChangeCpt')
      this.REMOVE_CACHED_DROPDOWN('outpatientReasonsForChangeApc')
      this.REMOVE_CACHED_DROPDOWN('outpatientReasonsForChangeMod')
      this.REMOVE_CACHED_DROPDOWN('outpatientReasonsForChange')
      this.REMOVE_CACHED_DROPDOWN('allReasonsForChange')
    },
    uncacheProDropdowns() {
      this.REMOVE_CACHED_DROPDOWN('proReasonsForChangeHeader')
      this.REMOVE_CACHED_DROPDOWN('proReasonsForChangeDx')
      this.REMOVE_CACHED_DROPDOWN('proReasonsForChangeCpt')
      this.REMOVE_CACHED_DROPDOWN('proReasonsForChangeMod')
      this.REMOVE_CACHED_DROPDOWN('proReasonsForChange')
      this.REMOVE_CACHED_DROPDOWN('allReasonsForChange')
    },
    clearForm() {
      this.reasonForChangeForm.reviewType = null
      this.reasonForChangeForm.description = ''

      this.reasonForChangeFormOp.reviewType = null
      this.reasonForChangeFormOp.description = ''

      this.reasonForChangeFormPro.reviewType = null
      this.reasonForChangeFormPro.description = ''

      this.showReasonForChangeForm = false
      this.showOpReasonForChangeForm = false
      this.showProReasonForChangeForm = false
    }
  },
  mounted: function() {
    this.initialize()
  },
  activated: function() {
    this.initialize()
  },
  computed: {
    ...mapState('managedLists/reasonForChanges/', [
      'reasonForChanges',
      'reasonForChangesLoading'
    ])
  }
}
</script>

<style scoped>
/* >>>.isNew {
    background-color: blue !important;
    transition: background-color 0.5s ease !important;
  } */

@-webkit-keyframes isNew {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-moz-keyframes isNew {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes isNew {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

>>> .isNew {
  -webkit-animation: isNew 0.3s ease-in-out 0s;
  -moz-animation: isNew 0.3s ease-in-out 0s;
  -o-animation: isNew 0.3s ease-in-out 0s;
  animation: isNew 0.3s ease-in-out 0s;
  background-color: rgba(255, 196, 12, 0.2) !important;
}
</style>
