<template>
  <div>
    <el-row class="header-row">
      <h3 class="el-page-header">Tags</h3>
    </el-row>
    <el-row :gutter="10">
      <el-col :lg="8" :md="12">
        <el-form :model="projectIdentifiersForm" size="mini" ref="projectIdentifiersForm" :rules="projectIdentifiersForm.rules" label-position="left" label-width="180px">
          <template v-if="!showprojectIdentifiersForm">
            <el-button class="hiaButton" plain round size="mini" @click="toggleprojectIdentifiersForm()" icon="el-icon-plus">Add New Tag </el-button>
          </template>
          <el-form-item v-if="showprojectIdentifiersForm" prop="identifier" label="Tag">
            <el-input size="mini" maxlength="200" type="text" v-model="projectIdentifiersForm.identifier" id="identifier"></el-input>
          </el-form-item>
          <el-form-item v-if="showprojectIdentifiersForm">
            <el-button-group>
              <el-button class="hiaButton" plain round size="mini" type="text" @click="toggleprojectIdentifiersForm()">Cancel</el-button>
              <el-button class="hiaButton" plain round size="mini" @click="postProjectIdentifier()">Add</el-button>
            </el-button-group>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row :gutter="10" style="margin: 10px 0px 0px 0px;">
      <el-col :lg="12" :md="18">
        <v-client-table class="hiaTable" v-loading="projectIdentifiersLoading" element-loading-spinner="atom-audit-loader" ref="projectIdentifiersGrid" id="projectIdentifiersGrid" :data="projectIdentifiersList" :columns="projectIdentifierColumns" :options="projectIdentifierOptions">
          <div slot="filter__inactive">
            <input type="checkbox" @change="$refs.projectIdentifiersGrid.setFilter({ inactive: $event.target.checked })" />
          </div>

          <!-- <div slot="filter__projectLevel">
            <input type="checkbox" @change="$refs.projectIdentifiersGrid.setFilter({projectLevel: $event.target.checked})">
          </div> -->

          <template slot="identifier" slot-scope="props">
            <div :key="props.row.identifierId" class="el-input--mini">
              <input maxlength="200" class="el-input__inner" type="text" v-model="props.row.identifier" @change="validateRow(props.row)" />
              <div v-if="props.row.validationFields.length && props.row.validationFields.find(f => f.field === 'identifier').error" class="el-form-item__error">{{ props.row.validationFields.length ? props.row.validationFields.find(f => f.field === 'identifier').message : '' }}</div>
            </div>
          </template>

          <template slot="projectLevel" slot-scope="props">
            <input :key="props.row.identifierId" type="checkbox" v-model="props.row.projectLevel" @change="putProjectIdentifier(props.row)" />
          </template>
          <template slot="recordLevel" slot-scope="props">
            <input :key="props.row.identifierId" type="checkbox" v-model="props.row.recordLevel" @change="putProjectIdentifier(props.row)" />
          </template>
          <template slot="inactive" slot-scope="props">
            <input :key="props.row.identifierId" type="checkbox" v-model="props.row.inactive" @change="putProjectIdentifier(props.row)" />
          </template>
          <template slot="delete" slot-scope="props">
            <div style="text-align: center;">
              <i class="el-icon-close delete-button" title="Delete Tag" @click="deleteProjectIdentifier(props.row)"></i>
            </div>
          </template>
        </v-client-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import HiaAuthorization from '@/mixins/hiaAuthorization'
  import Schema from 'async-validator'
  // import { mapFields } from 'vuex-map-fields'

  export default {
    name: 'projectIdentifiers',
    mixins: [HiaAuthorization],
    data() {
      return {
        showprojectIdentifiersForm: false,
        projectIdentifiersForm: {
          identifier: '',
          rules: {
            identifier: [
              { type: 'string', required: true, trigger: 'change', message: 'Tag is required' },
              { type: 'string', required: true, trigger: 'change', pattern: /^[^,]*$/, message: 'Tag may not contain any commas ","' }
            ]
          }
        },
        projectIdentifierColumns: ['identifier', 'projectLevel', 'recordLevel', 'inactive', 'delete'],
        projectIdentifierOptions: {
          filterByColumn: true,
          rowClassCallback: row => {
            if (row.isNew) {
              return 'isNew'
            }
            return ''
          },
          // orderBy: {
          //   column: 'identifier',
          //   ascending: true
          // },
          headings: {
            identifier: 'Tag',
            projectLevel: 'Projects',
            recordLevel: 'Records',
            inactive: 'Inactive',
            delete: ''
          },
          perPage: 10,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' }
        }
      }
    },
    methods: {
      ...mapActions('managedLists/projectIdentifiers/', ['GET_PROJECTIDENTIFIERS', 'PUT_PROJECTIDENTIFIERS', 'POST_PROJECTIDENTIFIERS', 'DELETE_PROJECTIDENTIFIERS']),
      ...mapMutations('dropdowns/', ['REMOVE_CACHED_DROPDOWN']),
      initialize() {
        this.GET_PROJECTIDENTIFIERS()
      },
      toggleprojectIdentifiersForm() {
        this.showprojectIdentifiersForm = !this.showprojectIdentifiersForm
      },
      postProjectIdentifier() {
        this.$refs.projectIdentifiersForm.validate(async valid => {
          if (valid) {
            try {
              const payload = {
                projectIdentifier: {
                  identifier: this.projectIdentifiersForm.identifier,
                  global: true
                }
              }
              await this.POST_PROJECTIDENTIFIERS(payload)
              this.REMOVE_CACHED_DROPDOWN('projectIdentifiers')
              this.REMOVE_CACHED_DROPDOWN('recordTags')
              this.clearForm()
              this.$message({
                message: 'Tag Added Successfully',
                type: 'success'
              })
            } catch (err) {
              this.$message({
                message: 'Error Adding Tag',
                type: 'error'
              })
            }
          }
        })
      },
      async putProjectIdentifier(row) {
        const index = this.projectIdentifiersList.findIndex(x => x.identifierId === row.identifierId)
        this.$set(this.projectIdentifiersList, index, row)

        try {
          await this.PUT_PROJECTIDENTIFIERS(row)
          this.REMOVE_CACHED_DROPDOWN('projectIdentifiers')
          this.REMOVE_CACHED_DROPDOWN('recordTags')
          this.$message({
            message: 'Tag Updated Successfully',
            type: 'success'
          })
        } catch (err) {
          this.$message({
            message: 'Error Updating Tag',
            type: 'error'
          })
        }
      },
      async deleteProjectIdentifier(row) {
        await this.$confirm(`Permanently delete Tag ${row.identifier}?`, 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        })

        try {
          await this.DELETE_PROJECTIDENTIFIERS(row.identifierId)
          this.REMOVE_CACHED_DROPDOWN('projectIdentifiers')
          this.REMOVE_CACHED_DROPDOWN('recordTags')
          this.$message({
            message: 'Tag Delete Successful',
            type: 'success'
          })
        } catch (err) {
          this.$message({
            message: `Error Deleting Tag: ${err.message}`,
            type: 'error'
          })
        }
      },
      clearForm() {
        this.projectIdentifiersForm = {
          identifier: ''
        }
      },
      validateRow(row) {
        const rules = {
          identifier: [
            { type: 'string', required: true, trigger: 'change', message: 'Tag is required' },
            { type: 'string', required: true, trigger: 'change', pattern: /^[^,]*$/, message: 'Tag may not contain any commas ","' }
          ]
        }
        const validator = new Schema(rules)
        validator.validate(row, async (errors, fields) => {
          if (errors) {
            const validationFields = []
            const fieldKeys = Object.keys(fields)
            const index = this.projectIdentifiersList.findIndex(x => x.identifierId === row.identifierId)
            // loop over field keys and build validationFields array
            fieldKeys.forEach((e, i) => {
              validationFields.push({
                field: fields[e][0].field,
                error: true,
                message: fields[e][0].message
              })
            })
            row.validationFields = validationFields
            this.$set(this.projectIdentifiersList, index, row)
            return
          }
          row.validationFields = []
          await this.putProjectIdentifier(row)
        })
      }
    },
    mounted: function() {
      this.initialize()
    },
    activated: function() {
      this.initialize()
    },
    computed: {
      ...mapState('managedLists/projectIdentifiers/', ['projectIdentifiersList', 'projectIdentifiersLoading'])
    }
  }
</script>

<style scoped>
  @-webkit-keyframes isNew {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @-moz-keyframes isNew {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes isNew {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  >>> .isNew {
    -webkit-animation: isNew 0.3s ease-in-out 0s;
    -moz-animation: isNew 0.3s ease-in-out 0s;
    -o-animation: isNew 0.3s ease-in-out 0s;
    animation: isNew 0.3s ease-in-out 0s;
    background-color: rgba(255, 196, 12, 0.2) !important;
  }
</style>
