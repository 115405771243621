<template>
  <div>
    <el-tabs v-model="requiredFieldsTab">
      <el-tab-pane label="Inpatient Validations" name="ip">
        <el-row>
          <p style="font-size: 14px;">The selected validations will be required for these statuses:</p>
          <ul style="list-style-type: none;padding-inline-start:10px;margin:0px 0px 20px 0px;font-size: 14px;">
            <li><i class="mdi mdi-account" style="margin-right:4px;"></i>Ready for Coder</li>
            <li><i class="mdi mdi-check-bold" style="margin-right:4px;"></i>Completed</li>
          </ul>
          <el-col :lg="12" :md="12">
            <v-client-table class="hiaTable" v-loading="requiredFieldsLoading" element-loading-spinner="atom-audit-loader" ref="ipRequiredFieldsGrid" id="ipRequiredFieldsGrid" :data="ipRequiredFieldsGridData" :columns="requiredFieldsColumns" :options="requiredFieldsOptions">
              <div slot="filter__required">
                <input type="checkbox" @change="$refs.ipRequiredFieldsGrid.setFilter({ required: $event.target.checked })" />
              </div>
              <template slot="group" slot-scope="props">
                {{ props.row.group }}
              </template>
              <template slot="fieldLabel" slot-scope="props">
                {{ props.row.fieldLabel }}
              </template>
              <template slot="message" slot-scope="props">
                <div class="el-input--mini">
                  <input class="el-input__inner" type="text" v-model="props.row.message" @change="putRequiredFields(props.row)" />
                </div>
              </template>
              <template slot="required" slot-scope="props">
                <input :key="props.row.required" type="checkbox" v-model="props.row.required" @change="putRequiredFields(props.row)" />
              </template>
            </v-client-table>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="Outpatient Validations" name="op">
        <el-row>
          <p style="font-size: 14px;">The selected validations will be required for these statuses:</p>
          <ul style="list-style-type: none;padding-inline-start:10px;margin:0px 0px 20px 0px;font-size: 14px;">
            <li><i class="mdi mdi-account" style="margin-right:4px;"></i>Ready for Coder</li>
            <li><i class="mdi mdi-check-bold" style="margin-right:4px;"></i>Completed</li>
          </ul>
          <el-col :lg="12" :md="12">
            <v-client-table class="hiaTable" v-loading="requiredFieldsLoading" element-loading-spinner="atom-audit-loader" ref="opRequiredFieldsGrid" id="opRequiredFieldsGrid" :data="opRequiredFieldsGridData" :columns="requiredFieldsColumns" :options="requiredFieldsOptions">
              <div slot="filter__required">
                <input type="checkbox" @change="$refs.opRequiredFieldsGrid.setFilter({ required: $event.target.checked })" />
              </div>
              <template slot="group" slot-scope="props">
                {{ props.row.group }}
              </template>
              <template slot="fieldLabel" slot-scope="props">
                {{ props.row.fieldLabel }}
              </template>
              <template slot="message" slot-scope="props">
                <div class="el-input--mini">
                  <input class="el-input__inner" type="text" v-model="props.row.message" @change="putRequiredFields(props.row)" />
                </div>
              </template>
              <template slot="required" slot-scope="props">
                <input :key="props.row.required" type="checkbox" v-model="props.row.required" @change="putRequiredFields(props.row)" />
              </template>
            </v-client-table>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="Professional Fee Validations" name="pro">
        <el-row>
          <p style="font-size: 14px;">The selected validations will be required for these statuses:</p>
          <ul style="list-style-type: none;padding-inline-start:10px;margin:0px 0px 20px 0px;font-size: 14px;">
            <li><i class="mdi mdi-account" style="margin-right:4px;"></i>Ready for Coder</li>
            <li><i class="mdi mdi-check-bold" style="margin-right:4px;"></i>Completed</li>
          </ul>
          <el-col :lg="12" :md="12">
            <v-client-table class="hiaTable" v-loading="requiredFieldsLoading" element-loading-spinner="atom-audit-loader" ref="proRequiredFieldsGrid" id="proRequiredFieldsGrid" :data="proRequiredFieldsGridData" :columns="requiredFieldsColumns" :options="requiredFieldsOptions">
              <div slot="filter__required">
                <input type="checkbox" @change="$refs.proRequiredFieldsGrid.setFilter({ required: $event.target.checked })" />
              </div>
              <template slot="group" slot-scope="props">
                {{ props.row.group }}
              </template>
              <template slot="fieldLabel" slot-scope="props">
                {{ props.row.fieldLabel }}
              </template>
              <template slot="message" slot-scope="props">
                <div class="el-input--mini">
                  <input class="el-input__inner" type="text" v-model="props.row.message" @change="putRequiredFields(props.row)" />
                </div>
              </template>
              <template slot="required" slot-scope="props">
                <input :key="props.row.required" type="checkbox" v-model="props.row.required" @change="putRequiredFields(props.row)" />
              </template>
            </v-client-table>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import HiaAuthorization from '@/mixins/hiaAuthorization'

  export default {
    name: 'requiredFields',
    mixins: [HiaAuthorization],
    data() {
      return {
        requiredFieldsTab: 'ip',
        toggleTable: true,
        requiredFieldsColumns: ['group', 'fieldLabel', 'message', 'required'],
        requiredFieldsOptions: {
          filterByColumn: true,
          // orderBy: {
          //   column: 'description',
          //   ascending: true
          // },
          rowClassCallback: row => {
            if (row.isNew) {
              return 'isNew'
            }
            return ''
          },
          headings: {
            group: 'Group',
            fieldLabel: 'Field',
            message: 'Error Message',
            required: 'Required'
          },
          perPage: 10,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' }
        }
      }
    },
    methods: {
      ...mapActions('managedLists/requiredFields/', ['GET_REQUIREDFIELDS', 'PUT_REQUIREDFIELDS']),
      initialize() {
        this.GET_REQUIREDFIELDS()
      },
      async putRequiredFields(row) {
        let index = null
        let requiredFieldsId = null
        let data = []

        switch (this.requiredFieldsTab) {
          case 'ip':
            index = this.ipRequiredFieldsGridData.findIndex(x => x.field === row.field)
            requiredFieldsId = this.requiredFields.find(f => f.reviewType === 'ip')?.requiredFieldsId
            this.$set(this.ipRequiredFieldsGridData, index, row)
            data = this.ipRequiredFieldsGridData
            break
          case 'op':
            index = this.opRequiredFieldsGridData.findIndex(x => x.field === row.field)
            requiredFieldsId = this.requiredFields.find(f => f.reviewType === 'op')?.requiredFieldsId
            this.$set(this.opRequiredFieldsGridData, index, row)
            data = this.opRequiredFieldsGridData
            break
          case 'pro':
            index = this.proRequiredFieldsGridData.findIndex(x => x.field === row.field)
            requiredFieldsId = this.requiredFields.find(f => f.reviewType === 'pro')?.requiredFieldsId
            this.$set(this.proRequiredFieldsGridData, index, row)
            data = this.proRequiredFieldsGridData
            break
        }

        const payload = {
          requiredFieldsId: requiredFieldsId,
          reviewType: this.requiredFieldsTab,
          requiredFields: JSON.stringify(data),
          requiredFieldsData: data
        }
        try {
          await this.PUT_REQUIREDFIELDS(payload)
          this.$message({
            message: 'Required Fields Updated Successfully',
            type: 'success'
          })
        } catch (err) {
          this.$message({
            message: 'Required Fields Updated Error',
            type: 'error'
          })
        }
      },
      mapRequiredFieldsRules(fields) {
        return fields.map(m => ({
          [m.field]: [{ required: m.required, trigger: 'change' }]
        }))
      }
    },
    mounted: function() {
      this.initialize()
    },
    activated: function() {
      this.initialize()
    },
    computed: {
      ...mapState('managedLists/requiredFields/', ['requiredFields', 'ipRequiredFieldsGridData', 'opRequiredFieldsGridData', 'proRequiredFieldsGridData', 'requiredFieldsLoading'])
    }
  }
</script>

<style scoped>
  >>> input[name='vf__required'] {
    display: none;
  }
  /* >>>.isNew {
    background-color: blue !important;
    transition: background-color 0.5s ease !important;
  } */

  @-webkit-keyframes isNew {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @-moz-keyframes isNew {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes isNew {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  >>> .isNew {
    -webkit-animation: isNew 0.3s ease-in-out 0s;
    -moz-animation: isNew 0.3s ease-in-out 0s;
    -o-animation: isNew 0.3s ease-in-out 0s;
    animation: isNew 0.3s ease-in-out 0s;
    background-color: rgba(255, 196, 12, 0.2) !important;
  }
</style>
