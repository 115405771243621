<template>
  <div>
    <el-row class="header-row">
      <h3 class="el-page-header">Payor List</h3>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="20">
        <el-form :model="payorListForm" size="mini" ref="payorListForm" :rules="payorListForm.rules" label-position="left" label-width="180px">
          <template v-if="!showpayorListForm">
            <el-button class="hiaButton" plain round size="mini" @click="togglePayorListForm()" icon="el-icon-plus">Add New Payor </el-button>
          </template>
          <el-form-item v-if="showpayorListForm" prop="payorName" label="Payor Name">
            <el-input size="mini" type="text" v-model="payorListForm.payorName" id="payorName"></el-input>
          </el-form-item>
          <el-form-item v-if="showpayorListForm" prop="medicarePayor">
            <template slot="label">
              Medicare Payor
              <div><small style="font-style: italic;">Includes payor in CMS Quality Measure calculations</small></div>
            </template>
            <input size="mini" type="checkbox" v-model="payorListForm.medicarePayor" id="medicarePayor" />
          </el-form-item>
          <el-form-item v-if="showpayorListForm">
            <el-button-group>
              <el-button class="hiaButton" plain round size="mini" type="text" @click="togglePayorListForm()">Cancel</el-button>
              <el-button class="hiaButton" plain round size="mini" @click="postPayorList()">Add</el-button>
            </el-button-group>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row :gutter="10" style="margin: 10px 0px 0px 0px;">
      <el-col :span="24">
        <v-client-table class="hiaTable" v-loading="payorListLoading" element-loading-spinner="atom-audit-loader" ref="payorListGrid" id="payorListGrid" :data="payorList" :columns="payorListColumns" :options="payorListOptions">
          <div slot="filter__inactive">
            <input type="checkbox" @change="$refs.payorListGrid.setFilter({ inactive: $event.target.checked })" />
          </div>
          <div slot="filter__medicarePayor">
            <input type="checkbox" @change="$refs.payorListGrid.setFilter({ medicarePayor: $event.target.checked })" />
          </div>
          <template slot="payorName" slot-scope="props">
            <div class="el-input--mini">
              <input class="el-input__inner" type="text" v-model="props.row.payorName" @change="putPayorList(props.row)" />
            </div>
          </template>
          <template slot="medicarePayor" slot-scope="props">
            <input type="checkbox" v-model="props.row.medicarePayor" @change="putPayorList(props.row)" />
          </template>
          <template slot="inactive" slot-scope="props">
            <input type="checkbox" v-model="props.row.inactive" @change="putPayorList(props.row)" />
          </template>
          <template slot="delete" slot-scope="props">
            <div style="text-align: center;">
              <i class="el-icon-close delete-button" title="Delete Payor" @click="deletePayorList(props.row)"></i>
            </div>
          </template>
        </v-client-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import HiaAuthorization from '@/mixins/hiaAuthorization'
  // import { mapFields } from 'vuex-map-fields'

  export default {
    name: 'payorDetailList',
    mixins: [HiaAuthorization],
    data() {
      return {
        showpayorListForm: false,
        payorListForm: {
          payorName: '',
          payorNumber: null,
          medicarePayor: false,
          rules: {
            payorName: [{ required: true, trigger: 'change' }]
          }
        },
        payorListColumns: ['payorName', 'medicarePayor', 'inactive', 'delete'],
        payorListOptions: {
          filterByColumn: true,
          orderBy: {
            column: 'payorName',
            ascending: true
          },
          headings: {
            payorName: function(h) {
              return 'Payor Name'
            },
            medicarePayor: function(h) {
              return 'Medicare Payor'
            },
            delete: function(h) {
              return ''
            }
          },
          perPage: 10,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' }
        }
      }
    },
    methods: {
      ...mapActions('managedLists/payors/', ['GET_PAYORLIST']),
      ...mapActions('managedLists/payors/', ['PUT_PAYORLIST', 'POST_PAYORLIST', 'DELETE_PAYORLIST']),
      ...mapMutations('dropdowns/', ['REMOVE_CACHED_DROPDOWN']),
      initialize() {
        this.GET_PAYORLIST()
      },
      togglePayorListForm() {
        this.showpayorListForm = !this.showpayorListForm
      },
      postPayorList() {
        this.$refs.payorListForm.validate(async valid => {
          if (valid) {
            try {
              const payload = {
                payorList: {
                  payorName: this.payorListForm.payorName,
                  medicarePayor: this.payorListForm.medicarePayor
                }
              }
              this.POST_PAYORLIST(payload)
              this.REMOVE_CACHED_DROPDOWN('payors')
              this.clearForm()
              this.$message({
                message: 'Payor Added Successful',
                type: 'success'
              })
            } catch (err) {
              this.$message({
                message: 'Payor Added Error',
                type: 'error'
              })
            }
          }
        })
      },
      async putPayorList(row) {
        const index = this.payorList.findIndex(x => x.payorNumber === row.payorNumber)
        this.$set(this.payorList, index, row)

        try {
          await this.PUT_PAYORLIST(row)
          this.REMOVE_CACHED_DROPDOWN('payors')
          this.$message({
            message: 'Payor Updated Successfully',
            type: 'success'
          })
        } catch (err) {
          this.$message({
            message: 'Payor Updated Error',
            type: 'error'
          })
        }
      },
      async deletePayorList(row) {
        await this.$confirm(`Permanently delete Payor ${row.payorName}? Any Associated Payor Detail records will also be deleted.`, 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        })

        try {
          await this.DELETE_PAYORLIST(row.payorNumber)
          this.REMOVE_CACHED_DROPDOWN('payors')
          this.$message({
            message: 'Payor Delete Successful',
            type: 'success'
          })
        } catch (err) {
          this.$message({
            message: `Error Deleting Payor: ${err.message}`,
            type: 'error'
          })
        }
      },
      clearForm() {
        this.payorListForm = {
          payorName: '',
          payorNumber: null
        }
      }
    },
    mounted: function() {
      this.initialize()
    },
    activated: function() {
      this.initialize()
    },
    computed: {
      // ...mapFields(['payorList.payorName']),
      ...mapState('managedLists/payors/', ['payorList', 'payorListLoading'])
    }
  }
</script>

<style scoped>
  >>> input[name='vf__medicarePayor'] {
    display: none;
  }
</style>
