<!--
This is designed to be a reusable component to pick a Recommendation from the Recommendation Library
-->

<template>
  <div>
    <el-row class="header-row">
      <el-col :span="24">
        <h3 class="el-page-header">Recommendation Library</h3>
      </el-col>
    </el-row>
    <el-form :model="addRecommendationForm" size="mini" ref="addRecommendationForm" :rules="addRecommendationForm.rules" label-position="left" label-width="160px">
      <el-row :gutter="20">
        <el-col :span="12">
          <template v-if="!showAddRecommendationForm">
            <el-button class="hiaButton" plain round size="mini" @click="toggleAddRecommendationForm" icon="el-icon-plus">Add Recommendation</el-button>
          </template>
          <el-form-item v-if="showAddRecommendationForm" prop="title" label="Title">
            <el-input size="mini" type="text" v-model="addRecommendationForm.title" id="title"></el-input>
          </el-form-item>
          <el-form-item v-if="showAddRecommendationForm" prop="recommendationType" label="Recommendation Type">
            <hia-el-select v-model="addRecommendationForm.recommendationType" placeholder="Recommendation Type" :clearable="false" :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.RecommendationTypes.list"> </hia-el-select>
          </el-form-item>
          <el-form-item v-if="showAddRecommendationForm" prop="recordType" label="Record Type">
            <hia-el-select v-model="addRecommendationForm.recordType" placeholder="Record Type" :clearable="true" :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.RecommendationRecordTypes.list"> </hia-el-select>
          </el-form-item>
          <el-form-item v-if="showAddRecommendationForm" prop="codeGroup" label="Code Group">
            <hia-el-select v-model="addRecommendationForm.codeGroup" placeholder="Code Group" :clearable="true" :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.RecommendationCodeGroups.list"> </hia-el-select>
          </el-form-item>
          <el-form-item v-if="showAddRecommendationForm" prop="recommendation" label="Recommendation">
            <Tinymce id="recommendation" v-model="addRecommendationForm.recommendation"></Tinymce>
          </el-form-item>
          <el-form-item v-if="showAddRecommendationForm">
            <el-button-group class="pull-right">
              <el-button class="hiaButton" plain round size="mini" type="text" @click="toggleAddRecommendationForm">Cancel</el-button>
              <el-button class="hiaButton" plain round size="mini" @click="addRecommendation()">Add</el-button>
            </el-button-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-button style="float: right;" class="hiaButton" plain round size="mini" @click="triggerShowReccomendationLibraryPrintable"><i class="mdi mdi-file-download-outline"></i> Print</el-button>
        </el-col>
      </el-row>
    </el-form>

    <v-client-table style="margin: 10px 0px 0px 0px;" v-loading="loading" element-loading-spinner="atom-audit-loader" class="hiaTable" ref="recommendationsGrid" id="recommendationsGrid" :data="recommendationLibrary" :columns="recommendationsColumns" :options="options">
      <div slot="filter__inactive">
        <input type="checkbox" @change="$refs.recommendationsGrid.setFilter({ inactive: $event.target.checked })" />
      </div>
      <template slot-scope="props" slot="title">
        <router-link :to="'/reviews/recommendation-library/' + props.row.id">{{ props.row.title }}</router-link>
      </template>
      <template slot-scope="props" slot="recommendation">
        <div v-html="getRecommendation(props.row.recommendation)"></div>
      </template>
      <template slot-scope="props" slot="recommendationType">
        {{ getDropdownLabel(dropdowns.RecommendationTypes.list, props.row.recommendationType) }}
      </template>
      <template slot-scope="props" slot="recordType">
        {{ props.row.recordType ? getDropdownLabel(dropdowns.RecommendationRecordTypes.list, props.row.recordType) : 'Any' }}
      </template>
      <template slot-scope="props" slot="codeGroup">
        {{ props.row.codeGroup ? getDropdownLabel(dropdowns.RecommendationCodeGroups.list, props.row.codeGroup) : 'Any' }}
      </template>
      <template slot="inactive" slot-scope="props">
        <input :key="props.row.id" type="checkbox" v-model="props.row.inactive" @change="putRecommendationRecord(props.row)" />
      </template>
      <template slot="delete" slot-scope="props">
        <div>
          <i style="float: right; cursor: pointer;" title="Delete Recommendation" class="el-icon-close delete-button" @click="deleteRecommendationRecord(props.row.id)"></i>
        </div>
      </template>
    </v-client-table>
    <div class="printThis">
      <RecommendationLibraryListPrintable class="recommendationLibraryListPrintable" ref="recommendationLibraryListPrintable" :activatePrint="activatePrint" @printRecommendations="printRecommendations"></RecommendationLibraryListPrintable>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions, mapGetters } from 'vuex'
  import Tinymce from '@/components/controls/Tinymce'
  import DropdownHelper from '@/mixins/dropdown-helper'
  import RecommendationLibraryListPrintable from '@/views/reviews/recommendationLibrary/RecommendationLibraryListPrintable'

  export default {
    name: 'RecommendationLibraryList',
    mixins: [DropdownHelper],
    data() {
      return {
        showReccomendationLibraryPrintable: false,
        activatePrint: false,
        loading: true,
        recommendations: [],
        showAddRecommendationForm: false,
        addRecommendationForm: {
          title: '',
          recommendationType: '',
          recordType: '',
          recommendation: '',
          rules: {
            title: [{ required: true, message: 'Title is required', trigger: 'change' }],
            recommendationType: [{ required: true, message: 'Recommendation Type is required', trigger: 'change' }],
            recommendation: [{ required: true, message: 'Recommendation is required', trigger: 'change' }]
          }
        },
        recommendationsColumns: ['title', 'recommendation', 'recommendationType', 'recordType', 'codeGroup', 'createdByName', 'inactive', 'delete'],
        options: {
          // see the options API
          filterByColumn: true,
          listColumns: {
            recommendationType: [],
            recordType: [],
            codeGroup: [],
            createdByName: []
          },
          columnsClasses: {
            title: 'twenty-percent',
            recommendation: 'fifty-percent',
            delete: 'deleteButton'
          },
          headings: {
            recommendationType: 'Recommendation Type',
            recordType: 'Record Type',
            codeGroup: 'Code Group',
            rec: 'Recommendation',
            createdByName: 'Created By',
            delete: ''
          },
          orderBy: {
            column: 'title',
            ascending: true
          },
          perPage: 15,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' }
        },
        dropdownsNeeded: ['RecommendationTypes', 'RecommendationRecordTypes', 'RecommendationCodeGroups', 'consultants']
      }
    },
    methods: {
      ...mapActions('recommendationLibrary/', ['GET_RECOMMENDATION_LIBRARY_ALL', 'PUT_RECOMMENDATION_RECORD', 'POST_RECOMMENDATION_RECORD', 'DELETE_RECOMMENDATION_RECORD']),
      ...mapActions('dropdowns/', ['GET_DROPDOWNS']),
      initialize() {
        this.loading = true
        Promise.all([this.GET_RECOMMENDATION_LIBRARY_ALL(), this.GET_DROPDOWNS(this.dropdownsNeeded)]).then(() => {
          this.options.listColumns.recommendationType = this.GET_COLUMNFILTERARRAY('RecommendationTypes')
          this.options.listColumns.recordType = this.GET_COLUMNFILTERARRAY('RecommendationRecordTypes')
          this.options.listColumns.codeGroup = this.GET_COLUMNFILTERARRAY('RecommendationCodeGroups')
          const recUsers = [...new Set(this.recommendationLibrary.map(m => m.createdByName))].map(m => ({ id: m, text: m }))
          this.options.listColumns.createdByName = recUsers
          this.loading = false
        })
      },
      addRecommendation() {
        this.$refs.addRecommendationForm.validate(async valid => {
          if (valid) {
            try {
              const newRecommendation = {
                title: this.addRecommendationForm.title,
                recordType: this.addRecommendationForm.recordType,
                recommendationType: this.addRecommendationForm.recommendationType,
                recommendation: this.addRecommendationForm.recommendation,
                codeGroup: this.addRecommendationForm.codeGroup,
                createdBy: this.currentTenantUserId
              }

              await this.POST_RECOMMENDATION_RECORD(newRecommendation)
              this.$refs.addRecommendationForm.resetFields()
              this.$message({
                message: 'Recommendation Library Added Successful',
                type: 'success'
              })
            } catch (err) {
              this.$message({
                message: 'Recommendation Library Added Error',
                type: 'error'
              })
            } finally {
              this.showAddRecommendationForm = false
              this.$refs.addRecommendationForm.resetFields()
            }
          }
        })
      },
      getRecommendation(recommendationHtml) {
        const rec = recommendationHtml.length > 500 ? recommendationHtml.substring(0, 500) + '...' : recommendationHtml
        return rec
      },
      toggleAddRecommendationForm() {
        this.showAddRecommendationForm = !this.showAddRecommendationForm
      },
      async putRecommendationRecord(row) {
        const index = this.recommendationLibrary.findIndex(x => x.id === row.id)
        this.$set(this.recommendationLibrary, index, row)

        try {
          this.loading = true
          await this.PUT_RECOMMENDATION_RECORD(row)
          this.loading = false
          this.$message({
            message: 'Recommendation Library Updated Successfully',
            type: 'success'
          })
        } catch (err) {
          this.loading = false
          const message = err.message ? `Error Updating Recommendation Library: ${err.message}` : 'Error Updating Recommendation Library'
          this.$message({
            message: message,
            type: 'error'
          })
        }
      },
      deleteRecommendationRecord(id) {
        const row = this.recommendationLibrary.find(x => x.id === id)

        this.$confirm('Delete the recommendation ' + row.title + '?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          this.loading = true
          this.DELETE_RECOMMENDATION_RECORD(id).then(() => {
            this.loading = false
            this.$message({
              message: 'Recommendations Deketed Successfully',
              type: 'success'
            })
          })
        })
      },
      triggerShowReccomendationLibraryPrintable() {
        this.activatePrint = true
      },
      printRecommendations() {
        window.print()
        this.activatePrint = false
      }
    },
    computed: {
      ...mapState('recommendationLibrary/', ['recommendationLibrary']),
      ...mapState('dropdowns/', ['dropdowns']),
      ...mapGetters('dropdowns/', ['GET_COLUMNFILTERARRAY']),
      ...mapState('user/', ['currentTenantGuid', 'currentTenantUserId'])
    },
    activated: function() {
      this.initialize()
    },
    created: function() {
      this.initialize()
    },
    mounted: function() {
      // this.initialize()
    },
    watch: {
      currentTenantGuid() {
        this.initialize()
      }
    },
    components: {
      Tinymce,
      RecommendationLibraryListPrintable
    }
  }
</script>

<style>
  .printThis {
    visibility: hidden;
    position: absolute;
    left: -9000;
    max-height: 0;
    display: none;
  }
  @media print {
    .printThis {
      position: absolute !important;
      left: 0 !important;
      top: 0 !important;
      margin: 0 !important;
      padding: 0 !important;
      min-height: 550px;
      width: 100% !important;
      visibility: visible !important;
      margin-top: 0 !important;
      overflow: visible !important; /* Remove scrollbar for printing. */
      margin-top: 0 !important;
      background: #ffffff !important;
      z-index: 99999 !important;
      max-height: 100%;
      display: block;
    }
    /* .printDialog .el-dialog__header,
    .print-hidden {
      visibility: hidden !important;
    }
    .printDialog .el-dialog__body {
      padding: 0px 0px 0px 0px !important;
    } */
    .recommendationLibraryListPrintable {
      background: #ffffff;
      padding: 5px;
    }
  }
</style>

<style scoped>
  >>> .twenty-percent {
    width: 20%;
  }

  >>> .fifty-percent {
    width: 50%;
  }

  >>> .deleteButton {
    width: 25px;
    text-align: center;
  }

  >>> .deleteButton th {
    display: none;
  }
</style>
