<template>
  <div id="tasks">
    <el-row class="topMargin">
      <v-client-table class="hiaTable" v-loading="loading" element-loading-spinner="atom-audit-loader" ref="clientChecklistItemsGrid" id="clientChecklistItemsGrid"
        :data="clientChecklistItems.filter(x => x.checklistId === selectedChecklist.checklistId)" :columns="taskColumns" :options="taskOptions" uniqueKey="itemId">
        <div slot="filter__inactive">
          <input type="checkbox" @change="$refs.clientChecklistItemsGrid.setFilter({inactive: $event.target.checked})">
        </div>
        <template slot="order" slot-scope="props">
          <div class="el-input--mini">
            <input class="el-input__inner" type="number" v-model="props.row.order" @change="updateChecklistItem(props.row)">
          </div>
        </template>
        <template slot="name" slot-scope="props">
          <div class="el-input--mini">
            <input class="el-input__inner" type="text" v-model="props.row.name" @change="updateChecklistItem(props.row)">
          </div>
        </template>
        <template slot="inactive" slot-scope="props">
          <input :key="props.row.itemId" type="checkbox" v-model="props.row.inactive" @change="updateChecklistItem(props.row)">
        </template>
        <template slot="delete" slot-scope="props">
          <div style="text-align: center;">
            <i class="el-icon-close delete-button" @click="deleteChecklistItem(props.row)"></i>
          </div>
        </template>
      </v-client-table>
    </el-row>
    <el-form label-position="left" label-width="150px" size="mini" style="margin-top: 10px;">
      <div v-if="!showAddItemForm">
        <el-popover placement="right" ref="newItemPopover" :title="`Add Item to ${selectedChecklist.name}`" width="300" trigger="click" @show="handleOpenPopover">
          <el-form :model="addItemForm" ref="addItemFormIp" size="mini" :rules="addItemForm.rules" label-position="top" label-width="180px" @submit.native.prevent>
            <el-form-item prop="name" label="Item Name">
              <el-input v-model="addItemForm.name" size="mini"></el-input>
            </el-form-item>
            <el-form-item prop="order" label="Order">
              <el-input type="number" v-model="addItemForm.order" size="mini"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button-group>
                <el-button class="hiaButton" plain round size="mini" type="text" @click="closenewItemPopover()">Cancel</el-button>
                <el-button class="hiaButton" plain round size="mini" @click="addChecklistItem('ip', 'addItemFormIp')">Add</el-button>
              </el-button-group>
            </el-form-item>
          </el-form>

          <el-button slot="reference" class="hiaButton" plain round size="mini" icon="el-icon-plus">Add Checklist Item</el-button>
        </el-popover>
      </div>
    </el-form>

  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import HiaAuthorization from '@/mixins/hiaAuthorization'
  import hiaTable from '@/mixins/hiaTable'

  export default {
    name: 'taskTab',
    mixins: [hiaTable, HiaAuthorization],
    props: ['selectedChecklist'],
    data() {
      return {
        activeTab: 'ip',
        loading: false,
        showMergeProjectTask: false,
        mergeProjectTaskLoading: false,
        selectedMergeProjectTask: {},
        taskColumns: ['name', 'order', 'inactive', 'delete'],
        taskOptions: {
          filterByColumn: false,
          filterable: false,
          sortable: ['name'],
          orderBy: {
            column: 'order',
            ascending: true
          },
          headings: {
            checklistId: 'Checklist',
            delete: function (h) {
              return ''
            },
            name: 'Item'
          },
          perPage: 10,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' }
        },
        showAddItemForm: false,
        addItemForm: {
          name: '',
          order: '',
          rules: {
            name: [
              { required: true, message: 'Please Input Name', trigger: 'none' }
            ],
            order: [
              { required: true, message: 'Please Input Order', trigger: 'none' }
            ]
          }
        }
      }
    },
    methods: {
      ...mapActions('managedLists/clientChecklistItems/', ['GET_CLIENT_CHECKLIST_ITEMS', 'POST_CLIENT_CHECKLIST_ITEM', 'DELETE_CLIENT_CHECKLIST_ITEM', 'PUT_CLIENT_CHECKLIST_ITEM']),
      initialize(route) {
        // this.loading = true
        // this.GET_PROJECT_CHECKLIST_ITEMS().then(response => {
        //   this.loading = false
        // })
      },
      getProjectTypeId(projectType) {
        let projectTypeId
        switch (projectType) {
          case 'ip':
            projectTypeId = 1001
            break
          case 'op':
            projectTypeId = 1003
            break
          case 'pro':
            projectTypeId = 1002
            break
          case 'amb':
            projectTypeId = 1004
            break
        }
        return projectTypeId
      },
      addChecklistItem(projectType, refName) {
        const projectTypeId = this.getProjectTypeId(projectType)

        this.$refs[refName].validate(async (valid) => {
          if (valid) {
            try {
              this.loading = true

              const payload = {
                checklistItem: {
                  order: this.addItemForm.order,
                  projectTypeId: projectTypeId,
                  name: this.addItemForm.name,
                  checklistId: this.selectedChecklist.checklistId
                }
              }
              await this.POST_CLIENT_CHECKLIST_ITEM(payload)
              this.$refs[refName].resetFields()
              this.closenewItemPopover()
              this.loading = false
              this.$message({
                message: 'Checklist Item Added',
                type: 'success'
              })
            } catch (err) {
              this.loading = false
              this.$message({
                message: `Error Adding Checklist Item: ${err.message}`,
                type: 'error'
              })
            }
          }
        })
      },
      async updateChecklistItem(item) {
        const index = this.clientChecklistItems.findIndex(x => x.itemId === item.itemId)
        this.$set(this.clientChecklistItems, index, item)

        try {
          this.loading = true
          await this.PUT_CLIENT_CHECKLIST_ITEM(item)
          this.loading = false
          this.$message({
            message: 'Checklist Item Saved',
            type: 'success'
          })
        } catch (err) {
          this.loading = false
          this.$message({
            message: 'Error Editing Checklist Item',
            type: 'error'
          })
        }
      },
      async deleteChecklistItem(item) {
        if (item.usedOnProjects) {
          const res = await this.$prompt(`The item ${item.name} has been used in Projects. Do you want to permanently delete this item and remove from all associated projects? If so, enter the text DELETE below and click OK.`, 'Warning', {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel'
          })

          res.value = res.value ? res.value : 'blank'

          if (res.value.trim() !== 'DELETE') {
            this.$message({
              message: `Error Deleting Checklist Item, expected the value DELETE but received ${res.value}`,
              type: 'error'
            })
            return
          }
        } else {
          await this.$confirm(`Permanently delete ${item.name}?`, 'Warning', {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
          })
        }

        try {
          this.loading = true
          await this.DELETE_CLIENT_CHECKLIST_ITEM(item)
          this.loading = false
          this.$message({
            message: 'Checklist Item Deleted Successfully',
            type: 'success'
          })
        } catch (err) {
          this.loading = false
          this.$message({
            message: `Error Deleting Checklist Item: ${err.message}`,
            type: 'error'
          })
        }
      },
      handleOpenPopover() {
        const tasksForType = this.clientChecklistItems.filter(x => x.checklistId === this.selectedChecklist.checklistId)
        let maxOrder = Math.max(...tasksForType.map(x => x.order))
        if (!isFinite(maxOrder)) {
          maxOrder = 0
        }
        this.addItemForm.order = maxOrder + 1
      },
      closenewItemPopover() {
        const popper = this.$refs.newItemPopover
        if (popper) {
          this.addItemForm.name = null
          this.addItemForm.order = null
          popper.doDestroy()
          popper.doClose()
        }
      }
    },
    computed: {
      ...mapState('managedLists/clients/', ['clientList']),
      ...mapState('managedLists/clientChecklistItems/', ['clientChecklistItems']),
      ...mapState('user/', ['currentTenantGuid']),
      disableMergeButton() {
        return this.clientChecklistItems.filter(x => x.selected).length < 2
      }
    },
    watch: {
      currentTenantGuid() {
        this.initialize(this.$route)
      }
    },
    created: function () {
      this.initialize(this.$route)
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .taskOption {
    cursor: pointer;
    padding: 0px 20px 0px 20px;
    color: #337ab7;
  }

  .taskOption:hover {
    background: rgba(255, 196, 12, 0.2);
    border-radius: 2px;
  }

  .selected {
    background: rgba(255, 196, 12, 0.2);
  }

  .topMargin {
    margin: 10px 0px 0px 0px;
  }

  >>>.VuePagination__count {
    display: none;
  }
</style>