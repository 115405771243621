<template>
  <div id="Modifiers">
    <!-- <el-row class="header-row">
      <h3 class="el-page-header">Modifiers</h3>
    </el-row> -->

    <el-tabs>
      <el-tab-pane label="Custom Modifiers">
        <el-form label-position="left" label-width="150px" size="mini">
          <div v-if="!showAddModifierForm">
            <el-button-group>
              <el-button class="hiaButton" plain round size="mini" @click="toggleAddModifierForm()" icon="el-icon-plus">Add Modifier</el-button>
            </el-button-group>
          </div>
          <el-row v-if="showAddModifierForm" style="margin-top: 15px;">
            <el-col :lg="8" :md="12">
              <el-form :model="addModifierForm" ref="addModifierForm" size="mini" :rules="addModifierForm.rules" label-position="left" label-width="180px" @submit.native.prevent>
                <el-form-item prop="modifier" label="Modifier">
                  <el-input v-model="addModifierForm.modifier" size="mini" maxlength="5"></el-input>
                </el-form-item>
                <el-form-item prop="description" label="Description">
                  <el-input v-model="addModifierForm.description" size="mini"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button-group>
                    <el-button class="hiaButton" plain round size="mini" type="text" @click="toggleAddModifierForm()">Cancel</el-button>
                    <el-button class="hiaButton" plain round size="mini" @click="addModifier">Add</el-button>
                  </el-button-group>
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>
          <el-row class="topMargin">
            <el-col :lg="12" :md="18">
              <v-client-table class="hiaTable" v-loading="loading" element-loading-spinner="atom-audit-loader" ref="modifierListsGrid" id="modifierListsGrid" :data="modifierList" :columns="modifierColumns" :options="modifierOptions" uniqueKey="modifierId">
                <div slot="filter__inactive">
                  <input type="checkbox" @change="$refs.modifierListsGrid.setFilter({ inactive: $event.target.checked })" />
                </div>
                <template slot="description" slot-scope="props">
                  <div class="el-input--mini">
                    <input class="el-input__inner" type="text" v-model="props.row.description" @change="updateModifier(props.row)" />
                  </div>
                </template>
                <template slot="inactive" slot-scope="props">
                  <input :key="props.row.modifierId" type="checkbox" v-model="props.row.inactive" @change="updateModifier(props.row)" />
                </template>
                <template slot="delete" slot-scope="props">
                  <div style="text-align: center;">
                    <i class="el-icon-close delete-button" @click="deleteModifier(props.row)"></i>
                  </div>
                </template>
              </v-client-table>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="Standard Modifiers">
        <v-client-table class="hiaTable" v-loading="loading" element-loading-spinner="atom-audit-loader" ref="modifierListsGrid" id="modifierListsGrid" :data="dropdowns.modifiers.list" :columns="['key', 'value']" :options="standardModifierOptions" uniqueKey="key"> </v-client-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import HiaAuthorization from '@/mixins/hiaAuthorization'
  import hiaTable from '@/mixins/hiaTable'

  export default {
    name: 'modifierTab',
    mixins: [hiaTable, HiaAuthorization],
    props: [],
    data() {
      return {
        loading: false,
        showMergeModifier: false,
        mergeModifierLoading: false,
        selectedMergeModifier: {},
        modifierColumns: ['modifier', 'description', 'inactive', 'delete'],
        modifierOptions: {
          filterByColumn: true,
          orderBy: {
            column: 'modifier',
            ascending: true
          },
          headings: {
            key: 'Modifier',
            value: 'Description',
            delete: function(h) {
              return ''
            }
          },
          perPage: 10,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' }
        },
        standardModifierOptions: {
          filterByColumn: true,
          orderBy: {
            column: 'key',
            ascending: true
          },
          headings: {
            key: 'Modifier',
            value: 'Description'
          },
          perPage: 10,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' }
        },
        showAddModifierForm: false,
        addModifierForm: {
          modifier: null,
          description: null,
          rules: {
            modifier: [{ required: true, message: 'Please Input Modifier', trigger: 'none' }]
          }
        }
      }
    },
    methods: {
      ...mapActions('managedLists/modifiers/', ['GET_MODIFIERS', 'POST_MODIFIER', 'DELETE_MODIFIER', 'PUT_MODIFIER', 'PUT_MERGE_MODIFIER']),
      ...mapMutations('managedLists/modifiers/', ['SET_SELECTED']),
      ...mapMutations('dropdowns/', ['REMOVE_CACHED_DROPDOWN']),
      ...mapActions('dropdowns/', ['GET_DROPDOWNS']),
      async initialize(route) {
        this.loading = true
        await Promise.all([this.GET_MODIFIERS(), this.GET_DROPDOWNS(['modifiers'])])
        this.loading = false
      },
      localselectAll(checked) {
        let filteredRecords = []

        if (this.$refs.modifierListsGrid) {
          filteredRecords = this.$refs.modifierListsGrid.allFilteredData
        }

        const recs = this.modifierList.filter(x => filteredRecords.some(y => y.modifierId === x.modifierId))
        hiaTable.selectAll(recs, checked)
        this.SET_SELECTED(recs)
      },
      selectRecord(row, e) {
        const checked = e.srcElement.checked
        row.selected = checked
        this.SET_SELECTED([row])
      },
      addModifier() {
        this.$refs.addModifierForm.validate(async valid => {
          if (valid) {
            try {
              this.loading = true
              const payload = {
                modifier: {
                  modifier: this.addModifierForm.modifier,
                  description: this.addModifierForm.description
                }
              }
              await this.POST_MODIFIER(payload)
              this.$refs.addModifierForm.resetFields()
              this.toggleAddModifierForm()
              this.REMOVE_CACHED_DROPDOWN('modifiers')
              this.loading = false
              this.$message({
                message: 'Modifier Added',
                type: 'success'
              })
            } catch (err) {
              this.loading = false
              this.$message({
                message: `Error Adding Modifier: ${err.message}`,
                type: 'error'
              })
            }
          }
        })
      },
      async updateModifier(modifier) {
        const index = this.modifierList.findIndex(x => x.modifierId === modifier.modifierId)
        this.$set(this.modifierList, index, modifier)

        try {
          this.loading = true
          await this.PUT_MODIFIER(modifier)
          this.REMOVE_CACHED_DROPDOWN('modifiers')
          this.loading = false
          this.$message({
            message: 'Modifier Saved',
            type: 'success'
          })
        } catch (err) {
          this.loading = false
          this.$message({
            message: 'Error Editing Modifier',
            type: 'error'
          })
        }
      },
      async deleteModifier(modifier) {
        await this.$confirm(`Permanently delete ${modifier.modifier}?`, 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        })

        try {
          this.loading = true
          await this.DELETE_MODIFIER(modifier)
          this.REMOVE_CACHED_DROPDOWN('modifiers')
          this.loading = false
          this.$message({
            message: 'Modifier Deleted Successfully',
            type: 'success'
          })
        } catch (err) {
          this.loading = false
          this.$message({
            message: `Error Deleting Modifier: ${err.message}`,
            type: 'error'
          })
        }
      },
      toggleAddModifierForm() {
        this.showAddModifierForm = !this.showAddModifierForm
      },
      toggleMergeModifier() {
        this.selectedMergeModifier = {}
        this.showMergeModifier = !this.showMergeModifier
      }
    },
    computed: {
      ...mapState('managedLists/clients/', ['clientList']),
      ...mapState('managedLists/modifiers/', ['modifierList', 'modifierListLoading']),
      ...mapState('dropdowns/', ['dropdowns']),
      ...mapState('user/', ['currentTenantGuid']),
      disableMergeButton() {
        return this.modifierList.filter(x => x.selected).length < 2
      }
    },
    watch: {
      currentTenantGuid() {
        this.initialize(this.$route)
      }
    },
    created: function() {
      this.initialize(this.$route)
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .modifierOption {
    cursor: pointer;
    padding: 0px 20px 0px 20px;
    color: #337ab7;
  }

  .modifierOption:hover {
    background: rgba(255, 196, 12, 0.2);
    border-radius: 2px;
  }

  .selected {
    background: rgba(255, 196, 12, 0.2);
  }

  .topMargin {
    margin: 10px 0px 0px 0px;
  }
</style>
