<template>
  <div>
    <el-row class="header-row">
      <h3 class="el-page-header">Code Types</h3>
    </el-row>
    <el-row :gutter="10">
      <el-col :lg="8" :md="12">
        <el-form :model="codeTypesForm" size="mini" ref="codeTypesForm" :rules="codeTypesForm.rules" label-position="left" label-width="180px">
          <template v-if="!showcodeTypesForm">
            <el-button class="hiaButton" plain round size="mini" @click="togglecodeTypesForm()" icon="el-icon-plus">Add New Code Type </el-button>
          </template>
          <el-form-item v-if="showcodeTypesForm" prop="codeDesc" label="Description">
            <el-input size="mini" type="text" v-model="codeTypesForm.codeDesc" id="codeDesc"></el-input>
          </el-form-item>
          <el-form-item v-if="showcodeTypesForm">
            <el-button-group>
              <el-button class="hiaButton" plain round size="mini" type="text" @click="togglecodeTypesForm()">Cancel</el-button>
              <el-button class="hiaButton" plain round size="mini" @click="postCodeType()">Add</el-button>
            </el-button-group>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row style="margin: 10px 0px 0px 0px;">
      <el-col :lg="12" :md="18">
        <v-client-table class="hiaTable" v-loading="codeTypesLoading" element-loading-spinner="atom-audit-loader" ref="codeTypesGrid" id="codeTypesGrid" :data="codeTypesList" :columns="codeTypeColumns" :options="codeTypeOptions">
          <div slot="filter__inactive">
            <input type="checkbox" @change="$refs.codeTypesGrid.setFilter({ inactive: $event.target.checked })" />
          </div>
          <template slot="codeDesc" slot-scope="props">
            <div class="el-input--mini">
              <input class="el-input__inner" type="text" v-model="props.row.codeDesc" @change="putCodeType(props.row)" />
            </div>
          </template>
          <template slot="inactive" slot-scope="props">
            <input :key="props.row.codeNumber" type="checkbox" v-model="props.row.inactive" @change="putCodeType(props.row)" />
          </template>
          <template slot="delete" slot-scope="props">
            <div style="text-align: center;">
              <i class="el-icon-close delete-button" title="Delete Code Type" @click="deleteCodeType(props.row)"></i>
            </div>
          </template>
        </v-client-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import HiaAuthorization from '@/mixins/hiaAuthorization'
  // import { mapFields } from 'vuex-map-fields'

  export default {
    name: 'codeTypes',
    mixins: [HiaAuthorization],
    data() {
      return {
        showcodeTypesForm: false,
        codeTypesForm: {
          codeDesc: '',
          rules: {
            codeDesc: [{ required: true, trigger: 'change' }]
          }
        },
        codeTypeColumns: ['codeDesc', 'inactive', 'delete'],
        codeTypeOptions: {
          filterByColumn: true,
          orderBy: {
            column: 'codeDesc',
            ascending: true
          },
          headings: {
            codeDesc: function(h) {
              return 'Description'
            },
            delete: function(h) {
              return ''
            }
          },
          perPage: 10,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' }
        }
      }
    },
    methods: {
      ...mapActions('managedLists/codeTypes/', ['GET_CODE_TYPES', 'PUT_CODE_TYPES', 'POST_CODE_TYPES', 'DELETE_CODE_TYPES']),
      ...mapMutations('dropdowns/', ['REMOVE_CACHED_DROPDOWN']),
      initialize() {
        this.GET_CODE_TYPES()
      },
      togglecodeTypesForm() {
        this.showcodeTypesForm = !this.showcodeTypesForm
      },
      postCodeType() {
        this.$refs.codeTypesForm.validate(async valid => {
          if (valid) {
            try {
              const payload = {
                codeType: {
                  codeDesc: this.codeTypesForm.codeDesc,
                  global: true
                }
              }
              await this.POST_CODE_TYPES(payload)
              this.REMOVE_CACHED_DROPDOWN('codeTypes')
              this.clearForm()
              this.$message({
                message: 'Code Type Added Successful',
                type: 'success'
              })
            } catch (err) {
              this.$message({
                message: 'Code Type Added Error',
                type: 'error'
              })
            }
          }
        })
      },
      async putCodeType(row) {
        const index = this.codeTypesList.findIndex(x => x.codeNumber === row.codeNumber)
        this.$set(this.codeTypesList, index, row)

        try {
          await this.PUT_CODE_TYPES(row)
          this.REMOVE_CACHED_DROPDOWN('codeTypes')
          this.$message({
            message: 'Code Type Updated Successfully',
            type: 'success'
          })
        } catch (err) {
          this.$message({
            message: 'Code Type Updated Error',
            type: 'error'
          })
        }
      },
      async deleteCodeType(row) {
        await this.$confirm(`Permanently delete Code Type ${row.codeDesc}?`, 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        })

        try {
          await this.DELETE_CODE_TYPES(row.codeNumber)
          this.REMOVE_CACHED_DROPDOWN('codeTypes')
          this.$message({
            message: 'Code Type Delete Successful',
            type: 'success'
          })
        } catch (err) {
          this.$message({
            message: `Error Deleting Code Type: ${err.message}`,
            type: 'error'
          })
        }
      },
      clearForm() {
        this.codeTypesForm = {
          codeDesc: ''
        }
      }
    },
    mounted: function() {
      this.initialize()
    },
    activated: function() {
      this.initialize()
    },
    computed: {
      ...mapState('managedLists/codeTypes/', ['codeTypesList', 'codeTypesLoading'])
    }
  }
</script>
