<template>
  <div style="min-height: 25vh;">
    <el-row class="header-row" :gutter="20">
      <el-col :span="12">
        <h3 class="el-page-header">Organization Setup</h3>

        <el-form size="mini" ref="form" label-position="left" label-width="120px" v-if="!loading">
          <el-form-item size="mini" label="Organization Title">
            <el-input style="width: 400px;" v-model="tenantSetup.tenantTitle" @change="putTenantSetup"></el-input>
          </el-form-item>

          <el-form-item size="mini">
            <label slot="label">
              Logo
              <el-tooltip effect="dark" content="Upload a rectangular logo image to show logo on printable reports" placement="top">
                <i class="el-icon-info"></i>
              </el-tooltip>
            </label>
            <div class="imgContainer" v-loading="imageLoading" element-loading-spinner="atom-audit-loader">
              <img v-if="tenantSetup.imageUrl" style="border: 1px solid #DCDFE6; border-radius: 5px; width: 240px;" :src="tenantSetup.imageUrl">
              <el-button v-if="tenantSetup.imageUrl" id="removeImageButton" class="hiaButton imgButton" plain round @click="handleImageButtonRemove">Remove Image
              </el-button>
              <div v-if="!tenantSetup.imageUrl" style="width: 240px; height: 80px; border: 1px solid #DCDFE6; border-radius: 5px; text-align: center;">
                <el-button class="hiaButton" plain round style="position: relative; top: 25px;" @click="handleImageButton">Add Image</el-button>
              </div>
            </div>
            <el-button v-if="tenantSetup.imageUrl" style="margin-top: 20px;" class="hiaButton" plain round @click="showExample = true">View Report Example</el-button>
          </el-form-item>
        </el-form>
        <input id="imageFile" style="display: none;" name="txttoImport" type="file" accept="image/x-png,image/gif,image/jpeg" @change="onFileChange">
      </el-col>

      <el-col :span="12">
        <h3 class="el-page-header">Import Defaults</h3>

        <el-form size="mini" ref="form" label-position="left" label-width="250px" v-if="!loading">
          <el-form-item size="mini" label="Inpatient Mapping">
            <mappingSelector v-model="tenantSetup.inpatientMapping" @input="putTenantSetup" :projectTypeId="getProjectTypeId('Inpatient')">
            </mappingSelector>
          </el-form-item>
          <el-form-item size="mini" label="Outpatient Mapping">
            <mappingSelector v-model="tenantSetup.outpatientMapping" @input="putTenantSetup" :projectTypeId="getProjectTypeId('Outpatient')">
            </mappingSelector>
          </el-form-item>
          <el-form-item size="mini" label="Provider Mapping">
            <mappingSelector v-model="tenantSetup.providerMapping" @input="putTenantSetup" :projectTypeId="getProjectTypeId('Professional Fee')">
            </mappingSelector>
          </el-form-item>
          <el-form-item size="mini" label="Ambulatory Mapping">
            <mappingSelector v-model="tenantSetup.ambulatoryMapping" @input="putTenantSetup" :projectTypeId="getProjectTypeId('Ambulatory')">
            </mappingSelector>
          </el-form-item>

        </el-form>
      </el-col>
    </el-row>

    <div v-loading="loading" element-loading-spinner="atom-audit-loader">
    </div>

    <el-dialog title="" :visible="showExample" width="90%" @close="showExample = false">
      <TenantReportHeader></TenantReportHeader>
      <h1 style="margin-top: 20px;">Inpatient Coding Audit Results</h1>
      <IPSummaryPrintable key="Inpatient" :item="reportSampleData"></IPSummaryPrintable>
    </el-dialog>

  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import IPSummaryPrintable from '@/views/reports/printable/IP_SummaryPrintable'
  import TenantReportHeader from '@/views/reports/printable/TenantReportHeader.vue'
  import HiaAuthorization from '@/mixins/hiaAuthorization'
  import mappingSelector from '@/components/controls/mappingSelector'

  export default {
    name: 'managedListsSetup',
    mixins: [HiaAuthorization],
    data() {
      return {
        editMediaList: [],
        loading: true,
        imageLoading: false,
        showExample: false,
        reportSampleData: {
          patTypeDesc: 'All Inpatient Records',
          dxAdditions: 24,
          dxDeletions: 9,
          dxRevisions: 19,
          pxAdditions: 9,
          pxDeletions: 13,
          pxRevisions: 7,
          totalRecords: 87,
          dxNoRec: 1375,
          pxNoRec: 136,
          dxAdditionsEx: 13,
          dxDeletionsEx: 0,
          dxRevisionsEx: 14,
          pxAdditionsEx: 0,
          pxDeletionsEx: 0,
          pxRevisionsEx: 0,
          dxNoRecEx: 0,
          pxNoRecEx: 0,
          patStat: 84,
          patStatChanges: 1,
          prinDxNoRec: 81,
          prinDxRev: 4,
          prinDxAdditions: 0,
          prinDxDeletions: 0,
          prinPxNoRec: 61,
          prinPxRev: 4,
          prinPxAdditions: 0,
          prinPxDeletions: 0,
          poaNoRecommendations: 13,
          poaNoRecommendationsEx: 0,
          poaRecommendations: 2,
          poaRecommendationsEx: 0,
          poo: 2,
          pooChanges: 2,
          drgChanges: 6,
          resultOfQuery: 2,
          asIsDRGChanges: 4,
          cptAdditions: null,
          cptRevisions: null,
          cptDeletions: null,
          modAdditions: null,
          modDeletions: null,
          modRevisions: null,
          cptNoRec: null,
          modNoRec: null,
          reimbDiff: 48133.56,
          reimbDec: -24709.47,
          reimbInc: 72843.03,
          reimbIncRecords: 6,
          reimbDecRecords: 5,
          recordsWithRecommendations: 6,
          accountNumbers: '',
          numClients: 0,
          cmCodes: 0.963,
          pcsCodes: 0.824,
          cptCodes: null,
          modCodes: null,
          totalCodes: 0.949,
          totalDRGs: 0.9310,
          totalDRGNoQuery: 0.9540,
          patStatCodes: 0.98,
          pooCodes: 0.0,
          poaCodes: 0.86,
          totalCodesAdditions: 33,
          totalCodesDeletions: 22,
          totalCodesNoRec: 1511,
          totalCodesRev: 26,
          totalCodesReviewed: 1592,
          totalCodesRecommended: 81,
          drgNoRec: 81,
          drgWOQueryNoRec: 83,
          patStatNoRec: 83,
          patStatFinNoRec: -99,
          pooNoRec: 0,
          dxTotalReviewed: 1427,
          dxTotalRec: 52,
          pxTotalReviewed: 165,
          pxTotalRec: 29,
          poaTotalReviewed: 15
        }
      }
    },
    components: {
      IPSummaryPrintable,
      TenantReportHeader,
      mappingSelector
    },
    methods: {
      ...mapActions('managedLists/tenantSetup/', ['GET_TENANT_SETUP', 'PUT_TENANT_SETUP']),
      ...mapActions('pulllists/', ['GET_PULLLIST_BUILDS']),
      ...mapActions('globals/', ['POST_BLOB']),
      ...mapActions('dropdowns/', ['GET_DROPDOWNS']),
      async initialize() {
        this.loading = true
        await Promise.all([this.GET_TENANT_SETUP(), this.GET_PULLLIST_BUILDS(), this.GET_DROPDOWNS(['allProjectTypes'])])
        this.loading = false
      },
      async putTenantSetup() {
        try {
          await this.PUT_TENANT_SETUP(this.tenantSetup)
        } catch (e) {
          console.log(e)
        }
      },
      async handleImageButtonRemove() {
        this.imageLoading = true
        this.tenantSetup.imageUrl = null
        await this.putTenantSetup()
        this.imageLoading = false
      },
      getProjectTypeId(projectDesc) {
        const projectType = this.dropdowns.allProjectTypes.list.find(x => x.value === projectDesc)
        if (projectType) {
          return projectType.key
        }
        return null
      },
      handleImageButton() {
        this.resetFileInput()
        const input = document.getElementById('imageFile')
        input.click()
      },
      resetFileInput() {
        const input = document.getElementById('imageFile')
        input.value = null
      },
      async onFileChange(e) {
        const input = document.getElementById('imageFile')
        if (!input.files) {
          return
        }

        const importFile = input.files[0]
        const parts = importFile.name.split('.')
        const extension = parts.pop()

        const payload = {
          blobPathPrefix: 'tenantSetup/',
          blob: importFile,
          fileExtension: extension,
          resizeWidth: 240
        }

        this.imageLoading = true
        const response = await this.POST_BLOB(payload)
        this.tenantSetup.imageUrl = response.data.location
        await this.putTenantSetup()
        this.imageLoading = false
      }
    },
    computed: {
      ...mapState('managedLists/tenantSetup/', ['tenantSetup']),
      ...mapState('pulllists/', ['pullListBuilds']),
      ...mapState('dropdowns/', ['dropdowns'])
    },
    created: function () {
      this.initialize()
    }
  }
</script>

<style scoped>
  .imgButton {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
  }

  .imgContainer:hover .imgButton {
    display: initial;
  }

  .imgContainer {
    position: relative;
    width: 240px;
  }

  .imgContainer:hover img {
    opacity: 0.3;
  }

  .imgContainer>>>.atom-audit-loader {
    margin-top: -30px !important;
  }

  >>>table {
    border-spacing: 0px;
    border-collapse: collapse;
    table-layout: fixed;
    font-size: 14px;
  }

  >>>td,
  >>>th {
    margin: 0px;
    padding: 10px 6px 10px 6px;
    vertical-align: top;
  }

  >>>thead {
    text-align: left !important;
    padding-top: 5px;
    /* background-color: #e1f5fe; */
  }

  >>>tr:nth-child(even) {
    background-color: #fafafa;
  }

  >>>.worksheet .demographics tr:nth-child(even) {
    background-color: unset;
  }

  >>>.worksheet .stripe tr:nth-child(even) {
    background-color: unset;
  }

  h1 {
    margin: 40px 0px 20px 0px;
    font-size: 2rem;
    border-bottom: 1px solid #b4bccc;
    padding-bottom: 5px;
  }

  h2 {
    margin: 50px 0px 20px 0px;
    font-size: 1.5rem;
    border-bottom: 1px solid #b4bccc;
    padding-bottom: 5px;
  }
</style>
