<template>
  <div>
    <el-row class="header-row">
      <h3 class="el-page-header">Facility List</h3>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="24">
        <el-form :model="clientListForm" size="mini" ref="clientListForm" :rules="clientListForm.rules" label-position="left" label-width="180px">
          <template v-if="!showclientListForm">
            <el-button class="hiaButton" plain round size="mini" @click="toggleclientListForm()" icon="el-icon-plus">Add New Facility </el-button>
          </template>
          <el-form-item v-if="showclientListForm" prop="clientName" label="Facility Name">
            <el-input size="mini" type="text" v-model="clientListForm.clientName" id="clientName"></el-input>
          </el-form-item>
          <el-form-item v-if="showclientListForm" prop="medicareProvider" label="Medicare Provider Number">
            <el-input size="mini" type="text" v-model="clientListForm.medicareProvider" id="medicareProvider"></el-input>
          </el-form-item>
          <el-form-item v-if="showclientListForm" prop="state" label="State">
            <el-select size="mini" v-model="clientListForm.state" filterable placeholder="Select State" clearable>
              <el-option v-for="item in dropdowns.states.list" :key="item.key" :label="item.value" :value="item.key"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="showclientListForm">
            <el-button-group>
              <el-button class="hiaButton" plain round size="mini" type="text" @click="toggleclientListForm()">Cancel</el-button>
              <el-button class="hiaButton" plain round size="mini" @click="postClientList()">Add</el-button>
            </el-button-group>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row :gutter="10" style="margin: 10px 0px 0px 0px;">
      <el-col :lg="24" :md="24">
        <v-client-table class="hiaTable" v-loading="clientListLoading" element-loading-spinner="atom-audit-loader" ref="clientListGrid" id="clientListGrid" :data="clientList" :columns="clientListColumns" :options="clientListOptions">
          <div slot="filter__inactive">
            <input type="checkbox" @change="$refs.clientListGrid.setFilter({ inactive: $event.target.checked })" />
          </div>
          <template slot="clientName" slot-scope="props">
            <div class="el-input--mini">
              <input class="el-input__inner" type="text" v-model="props.row.clientName" @change="putClientList(props.row)" />
            </div>
          </template>
          <template slot="medicareProvider" slot-scope="props">
            <div class="el-input--mini">
              <input class="el-input__inner" type="text" v-model="props.row.medicareProvider" @change="putClientList(props.row)" />
            </div>
          </template>
          <template slot="state" slot-scope="props">
            <div class="el-input--mini">
              <el-select size="mini" v-model="props.row.state" filterable placeholder="Select State" @change="putClientList(props.row)" clearable>
                <el-option v-for="item in dropdowns.states.list" :key="item.key" :label="item.value" :value="item.key"> </el-option>
              </el-select>
            </div>
          </template>
          <template slot="inactive" slot-scope="props">
            <input :key="props.row.clientId" type="checkbox" v-model="props.row.inactive" @change="putClientList(props.row)" />
          </template>
          <template slot="delete" slot-scope="props">
            <div style="text-align: center;">
              <i class="el-icon-close delete-button" title="Delete Facility" @click="deleteClient(props.row)"></i>
            </div>
          </template>
        </v-client-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import HiaAuthorization from '@/mixins/hiaAuthorization'
  // import { mapFields } from 'vuex-map-fields'

  export default {
    name: 'clientList',
    mixins: [HiaAuthorization],
    data() {
      return {
        showclientListForm: false,
        clientListForm: {
          clientName: null,
          medicareProvider: null,
          state: null,
          rules: {
            clientName: [{ required: true, trigger: 'change' }]
          }
        },
        clientListColumns: ['clientName', 'medicareProvider', 'state', 'inactive', 'delete'],
        clientListOptions: {
          filterByColumn: true,
          orderBy: {
            column: 'clientName',
            ascending: true
          },
          headings: {
            clientName: function(h) {
              return 'Facility Name'
            },
            medicareProvider: function(h) {
              return 'Medicare Provider Number'
            },
            delete: function(h) {
              return ''
            }
          },
          perPage: 10,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' }
        }
      }
    },
    methods: {
      ...mapActions('managedLists/clients/', ['GET_CLIENTS', 'PUT_CLIENTS', 'POST_CLIENT', 'DELETE_CLIENT']),
      ...mapMutations('dropdowns/', ['REMOVE_CACHED_DROPDOWN']),
      initialize() {
        this.GET_CLIENTS('')
      },
      toggleclientListForm() {
        this.showclientListForm = !this.showclientListForm
      },
      postClientList() {
        this.$refs.clientListForm.validate(async valid => {
          if (valid) {
            try {
              const payload = {
                clientList: {
                  clientName: this.clientListForm.clientName,
                  medicareProvider: this.clientListForm.medicareProvider,
                  state: this.clientListForm.state
                }
              }
              await this.POST_CLIENT(payload)
              this.REMOVE_CACHED_DROPDOWN('clients')
              this.clearForm()
              this.toggleclientListForm()
              this.$message({
                message: 'Facility Added Successful',
                type: 'success'
              })
            } catch (err) {
              this.$message({
                message: 'Facility Added Error',
                type: 'error'
              })
            }
          }
        })
      },
      async putClientList(row) {
        const index = this.clientList.findIndex(x => x.clientId === row.clientId)
        this.$set(this.clientList, index, row)

        try {
          await this.PUT_CLIENTS(row)
          this.REMOVE_CACHED_DROPDOWN('clients')
          this.$message({
            message: 'Facility Updated Successfully',
            type: 'success'
          })
        } catch (err) {
          this.$message({
            message: 'Facility Updated Error',
            type: 'error'
          })
        }
      },
      async deleteClient(row) {
        await this.$confirm(`Permanently delete Facility ${row.clientName}?`, 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        })

        try {
          await this.DELETE_CLIENT(row.clientId)
          this.REMOVE_CACHED_DROPDOWN('clients')
          this.$message({
            message: 'Facility Delete Successful',
            type: 'success'
          })
        } catch (err) {
          this.$message({
            message: `Error Deleting Facility: ${err.message}`,
            type: 'error'
          })
        }
      },
      clearForm() {
        this.clientListForm = {
          clientName: null
        }
      }
    },
    mounted: function() {
      this.initialize()
    },
    activated: function() {
      this.initialize()
    },
    computed: {
      // ...mapFields(['payorList.payorName']),
      ...mapState('dropdowns/', ['dropdowns']),
      ...mapState('managedLists/clients/', ['clientList', 'clientListLoading'])
    }
  }
</script>
