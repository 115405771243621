<template>
  <div id="managedLists">
    <el-row class="header-row">
      <el-col :span="24">
        <h3 class="el-page-header">Manage Review Lists</h3>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/managed-lists' }">Manage Review Lists</el-breadcrumb-item>
          <el-breadcrumb-item v-if="$route.params.slug">{{ breadCrumbItem }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <el-card>
      <v-client-table v-if="!$route.params.slug" class="hiaTable" ref="managedListsGrid" id="managedListsGrid" :data="managedListOptions" :columns="managedListColumns"
        :options="tableOptions">
        <template slot="name" slot-scope="props">
          <router-link :to="`/managed-lists/${props.row.slug}`">{{ props.row.name }}</router-link>
        </template>
        <template slot="description" slot-scope="props">
          <div style="display: flex; align-items: center;">
            <div style="flex: 5;">
              {{props.row.description}}
            </div>
            <div style="flex: 1;">
              <div class="el-tag el-tag--mini el-tag--light" style="padding: 5px 5px 15px 5px; font-weight: 500; margin-left: 30px; vertical-align: middle"
                v-if="props.row.importIntegrated"
                title="Import Integrated lists are automatically updated when records containing new values, such as Coders, Providers, etc are imported into a Project.">
                Import Integrated</div>
            </div>
          </div>

        </template>
        <!-- <template slot="extra" slot-scope="props">
          <div class="el-tag el-tag--mini el-tag--light" style="padding: 5px 5px 15px 5px; font-weight: 500; margin-right: 20px;" v-if="props.row.importIntegrated"
            title="Import Integrated lists are automatically updated when new values are imported such as new Coders, Providers, etc.">Import Integrated</div>
        </template> -->
      </v-client-table>

      <component ref="parentComponent" style="margin-top: 20px;" v-if="$route.params.slug" :is="getComponent(selectedItem)"> </component>
    </el-card>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import payors from './payors'
  import clientCoders from './clientCoders'
  import clientCDIStaff from './clientCDIStaff'
  import clientModifiers from './clientModifiers'
  import practiceProvider from './practiceProvider'
  import projectProcesses from './projectProcesses'
  import proComplianceReasonForChange from './proComplianceReasonForChange'
  import clients from './clients'
  import reasonForChange from './reasonForChange'
  import serviceLines from './serviceLines'
  import tags from './tags'
  import codeTypes from './codeTypes'
  import tenantSetup from './tenantSetup'
  import recommendationLibraryList from '@/views/reviews/recommendationLibrary/RecommendationLibraryList'
  import ascPatientTypes from './ascPatientTypes'
  import requiredFields from './requiredFields'
  import checklists from './checklistBuilder'

  export default {
    name: 'managedLists',
    data() {
      return {
        activeName: '',
        selectedItem: '',
        breadCrumbItem: '',
        loading: false,
        dropdownsNeeded: ['states', 'BlendedRateTypes'],
        managedListColumns: ['name', 'description', 'extra'],
        tableOptions: {
          orderBy: {
            column: 'name',
            ascending: true
          },
          filterByColumn: true,
          filterable: ['name', 'description'],
          headings: {
            name: 'List Name',
            extra: ''
          },
          columnsClasses: {
            name: 'smallColumn',
            description: 'wideColumn'
          },
          perPage: 20,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' }
        },
        managedListOptions: [
          {
            name: 'Organization Setup',
            component: 'tenantSetup',
            description: 'Part of initial setup, Atom allows you to set your organization name and upload a logo. This organization name/logo will print on any reports.\n \n  Import Defaults are also located in this section allowing for multiple import mappings.',
            slug: 'organization-setup'
          },
          {
            name: 'Facilities',
            component: 'clients',
            description: 'Each audited record will be assigned a Facility, which allows users to break down their coding accuracy rates by individual facility.  At least one facility name will need to be setup.\n \n If using the baseline package, DRG, APC and ASC blended rates can be entered per facility and will be used to calculate reimbursement differences in audit reports.',
            slug: 'facilities',
            importIntegrated: 1
          },
          {
            name: 'Tags',
            component: 'tags',
            description: 'List of tags that can be used to classify and identify projects/records.\n \n You can further classify your Atom Audit projects/records by using Tags. Each tag is a label. Tags can help you manage, identify, organize and filter projects/records. You can create tags to categorize projects/records by purpose, owner, environment or other criteria.\n \n Tags can be used in the reports module to filter projects/records for specific types of reviews.',
            slug: 'tags',
            importIntegrated: 1
          },
          {
            name: 'Payors',
            component: 'payors',
            description: 'List of Payors and Payor Details to be assigned on audited records. \n \n Payor and Payor Details are used to track the Payor for a given medical record. Payor denotes the high-level Payor such as “Blue Cross” and Payor Detail is for a more detailed classification such as “Blue Cross of Virginia”. \n \n Use the Medicare Payor checkbox to indicate if the payor is Medicare. Records with Medicare Payors are used when calculating Readmission and Mortality Quality Measures for Inpatient Records.',
            slug: 'payors',
            importIntegrated: 1
          },
          {
            name: 'Coders',
            component: 'clientCoders',
            description: 'List of Coders to be assigned on audited records.\n \n Use of this list allows audit reports to be generated by coder and is the basis for the Coder Response Process.\n \n Atom provides an online Response Process for Auditor/Coder rebuttal communication. Accounts created in User Admin that have been linked to a coder account will show the linked email address here.',
            slug: 'coders',
            importIntegrated: 1
          },
          {
            name: 'CDI Staff',
            component: 'clientCDIStaff',
            description: 'List of CDI Staff to be assigned on audited records.\n \n Use of this list allows audit reports to be generated by CDI Staff.',
            slug: 'cdi-staff',
            importIntegrated: 1
          },
          {
            name: 'Providers',
            component: 'practiceProvider',
            description: 'List of Providers to be assigned on audited records.\n \n Use of this list allows audit reports to be generated by Provider.',
            slug: 'providers',
            importIntegrated: 1
          },
          {
            name: 'Service Lines',
            component: 'serviceLines',
            description: 'List of Service Lines to be assigned on audited records.\n \n Use of this field allows reports to be generated by Service Line.',
            slug: 'service-lines',
            importIntegrated: 1
          },
          {
            name: 'Reason for Changes',
            component: 'reasonForChange',
            description: 'List of potential audit Reasons for Change.\n \n Reasons for Change are ways to identify the reason for an auditing change on a given record and/or individual codes. For example, the Reason for Change “Medical CC” would be used to classify an audit recommendation related to a CC. Use of this field allows generating reports by reason for change. \n \n Each high level Review Type – Inpatient, Outpatient and Professional Fee – has its own Reasons for Change list. Then, within each of these lists, you can select whether you want the Reason for Change to be available at the header (record level), Dx, Px, Cpt and/or APC levels.',
            slug: 'reason-for-changes'
          },
          {
            name: 'Outpatient Patient Types',
            component: 'ascPatientTypes',
            description: 'List of Patient Types to be assigned on outpatient records.\n \n Patient Type is a required field for an outpatient record. Use of this field allows reports and statistics to be generated and segmented by Patient Type.',
            slug: 'outpatient-patient-types',
            importIntegrated: 1
          },
          {
            name: 'Modifiers',
            component: 'clientModifiers',
            description: 'List of Client Specified modifiers that can be used on CPT and EM codes.  Standard modifiers can also be viewed on this page and are automatically available.\n \n Use of this list allows audit reports to be generated with custom modifiers.',
            slug: 'modifiers',
            importIntegrated: 1
          },
          {
            name: 'Code Types',
            component: 'codeTypes',
            description: 'List of roles/departments responsible for assigning Oupatient and Professional Fee codes.\n \n The Code Type is a flag on each Outpatient and Professional Fee code to denote what department/role assigned the code. For example, the Code Type “HIM Assigned” indicates that the HIM Department assigned the code.\n \n Projects contain a default Code Type, which will default for any codes added. These can be updated on the Diagnosis and Procedure sections of the review record.\n \n Outpatient and Professional Fee reports are segmented by Code Type.',
            slug: 'code-types'
          },
          // {
          //   name: 'Compliance Findings',
          //   component: 'proComplianceReasonForChange'
          // },
          {
            name: 'Recommendation Library',
            component: 'recommendationLibraryList',
            description: 'The recommendation library is a set of pre-written coding recommendations.\n \n Pre-written recommendations can be used in the Action Taken or Coding References fields in Inpatient, Outpatient and Professional Fee reviews. Use of this feature allows users to setup standard write-ups, and use those standard write-ups at time of record audit.\n \n Using the recommendation library can increase consistency and productivity when applying audit recommendations.',
            slug: 'recommendation-library'
          },
          {
            name: 'Validations',
            component: 'requiredFields',
            description: 'List of validation settings for Inpatient, Outpatient and Provider reviews.\n \n Validations allow you to set whether a particular field should be required for the "Ready for Coder" and "Completed" statuses.\n \n For each field, you can set whether the field is required and set a custom Error Message. For example you can set the CDI Staff field to required with the message "CDI Staff is Required".',
            slug: 'validations'
          },
          {
            name: 'Project Processes',
            component: 'projectProcesses',
            description: 'Project Processes are a way to add a list of tasks to each project to help managers and auditors confirm and/or schedule a set of tasks for a given project.',
            slug: 'project-processes'
          },
          {
            name: 'Checklists',
            component: 'checklists',
            description: 'Checklists can be configured to audit specific items for each record, such as the requirements for an Annual Wellness Visit, enabling you to verify whether each individual requirement has been met.',
            slug: 'checklist-builder'
          }
        ],
        managedListsColumns: ['name', 'description'],
        managedListsOptions: {
          filterByColumn: true,
          orderBy: {
            column: 'payorNumberId',
            ascending: true
          },
          headings: {
            payorNumberId: function (h) {
              return 'Payor'
            },
            payorDetail: function (h) {
              return 'Payor Detail'
            },
            delete: function (h) {
              return ''
            }
          },
          perPage: 10,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' }
        }
      }
    },
    methods: {
      // ...mapActions('managedLists/payors/', ['GET_PAYORLIST']),
      // ...mapActions('managedLists/payorDetails/', ['GET_PAYORDETAIL']),
      // ...mapActions('managedLists/clients/', ['GET_CLIENTS']),
      // ...mapActions('managedLists/cdiStaff/', ['GET_CDISTAFF_LIST']),
      // ...mapActions('managedLists/proComplianceReasonForChanges/', ['GET_PRO_COMPLIANCE_REASONFORCHANGES']),
      // ...mapActions('managedLists/reasonForChanges/', ['GET_REASONFORCHANGES']),
      // ...mapActions('managedLists/serviceLines/', ['GET_SERVICELINESLIST']),
      // ...mapActions('managedLists/projectIdentifiers/', ['GET_PROJECTIDENTIFIERS']),
      // ...mapActions('managedLists/codeTypes/', ['GET_CODE_TYPES']),
      // ...mapActions('managedLists/ascPatientTypes/', ['GET_ASC_PATIENT_TYPES']),
      // ...mapActions('managedLists/blendedRates/', ['GET_BLENDED_RATES']),
      // ...mapActions('managedLists/requiredFields/', ['GET_REQUIREDFIELDS']),
      // ...mapActions('managedLists/projectTasks/', ['GET_PROJECT_TASKS']),
      ...mapActions('dropdowns/', ['GET_DROPDOWNS']),
      initialize() {
        if (this.$route.params.slug) {
          const item = this.managedListOptions.find(f => f.slug === this.$route.params.slug.trim())
          this.selectedItem = item.component
          this.breadCrumbItem = item.name
        }
        // todo: move these calls out and into their respective components
        this.GET_DROPDOWNS(this.dropdownsNeeded).then(() => {
          // this.GET_CLIENTS('')
          // this.GET_PAYORLIST()
          // this.GET_PAYORDETAIL()
          // this.GET_PRO_COMPLIANCE_REASONFORCHANGES()
          // this.GET_REASONFORCHANGES()
          // this.GET_SERVICELINESLIST()
          // this.GET_CODE_TYPES()
          // this.GET_ASC_PATIENT_TYPES()
          // this.GET_BLENDED_RATES()
          // this.GET_CDISTAFF_LIST()
          // this.GET_REQUIREDFIELDS()
          // this.GET_AVOIDABLEDAYREASONS()
          // this.GET_PROJECTIDENTIFIERS()
        })
      },
      handleRoute() {
        // this.$router.replace({ query: { list: this.selectedItem } })
      },
      getComponent(component) {
        const obj = this.managedListOptions.find(o => o.component === component)
        if (obj) {
          return obj.component
        }
      }
    },
    created: function () { },
    activated: function () {
      window.document.title = 'Managed Lists'
      this.initialize()
    },
    computed: {
      ...mapState('user/', ['currentTenantGuid'])
    },
    watch: {
      currentTenantGuid() {
        this.initialize()
      },
      $route() {
        this.$nextTick(function () {
          this.initialize()
        })
      }
    },
    components: {
      payors,
      clientCoders,
      clientCDIStaff,
      practiceProvider,
      proComplianceReasonForChange,
      reasonForChange,
      serviceLines,
      clientModifiers,
      tags,
      recommendationLibraryList,
      tenantSetup,
      codeTypes,
      ascPatientTypes,
      clients,
      requiredFields,
      projectProcesses,
      checklists
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .managedListsTab {
    margin: 0px 0px 0px 0px;
  }

  >>>.smallColumn {
    width: 200px;
  }

  >>>.wideColumn {
    white-space: pre-line;
  }
</style>