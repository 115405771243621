<template>
  <div id="tasks">
    <el-row class="topMargin">
      <v-client-table class="hiaTable" v-loading="loading" element-loading-spinner="atom-audit-loader" ref="taskListsGrid" id="taskListsGrid"
        :data="taskList.filter(x => x.processId === selectedProcess.processId)" :columns="taskColumns" :options="taskOptions" uniqueKey="taskId">
        <div slot="filter__inactive">
          <input type="checkbox" @change="$refs.taskListsGrid.setFilter({inactive: $event.target.checked})">
        </div>
        <template slot="order" slot-scope="props">
          <div class="el-input--mini">
            <input class="el-input__inner" type="number" v-model="props.row.order" @change="updateProjectTask(props.row)">
          </div>
        </template>
        <template slot="name" slot-scope="props">
          <div class="el-input--mini">
            <input class="el-input__inner" type="text" v-model="props.row.name" @change="updateProjectTask(props.row)">
          </div>
        </template>
        <template slot="inactive" slot-scope="props">
          <input :key="props.row.taskId" type="checkbox" v-model="props.row.inactive" @change="updateProjectTask(props.row)">
        </template>
        <template slot="delete" slot-scope="props">
          <div style="text-align: center;">
            <i class="el-icon-close delete-button" @click="deleteProjectTask(props.row)"></i>
          </div>
        </template>
      </v-client-table>
    </el-row>
    <el-form label-position="left" label-width="150px" size="mini" style="margin-top: 10px;">
      <div v-if="!showAddProjectTaskForm">
        <el-popover placement="right" ref="newTaskPopover" :title="`Add Task to ${selectedProcess.name}`" width="300" trigger="click" @show="handleOpenPopover">
          <el-form :model="addProjectTaskForm" ref="addProjectTaskFormIp" size="mini" :rules="addProjectTaskForm.rules" label-position="top" label-width="180px"
            @submit.native.prevent>
            <el-form-item prop="name" label="Task Name">
              <el-input v-model="addProjectTaskForm.name" size="mini"></el-input>
            </el-form-item>
            <el-form-item prop="order" label="Order">
              <el-input type="number" v-model="addProjectTaskForm.order" size="mini"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button-group>
                <el-button class="hiaButton" plain round size="mini" type="text" @click="closeNewTaskPopover()">Cancel</el-button>
                <el-button class="hiaButton" plain round size="mini" @click="addProjectTask('ip', 'addProjectTaskFormIp')">Add</el-button>
              </el-button-group>
            </el-form-item>
          </el-form>

          <el-button slot="reference" class="hiaButton" plain round size="mini" icon="el-icon-plus">Add Task</el-button>
        </el-popover>
      </div>
    </el-form>

  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import HiaAuthorization from '@/mixins/hiaAuthorization'
  import hiaTable from '@/mixins/hiaTable'

  export default {
    name: 'taskTab',
    mixins: [hiaTable, HiaAuthorization],
    props: ['selectedProcess'],
    data() {
      return {
        activeTab: 'ip',
        loading: false,
        showMergeProjectTask: false,
        mergeProjectTaskLoading: false,
        selectedMergeProjectTask: {},
        taskColumns: ['name', 'order', 'inactive', 'delete'],
        taskOptions: {
          filterByColumn: false,
          filterable: false,
          sortable: ['name'],
          orderBy: {
            column: 'order',
            ascending: true
          },
          headings: {
            processId: 'Process',
            delete: function (h) {
              return ''
            },
            name: 'Task Name'
          },
          perPage: 10,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' }
        },
        showAddProjectTaskForm: false,
        addProjectTaskForm: {
          name: '',
          order: '',
          rules: {
            name: [
              { required: true, message: 'Please Input Name', trigger: 'none' }
            ],
            order: [
              { required: true, message: 'Please Input Order', trigger: 'none' }
            ]
          }
        }
      }
    },
    methods: {
      ...mapActions('managedLists/projectTasks/', ['GET_PROJECT_TASKS', 'POST_PROJECT_TASK', 'DELETE_PROJECT_TASK', 'PUT_PROJECT_TASK']),
      ...mapMutations('managedLists/projectTasks/', ['SET_SELECTED']),
      initialize(route) {
        // this.loading = true
        // this.GET_PROJECT_TASKS().then(response => {
        //   this.loading = false
        // })
      },
      localselectAll(checked) {
        let filteredRecords = []

        if (this.$refs.taskListsGrid) {
          filteredRecords = this.$refs.taskListsGrid.allFilteredData
        }

        const recs = this.taskList.filter(x => filteredRecords.some(y => y.taskId === x.taskId))
        hiaTable.selectAll(recs, checked)
        this.SET_SELECTED(recs)
      },
      selectRecord(row, e) {
        const checked = e.srcElement.checked
        row.selected = checked
        this.SET_SELECTED([row])
      },
      getProjectTypeId(projectType) {
        let projectTypeId
        switch (projectType) {
          case 'ip':
            projectTypeId = 1001
            break
          case 'op':
            projectTypeId = 1003
            break
          case 'pro':
            projectTypeId = 1002
            break
          case 'amb':
            projectTypeId = 1004
            break
        }
        return projectTypeId
      },
      addProjectTask(projectType, refName) {
        const projectTypeId = this.getProjectTypeId(projectType)

        this.$refs[refName].validate(async (valid) => {
          if (valid) {
            try {
              this.loading = true

              const payload = {
                task: {
                  order: this.addProjectTaskForm.order,
                  projectTypeId: projectTypeId,
                  name: this.addProjectTaskForm.name,
                  processId: this.selectedProcess.processId
                }
              }
              await this.POST_PROJECT_TASK(payload)
              this.$refs[refName].resetFields()
              this.closeNewTaskPopover()
              this.loading = false
              this.$message({
                message: 'Project Task Added',
                type: 'success'
              })
            } catch (err) {
              this.loading = false
              this.$message({
                message: `Error Adding Project Task: ${err.message}`,
                type: 'error'
              })
            }
          }
        })
      },
      async updateProjectTask(task) {
        const index = this.taskList.findIndex(x => x.taskId === task.taskId)
        this.$set(this.taskList, index, task)

        try {
          this.loading = true
          await this.PUT_PROJECT_TASK(task)
          this.loading = false
          this.$message({
            message: 'Project Task Saved',
            type: 'success'
          })
        } catch (err) {
          this.loading = false
          this.$message({
            message: 'Error Editing Project Task',
            type: 'error'
          })
        }
      },
      async deleteProjectTask(task) {
        if (task.usedOnProjects) {
          const res = await this.$prompt(`The task ${task.name} has been used in Projects. Do you want to permanently delete this task and remove from all associated projects? If so, enter the text DELETE below and click OK.`, 'Warning', {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel'
          })

          res.value = res.value ? res.value : 'blank'

          if (res.value.trim() !== 'DELETE') {
            this.$message({
              message: `Error Deleting Project Task, expected the value DELETE but received ${res.value}`,
              type: 'error'
            })
            return
          }
        } else {
          await this.$confirm(`Permanently delete ${task.name}?`, 'Warning', {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
          })
        }

        try {
          this.loading = true
          await this.DELETE_PROJECT_TASK(task)
          this.loading = false
          this.$message({
            message: 'Project Task Deleted Successfully',
            type: 'success'
          })
        } catch (err) {
          this.loading = false
          this.$message({
            message: `Error Deleting Project Task: ${err.message}`,
            type: 'error'
          })
        }
      },
      handleOpenPopover() {
        const tasksForType = this.taskList.filter(x => x.processId === this.selectedProcess.processId)
        let maxOrder = Math.max(...tasksForType.map(x => x.order))
        if (!isFinite(maxOrder)) {
          maxOrder = 0
        }
        this.addProjectTaskForm.order = maxOrder + 1
      },
      closeNewTaskPopover() {
        const popper = this.$refs.newTaskPopover
        if (popper) {
          popper.doDestroy()
          popper.doClose()
        }
      }
    },
    computed: {
      ...mapState('managedLists/clients/', ['clientList']),
      ...mapState('managedLists/projectTasks/', ['taskList', 'taskListLoading']),
      ...mapState('managedLists/projectProcesses/', ['projectProcessesList']),
      ...mapState('user/', ['currentTenantGuid']),
      disableMergeButton() {
        return this.taskList.filter(x => x.selected).length < 2
      }
    },
    watch: {
      currentTenantGuid() {
        this.initialize(this.$route)
      }
    },
    created: function () {
      this.initialize(this.$route)
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .taskOption {
    cursor: pointer;
    padding: 0px 20px 0px 20px;
    color: #337ab7;
  }

  .taskOption:hover {
    background: rgba(255, 196, 12, 0.2);
    border-radius: 2px;
  }

  .selected {
    background: rgba(255, 196, 12, 0.2);
  }

  .topMargin {
    margin: 10px 0px 0px 0px;
  }

  >>>.VuePagination__count {
    display: none;
  }
</style>