<template>
  <div>
    <el-row :gutter="10">
      <el-col :span="12">
        <clientList></clientList>
      </el-col>
      <el-col :span="12">
        <blendedRates></blendedRates>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import clientList from './clientList.vue'
  import blendedRates from './blendedRates.vue'

  export default {
    name: 'payors',
    data() {
      return {
      }
    },
    components: {
      clientList,
      blendedRates
    }
  }
</script>